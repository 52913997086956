import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import {a11yLight} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {useMsal} from "@azure/msal-react";

import dayjs from "dayjs";

import {Team, TeamCognitoSettings, TeamFhirSettings} from "../models";
import AdminAPI from "../service/AdminAPI";
import {getFriendlyErrorMessage} from "../helpers";
import * as Auth from "../service/Auth";

import * as ScreenHelpers from './ScreenHelpers'
import ClipboardButton from "../components/forms/ClipboardButton";
import {ErrorMessageProps} from "./BaseScreen";
import SandboxBaseScreen, {SandboxLoadingScreen, SandboxNotFoundScreen} from "./sandbox/SandboxBaseScreen";
import {TurasSection} from "../components/TurasSection";
import {useLocalStorage} from "usehooks-ts";
import {localStorageKeys} from "../constants";

function OAuthCallbackScreen() {
  const location = useLocation();
  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [state, setState] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const [team, setTeam] = useState<Team>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings>();
  const [cognitoSettings, setCognitoSettings] = useState<TeamCognitoSettings>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [selectedCallbackUrl] = useLocalStorage<string>(localStorageKeys.selectedCallbackUrl, '');
  const [idToken, setIdToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [expires, setExpires] = useState<dayjs.Dayjs | undefined>();

  async function getAndDisplaySandbox(sandboxId: string): Promise<Team | undefined> {
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      setCognitoSettings,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  async function doSwapCodeForToken() {
    if (!code) {
      setErrorMessage({text: "No 'code' parameter in the callback URL"});
      return;
    }
    if (!cognitoSettings) {
      setErrorMessage({text: "Team Cognito settings are not configured"});
      return;
    }

    // @ts-ignore
    if (window.gtag) window.gtag('event', 'auth_try_it', {
      'sandbox_id': team?.id,
      'mode': 'authorization_code',
      'result': 'request_token',
    });

    try {
      const response = await Auth.handleTryItCallback(cognitoSettings, code, selectedCallbackUrl);
      setIdToken(response.data.id_token);
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);

      const expiresIn = parseInt(response.data.expires_in);
      const expires = dayjs().add(expiresIn, 'seconds');
      setExpires(expires);
    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code') || undefined;
    const state = params.get('state') || undefined;
    setCode(code || '');
    setState(state || '');

    if (!state) {
      setErrorMessage({text: "Unable to parse Team ID from 'state'"})
      return;
    }

    getAndDisplaySandbox(state);
  }, []); // NB: empty array important to prevent multiple firing of event

  const pageTitle = "OAuth 2.0 callback received";

  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;


  return <SandboxBaseScreen
    pageTitle={pageTitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>

      <h2>Callback details</h2>
      <Form className="turasForm">
        <Form.Group className="form-group mb-3">
          <Form.Label>Authorization code</Form.Label>
          <Form.Control as="input"
                        value={code}
                        readOnly={true}/>
          <Form.Text>The <code>code</code> is issued by the authentication server. It will be swapped for
            an <code>authorization token</code>.</Form.Text>
        </Form.Group>

        <Form.Group className="form-group mb-3">
          <Form.Label>State</Form.Label>
          <Form.Control as="input"
                        value={state}
                        readOnly={true}/>
          <Form.Text>The <code>state</code> variable is normally used to prevent CSRF and other attacks. In this
            situation it's being used to keep track of your <code>Sandbox ID</code>.</Form.Text>
        </Form.Group>
      </Form>

    </TurasSection>

    <TurasSection>
      <h2>Access Token</h2>

      <Form className="turasForm">
        <div className="mb-3">
          <Button type="button"
                  variant="warning"
                  onClick={() => doSwapCodeForToken()}
                  disabled={!!accessToken}
          >Request token</Button><br/>

          <Form.Text>Click this button to request the <code>auth token</code>. You cannot request
            a <code>token</code> twice with the same <code>code</code>.</Form.Text>
        </div>

        <div className="mb-3">
          This button sends a request with the following config:

          <div className="card overflow-auto" style={{maxHeight: '25em'}}>
            <SyntaxHighlighter language="http" style={a11yLight} className="m-0">
              {`POST /oauth2/token HTTP/1.1
Host: ${cognitoSettings?.settings?.auth_base_url.replace('http://', '').replace('https://', '')}
Content-Type: application/x-www-form-urlencoded

grant_type=authorization_code
  &client_id=${cognitoSettings?.settings?.web_client_id}
  &code=${code}
  &scope=openid+profile+email
  &redirect_uri=${selectedCallbackUrl}
`}</SyntaxHighlighter>
          </div>

        </div>

        <Form.Group className="form-group mb-3">
          <Form.Label>Access token</Form.Label>
          <InputGroup>
            <Form.Control as="input"
                          value={accessToken}
                          readOnly={true}/>
            <ClipboardButton value={accessToken || ''}/>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-group mb-3">
          <Form.Label>ID token</Form.Label>
          <InputGroup>
            <Form.Control as="input"
                          value={idToken}
                          readOnly={true}/>
            <ClipboardButton value={idToken || ''}/>
          </InputGroup>
          <Form.Text className="text-danger">Most services in the Sandbox require you to use the <code>ID
            token</code> instead of <code>access token</code> in your <code>Authorization</code> header</Form.Text>
        </Form.Group>

        <Form.Group className="form-group mb-3">
          <Form.Label>Refresh token</Form.Label>
          <InputGroup>
            <Form.Control as="input"
                          value={refreshToken}
                          readOnly={true}/>
            <ClipboardButton value={refreshToken || ''}/>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-group mb-3">
          <Form.Label>Expires</Form.Label>
          <Form.Control as="input"
                        value={expires ? expires.format('ddd DD MMM YYYY HH:mm:ss Z') : ''}
                        readOnly={true}/>
        </Form.Group>
      </Form>

    </TurasSection>

    <TurasSection>

      <h2>Use It!</h2>

      <div>You can now make a request using the token</div>
      <div className="card overflow-auto" style={{maxHeight: '25em'}}>
        <SyntaxHighlighter language="http" style={a11yLight} className="m-0">
          {`GET /tenant/${fhirSettings?.tenant_id}/Patient HTTP/1.1
Host: ${fhirSettings?.fhir_base_url.replace('http://', '').replace('https://', '')}
Authorization: Bearer ${idToken}
X-API-Key: (insert api key here)
`}</SyntaxHighlighter>
      </div>

    </TurasSection>
  </SandboxBaseScreen>
}

export default OAuthCallbackScreen
