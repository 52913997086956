import React from "react";
import StyledHyperlink from "./StyledHyperlink";
import {ButtonVariant, Color} from "react-bootstrap/types";
import {urlIsExternal} from "../helpers";

export type ButtonProps = {
  title: string;
  url?: string;
  variant?: ButtonVariant | 'disabled';
  colour?: Color;
  /**
   * If noExternal=true, the component will not add the external link icon. This is useful for links which are already
   * decorated by jquery at load time
   */
  noExternal?: boolean;
  // className?: string;
}

export type LandingBlockProps = React.PropsWithChildren<{
  title: string;
  subtitle?: string;
  // to make it more convenient to feature toggle buttons, accept undefined as a type here
  buttons?: (ButtonProps | undefined)[];
  url?: string;
  icon?: any;
  lg?: number;
  md?: number;
  sm?: number;
  height100pct?: boolean;
}>

function LandingBlock(props: LandingBlockProps): JSX.Element {
  const {buttons} = props;

  function CardContents() {
    const cardCssClasses = ['card', 'landingBlock'];
    if (props.height100pct === undefined || props.height100pct)
      cardCssClasses.push('h-100');
    if (props.icon)
      cardCssClasses.push('withIcon');

    return <div
      className={cardCssClasses.join(' ')}>
      {props.icon && <img src={props.icon} alt=""/>}
      <div className="card-heading">
        <h3 className="card-title">{props.title} {props.url && urlIsExternal(props.url) &&
          <i className="fa fa-external-link"></i>}</h3>
        <p className="card-title">{props.subtitle}</p>
      </div>
      <div className="card-body d-flex flex-column">
        {props.children}
      </div>
      <div className="card-footer mt-auto">
        {buttons?.filter(b => b).map((b, i) => {
          // to make it more convenient to feature toggle buttons we accept 'undefined' in the props
          if (!b) return;

          const btnVariant = b.variant || 'default';

          return <StyledHyperlink href={b.url}
                                  className={`btn btn-block btn-${btnVariant}${b.variant === 'disabled' ? ' disabled' : ''}`}
                                  key={i}
                                  noExternal={b.noExternal}>{b.title}</StyledHyperlink>;
        })}
      </div>
    </div>;
  }

  return <div className={`col-md-${props.md || 6} col-lg-${props.lg || 4} col-sm-${props.sm || 12} cardPaddingBottom`}>
    {props.url && <a href={props.url}
                     target={urlIsExternal(props.url) ? "_blank" : ""}
                     className="no-external-link">
      <CardContents/>
    </a>}

    {!props.url && <CardContents/>}
  </div>;
}

export default LandingBlock;
