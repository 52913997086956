import React, {useEffect, useState} from 'react';
import {PrePreparedOauthToken, Team, TeamApiKey, TeamFhirSettings} from "../../models";
import AdminAPI from "../../service/AdminAPI";
import {useMsal} from "@azure/msal-react";
import * as ScreenHelpers from "../ScreenHelpers";
import {ErrorMessageProps} from "../BaseScreen";
import SandboxBaseScreen, {
  SandboxFhirDisabledScreen,
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "./SandboxBaseScreen";
import {TurasSection} from "../../components/TurasSection";
import {Button, Form, InputGroup, Row} from "react-bootstrap";
import ClipboardButton from "../../components/forms/ClipboardButton";
import {environment} from "../../config";
import {useLocalStorage} from "usehooks-ts";
import {localStorageKeys} from "../../constants";


export default function QuickAccessScreen() {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();
  const [accessToken, setAccessToken] = useState<PrePreparedOauthToken | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  async function loadApiKeySecret() {
    if (!team) return;
    return await ScreenHelpers.loadApiKey(team.id, api, setApiKey, setErrorMessage);
  }

  async function loadAccessToken() {
    if (!team) return;
    return await ScreenHelpers.loadAccessToken(team.id, api, setAccessToken, setErrorMessage);
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Quick access details";
  const pageSubtitle = "If you know what you're looking for, here are all the most common access details in one place";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded || !team)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!fhirSettings)
    return <SandboxFhirDisabledScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} team={team}/>;

  // If team is found
  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>
      <Form className="turasForm">
        <h2>FHIR server and Media Store</h2>
        <p className='mb-30'>Use these details to access the FHIR server for your sandbox.</p>

        <Row>
          <div className='col-md-9'>
            <Form.Group controlId="fhirAccessDetails.rootUrl" className='form-group'>
              <Form.Label>Root URL</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={fhirSettings?.fhir_base_url}
                              readOnly={true}/>
                <ClipboardButton value={fhirSettings?.fhir_base_url || ''}/>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="fhirAccessDetails.tenantId" className='form-group'>
              <Form.Label>Tenant ID</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={fhirSettings?.tenant_id}
                              readOnly={true}/>
                <ClipboardButton value={fhirSettings?.tenant_id}/>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="fhirAccessDetails.fullUrl" className='form-group'>
              <Form.Label>Full URL</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={`${fhirSettings?.fhir_base_url}/tenant/${fhirSettings?.tenant_id}/`}
                              placeholder="********"
                              readOnly={true}/>
                <ClipboardButton value={`${fhirSettings?.fhir_base_url}/tenant/${fhirSettings?.tenant_id}`}/>
              </InputGroup>
            </Form.Group>


            {environment.empiApi && <>
              <h2>Electronic Patient Master Index (EMPI)</h2>
              <p>Use these details to access the EMPI service for your sandbox.</p>

              <Form.Group controlId="empiAccessDetails.rootUrl" className='form-group'>
                <Form.Label>Root URL</Form.Label>
                <InputGroup>
                  <Form.Control type="text"
                                value={environment.empiApi.url}
                                readOnly={true}/>
                  <ClipboardButton value={environment.empiApi.url}/>
                </InputGroup>
              </Form.Group>
            </>}

            {environment.vaccinationsApi && <>
              <h2>Vaccinations API (NCDS)</h2>
              <p>Use these details to access the Vaccinations API for your sandbox.</p>

              <Form.Group controlId="ncdsAccessDetails.rootUrl" className='form-group'>
                <Form.Label>Root URL</Form.Label>
                <InputGroup>
                  <Form.Control type="text"
                                value={environment.vaccinationsApi.url}
                                readOnly={true}/>
                  <ClipboardButton value={environment.vaccinationsApi.url}/>
                </InputGroup>
              </Form.Group>
            </>}

            <h2>Cognito authentication</h2>
            <p>This applies to any serviced based on Cognito auth, such as the <strong>FHIR server</strong>, <strong>Media
              Store</strong>, and the <strong>EMPI</strong> service</p>
            <p>For security, these are on the <a href="/sandbox/auth">Authentication</a> page.</p>

            <h2>Common access details</h2>
            <p>These details apply to the <strong>FHIR server</strong> and services based upon it in the Sandbox, such
              as <strong>EMPI</strong>.</p>

            <Form.Group controlId="fhirAccessDetails.apiKey" className='form-group'>
              <Form.Label>API Key</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={apiKey?.api_key_value}
                              placeholder="********"
                              readOnly={true}/>
                {!apiKey && <Button className="btn btn-outline" style={{margin: 0, color: "#000"}}
                                    onClick={loadApiKeySecret}><i className="fa fa-eye"
                                                                  style={{color: '#000'}}></i></Button>}
                {apiKey && <ClipboardButton value={apiKey?.api_key_value || ''}
                                            disabled={!apiKey}/>}
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="fhirAccessDetails.accessToken" className='form-group'>
              <Form.Label>ID Token</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={accessToken?.id_token}
                              placeholder="********"
                              readOnly={true}/>
                {!accessToken && <Button className="btn btn-outline" style={{margin: 0, color: "#000"}}
                                         onClick={loadAccessToken}><i className="fa fa-eye"
                                                                      style={{color: '#000'}}></i></Button>}
                {accessToken && <Button className="btn btn-outline" style={{margin: 0, color: "#000"}}
                                        onClick={loadAccessToken}><i className="fa fa-rotate-right"
                                                                     style={{color: '#000'}}></i></Button>}
                {accessToken && <ClipboardButton value={accessToken ? accessToken.id_token : ''}
                                                 disabled={!accessToken}/>}
              </InputGroup>
              <Form.Text className="text-muted">
                This token will be retrieved from the OAuth server by the Admin API. It is only valid for <strong>60
                minutes</strong>
              </Form.Text>
            </Form.Group>

          </div>
        </Row>
      </Form>
    </TurasSection>

  </SandboxBaseScreen>;
}

