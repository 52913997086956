import React, {useState} from "react";
import {Col, Row, Spinner, Tab, Tabs} from "react-bootstrap";

import {DiagnosticReport, DocumentReference, Patient, Practitioner} from "fhir/r4";

import {RandomDataAPI} from "../../../service/RandomDataAPI";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {GenericAlert} from "../../../components/AlertBox";
import TurasSideNav from "../../../components/TurasSideNav";
import TurasCodePanel from "../../../components/TurasCodePanel";
import LandingBlock from "../../../components/LandingBlock";

import DiagnosticReportFhirDataHierarchy from "../../../img/docs/mediastore/fhir-diagnostic-report-binary.png";
import {fhirIdentifierSystem} from "../../../constants";


export default function MediaStoreAndFHIRGuide(): JSX.Element {

  const defaultPatient: Patient = {
    resourceType: "Patient",
    identifier: [
      {
        system: "https://phfapi.digitalhealthplatform.net/fhir/chinumber",
        value: "9912675691"
      }
    ],
    name: [
      {
        given: [
          "Thomas",
          "Kathleen"
        ],
        family: "Adams"
      }
    ],
    gender: "male",
    birthDate: "1967-12-30",
    address: [
      {
        line: [
          "30 Kirby rapid"
        ],
        city: "Doddton",
        country: "GB-SCT",
        postalCode: "E0J 3AX"
      }
    ]
  };
  const defaultPractitioner: Practitioner = {
    resourceType: "Practitioner",
    identifier: [
      {
        system: "https://fhir.hl7.org.uk/Id/nmc-number",
        value: "IH97 RTH"
      }
    ],
    name: [
      {
        given: [
          "Ellie",
          "Karl"
        ],
        family: "Bell"
      }
    ],
    address: [
      {
        line: [
          "771 Abigail plains"
        ],
        city: "South Anneview",
        country: "GB-SCT",
        postalCode: "L13 7EW"
      }
    ]
  };

  const defaultDocumentReference: DocumentReference = {
    resourceType: "DocumentReference",
    status: "current",
    content: [
      {attachment: {url: "FULL_URL_OF_BINARY_RESOURCE"}}
    ],
  };
  const defaultDiagnosticReport: DiagnosticReport = {
    resourceType: "DiagnosticReport",
    status: "final",
    code: {
      coding: [{code: "19717001", system: fhirIdentifierSystem.snomedCt}],
    },
    subject: {reference: `Patient/<PATIENT_ID_OR_CHI>`},
    performer: [{reference: `Practitioner/<PRACTITIONER_ID>`}],
    media: [
      {link: {reference: `DocumentReference/<DOCUMENT_REFERENCE_ID>`}}
    ]
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [examplePatient, setExamplePatient] = useState<Patient>(defaultPatient);
  const [examplePractitioner, setExamplePractitioner] = useState<Practitioner>(defaultPractitioner);
  const [exampleDocumentReference] = useState<DocumentReference>(defaultDocumentReference);
  const [exampleDiagnosticReport] = useState<DiagnosticReport>(defaultDiagnosticReport);


  const randomDataApi = new RandomDataAPI();

  async function getRandomPatient() {
    setLoading(true);

    try {
      const newData = await randomDataApi.getPatient();

      // Remove the values we don't need
      newData.id = undefined;
      newData.extension = undefined;

      setExamplePatient(newData);
    } finally {
      setLoading(false);
    }
  }

  async function getRandomPractitioner() {
    setLoading(true);

    try {
      const newData = await randomDataApi.getPractitioner();

      // Remove the values we don't need
      newData.id = undefined;
      newData.extension = undefined;

      setExamplePractitioner(newData);
    } finally {
      setLoading(false);
    }
  }

  return <BaseScreen
    pageTitle="Getting started: Combine Media Store and FHIR Resources"
    pageSubtitle="Learn how to link Media Store items to FHIR resources to combine complex structured and unstructured
                  clinical data, or to provide metadata for files in Media Store.">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Combine Media Store files with FHIR resources</li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Build hierarchical FHIR data to represent a
              clinical picture
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Store metadata for a Media Store file</li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
            <li><i className="fa fa-check text-warning"></i>{' '}
              Complete the <a href="fhir">FHIR getting started guide</a></li>
            <li><i className="fa fa-check text-warning"></i>{' '}
              Complete the <a href="media-store">Media Store getting started guide</a></li>
          </ul>
          <p>Before starting, it might help to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="auth">authentication guide</a></li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>

      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {label: "Getting started", scrollToId: "step-getting-started"},
              {
                label: "Exercise 1: Link structured and unstructured data", scrollToId: "exercise-1",
                items: [
                  {label: "Step 1: Create FHIR resources", scrollToId: "step-1-1"},
                  {label: "Step 2: Upload Media Store file", scrollToId: "step-1-2"},
                  {label: "Step 3: Create DiagnosticReport and link to file", scrollToId: "step-1-3"},
                  {label: "Step 4: Conclusion", scrollToId: "step-1-4"},
                ]
              },
              {
                label: "Exercise 2: Store metadata for a Media Store file", scrollToId: "exercise-2",
                items: [
                  {label: "Step 1: ...", scrollToId: "step-2-1"},
                  {label: "Step 2: ...", scrollToId: "step-2-2"},
                ]
              }
            ]}/>
        </Col>

        <Col md={9} sm={12}>

          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>

            <p>The ability to link Media Store files with FHIR resources provides a powerful mechanism for adding
              additional structured data to unstructured data, or simply to keep track of metadata, etc within Media
              Store.</p>

          </div>


          <div id="exercise-1" className="guide-step">
            <h2>Link structured and unstructured data</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Getting started with the FHIR server"
                            url="/resources/getting-started/fhir"
              >
                Integrate the NDP FHIR server into your application so you can store and recall FHIR clinical data
              </LandingBlock>
              <LandingBlock title="Getting started with Media Store"
                            url="/resources/getting-started/media-store"
              >
                Integrate the Media Store service into your application so you can store and recall unstructured
                clinical data
              </LandingBlock>
            </Row>


            <p>This section we'll upload a file to Media Store, and link a series of FHIR resources to build up
              a FHIR <code>DiagnosticReport</code>.</p>

            <p><i className="fa fa-check"></i> Before you start, it's important that you've completed both the guides
              above, as this guide does not cover the technicalities of how to create FHIR data or upload Media Store
              files.</p>

            <p>The FHIR resources we'll create are</p>
            <ul>
              <li>A <code>Patient</code> to represent the subject of the report</li>
              <li>A <code>Practitioner</code> to represent the author of the report</li>
              <li>The <code>Binary</code> resource (via Media Store)</li>
              <li>A <code>DocumentReference</code> to link the DiagnosticReport and Binary file</li>
              <li>A <code>DiagnosticReport</code> to represent the report itself</li>
            </ul>

            <img src={DiagnosticReportFhirDataHierarchy} className="border p-3 bg-white"
                 alt="Hierarchy diagram of FHIR resources"/>

          </div>
          <div id="step-1-1" className="guide-step">
            <h3>Step 1: Create initial FHIR resources</h3>

            <p>Use Postman (or another API test tool) to create the following resources within your FHIR server. Keep
              track of the <code>ID</code> field for the resources you've created.</p>

            <Tabs>
              <Tab eventKey="fhir-patient"
                   title="Patient">
                {!loading && <TurasCodePanel language="json"
                                             title="FHIR Patient resource"
                                             footer={<div className="text-secondary">
                                               <i className="fa fa-code"></i> Don't like this one? Generate a
                                               new <a href="#" onClick={(e) => {
                                               getRandomPatient();
                                               e.preventDefault();
                                             }}>random Patient</a></div>}
                                             maxHeight="35rem"
                                             copyButton>
                  {JSON.stringify(examplePatient, undefined, 2).trim()}
                </TurasCodePanel>}

                {loading && <Spinner variant="secondary"/>}
              </Tab>

              <Tab eventKey="fhir-practitioner"
                   title="Practitioner">
                {!loading && <TurasCodePanel language="json"
                                             title="FHIR Practitioner resource"
                                             footer={<div className="text-secondary">
                                               <i className="fa fa-code"></i> Don't like this one? Generate a
                                               new <a href="#" onClick={(e) => {
                                               getRandomPractitioner();
                                               e.preventDefault();
                                             }}>random Practitioner</a></div>}
                                             maxHeight="35rem"
                                             copyButton>
                  {JSON.stringify(examplePractitioner, undefined, 2).trim()}
                </TurasCodePanel>}

                {loading && <Spinner variant="secondary"/>}
              </Tab>

            </Tabs>


          </div>

          <div id="step-1-2" className="guide-step">
            <h3>Step 2: Upload Media Store file</h3>

            <p>Upload a file to Media Store. For the purposes of this guide, there no limitations on the file - it could
              be an image, PDF, Excel, etc. Keep track of the <code>Binary.ID</code> field of the file you've uploaded.
            </p>

          </div>

          <div id="step-1-3" className="guide-step">
            <h3>Step 3: Create DiagnosticReport and link to file</h3>

            <p>Use Postman (or another API test tool) to create the following resources within your FHIR server. Note
              that these snippets have placeholders which should be filled with values from the previous
              steps, such as <code>DocumentReference.attachment.url</code>,
              and <code>DiagnosticReport.subject.reference</code>.</p>

            <Tabs>
              <Tab eventKey="fhir-documentreference"
                   title="DocumentReference">
                <TurasCodePanel language="json"
                                title="FHIR DocumentReference resource"
                                footer={<div className="text-secondary">
                                  <i className="fa fa-lightbulb"></i> Remember to populate the placeholders in this
                                  snippet</div>}
                                maxHeight="35rem"
                                copyButton>
                  {JSON.stringify(exampleDocumentReference, undefined, 2).trim()}
                </TurasCodePanel>
              </Tab>

              <Tab eventKey="fhir-diagnosticreport"
                   title="DiagnosticReport">
                <TurasCodePanel language="json"
                                title="FHIR DiagnosticReport resource"
                                footer={<div className="text-secondary">
                                  <i className="fa fa-lightbulb"></i> Remember to populate the placeholders in this
                                  snippet</div>}
                                maxHeight="35rem"
                                copyButton>
                  {JSON.stringify(exampleDiagnosticReport, undefined, 2).trim()}
                </TurasCodePanel>
              </Tab>

            </Tabs>

            <p>That's it! You now have a Media Store file linked to a FHIR resource, specifically
              a <code>DiagnosticReport</code> which is often used to represent the findings and interpretation of
              diagnostic tests performed on patients.</p>
          </div>

          <div id="step-1-4" className="guide-step">
            <h3>Step 4: Conclusion</h3>

            <p>You've successfully linked a Media Store file to a FHIR resource!</p>

            <p>The example in this guide is for a very specific, and probably unrealistic clinical situation. Your own
              implementation of this will depend upon the application you're building, the data it's expected to handle,
              the clinical setting, etc.</p>

            <p>If your application's data can be easily represented using the existing <a
              href="https://hl7.org/fhir/resourcelist.html" target="_blank">FHIR resources</a> then it's recommended
              that you primarily use FHIR data structures to store your data. If you need more custom data structures
              then you may want to consider using the <a href="https://www.openehr.org/" target="_blank">openEHR data
                format</a> via NDP's <a href="/data-stores/openehr">openEHR server</a>.
            </p>

            <p>Of course, you don't have to store all of your data in FHIR or openEHR, or within the NDP. For example
              you may want to store internal transactional data in your own database as it is likely to be more
              customisable to your own business processes.</p>
            <p>We recommend that you store any clinical data within NDP, as this means it will be available to other
              applications. Conversely, other applications who store clinical data within the NDP will make that
              data available to your application and users.</p>
          </div>

          <div id="exercise-2" className="guide-step">
            <h2>Store metadata for a Media Store file</h2>

            <GenericAlert title="Work in progress" alertBg="danger" faIcon="fa-construction">
              <p>This guide is a work in progress.</p>
              <p>We hope to have it ready for preview in early April 2023.</p>
            </GenericAlert>

            <p>This section ...</p>
          </div>

          <div id="step-2-1" className="guide-step">
            <h3>Step 1: ...</h3>

            <p>This section ...</p>

          </div>

          <div id="step-2-2" className="guide-step">
            <h3>Step 2: ...</h3>

            <p>This section ...</p>

          </div>

        </Col>

      </Row>

    </TurasSection>

  </BaseScreen>;
}
