import React, {ReactNode} from "react";


export type TurasTableRowMenuProps = {
  items: {
    title: string | "---";
    url?: string;
  }[];
}

export type TurasTableRowProps = {
  cells: (ReactNode | string)[];
  menu?: TurasTableRowMenuProps;
}


export type TurasTableProps = {
  headers?: string[];
  rows: TurasTableRowProps[];
}

export function TurasTableRowMenu(props: TurasTableRowMenuProps): JSX.Element {
  return <div className="btn-group actions-drop-down">
    <button type="button"
            className="btn btn-actions dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
    </button>
    <div className="dropdown-menu">
      {props.items.map((item, i) => {
        if (item.title === '---')
          return <div className="dropdown-divider"
                      key={`table-dd-div-${i}`}></div>

        return <a className="dropdown-item"
                  href={item.url}
                  key={`table-dd-item-${i}`}>{item.title}</a>
      })}
    </div>
  </div>
}

export default function TurasTable(props: TurasTableProps): JSX.Element {
  return <div className="table-responsive ">
    <table className="table">

      {props.headers && <thead>
      <tr>
        {props.headers.map((h, i) => <th key={`table-row-th-${i}`}>{h}</th>)}
      </tr>
      </thead>}

      <tbody>
      {props.rows.map((row, iRow) => <tr key={`table-row-${iRow}`}>

        {row.cells.map((item, iCol) => <td key={`table-cell-${iRow}-${iCol}`}>{item}</td>)}

        {row.menu && <TurasTableRowMenu {...row.menu}/>}

      </tr>)}
      </tbody>
    </table>
  </div>
}
