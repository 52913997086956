import {Badge, Card, Col, Row} from "react-bootstrap";
import React from "react";


export type ServiceMaturity = 'aspirational' | 'pilot' | 'mvp' | 'stable';
export type ServiceStatusPanelProps = {
  productionServiceMaturity: ServiceMaturity;
  productionServiceStatusDescription: string;
  sandboxServiceMaturity: ServiceMaturity;
  sandboxServiceStatusDescription: string;
  sandboxLimitations: string[];
}
export default function ServiceStatusPanel(props: ServiceStatusPanelProps): JSX.Element {
  function ServiceMaturityBadge(props: { status: ServiceMaturity }): JSX.Element {
    switch (props.status) {
      case 'aspirational':
        return <Badge bg="danger">Aspirational</Badge>;
      case'pilot':
        return <Badge bg="warning">Pilot</Badge>;
      case'mvp':
        return <Badge bg="info">MVP</Badge>;
      case'stable':
        return <Badge bg="success">Stable</Badge>;
      default:
        return <Badge bg="danger">Unknown</Badge>;
    }
  }

  return <Row>
    <Col sm={12} md={12} lg={12}>
      <Card>
        <div className="card-heading">
          <Card.Title as="h2">Service status</Card.Title>
        </div>
        <Card.Body className="row">
          <Col sm={12} md={8} className="border-right">
            <h4>In production <ServiceMaturityBadge status={props.productionServiceMaturity}/></h4>
            <p>{props.productionServiceStatusDescription}</p>

            <h4>In sandbox <ServiceMaturityBadge status={props.sandboxServiceMaturity}/></h4>
            <p>{props.sandboxServiceStatusDescription}</p>

          </Col>
          {/*<Col sm={12} md={4} className="border-right">
            <h4>Service Maturity</h4>
            <dl className="row">
              <dt className="col-sm-6 col-md-7">In production</dt>
              <dd className="col-sm-6 col-md-5"><ServiceMaturityBadge status={props.productionServiceMaturity}/></dd>
              <dt className="col-sm-6 col-md-7">In sandbox</dt>
              <dd className="col-sm-6 col-md-5"><ServiceMaturityBadge status={props.sandboxServiceMaturity}/></dd>
            </dl>
          </Col>*/}
          <Col sm={12} md={4}>
            <h4>Sandbox limitations</h4>
            {props.sandboxLimitations && props.sandboxLimitations.length > 0 && <ul>
              {props.sandboxLimitations.map(l => <li>{l}</li>)}
            </ul>}

            {(!props.sandboxLimitations || props.sandboxLimitations.length === 0) && <ul>
              <li><em>None specified</em></li>
            </ul>}

          </Col>

        </Card.Body>
      </Card>
    </Col>
  </Row>;
}
