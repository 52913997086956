import {Form} from "react-bootstrap";
import React from "react";

export type TurasCheckboxProps = {
  label: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
  validationErrorLabel?: string;
  readonly?: boolean;
  disabled?: boolean;
}

export default function TurasCheckbox(props: TurasCheckboxProps): JSX.Element {
  function handleItemSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue: boolean = event.target.checked;

    if (props.onCheckedChange)
      props.onCheckedChange(newValue);
  }

  const randId = (Math.random() + 1).toString(36).substring(7);
  const controlId = `chk-${randId}`;

  return <Form.Group
    className="form-group mb-0">
    <Form.Check type="checkbox"
                key={controlId}
                className="custom-checkbox mw-100"
                feedback={props.validationErrorLabel}
    >
      <Form.Check.Input type="checkbox"
                        value="true"
                        id={`${controlId}-chk`}
                        checked={props.checked}
                        required={props.required}
                        onChange={handleItemSelect}
                        readOnly={props.readonly}
                        disabled={props.disabled}
      />

      <Form.Check.Label
        className="form-check-label"
        htmlFor={`${controlId}-chk`}
      >{props.label}</Form.Check.Label>

    </Form.Check>
  </Form.Group>;
}
