import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";

import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useLocalStorage} from "usehooks-ts";

import AdminAPI from "../service/AdminAPI";
import {initGoogleAnalytics} from "./ScreenHelpers";

import Footer from "../components/Footer";
import GlobalNav from "../components/GlobalNav";
import MainNavbar from "../components/MainNavbar";
import LandingBlock from "../components/LandingBlock";
import {ContentLoginButton} from "../components/UserHandler";
import {ErrorAlert, InfoAlert, WarningAlert} from "../components/AlertBox";
import TurasCheckbox from "../components/TurasCheckbox";
import {TurasSection} from "../components/TurasSection";
import {LoadingModal} from "../components/LoadingSpinner";
import {CookieConsentModal} from "../components/modals/Cookie";

import {defaultNavMenuItems} from "../components/NavbarItems";
import {localStorageKeys} from "../constants";
import {DeveloperAccount} from "../models";
import {environment} from "../config";

import homeIllustration from "../img/illustrations/home_illustration.png"
import docsIcon from "../img/icons/documentation.svg";
import apisIcon from "../img/icons/apis.svg";
import sandboxesIcon from "../img/icons/sandbox.svg";
import dataStoresIcon from "../img/icons/data-stores.svg";
import ClipboardButton from "../components/forms/ClipboardButton";

const landingPageTitle = "National Digital Platform Developer Portal";
const landingPageTagline = "Use and manage a sandbox, access developer resources, API catalogue and data stores";

function LandingPageBanner(props: React.PropsWithChildren<{}>): JSX.Element {
  return <div className="main-banner" id="mainbanner">
    <div className="container">
      <div className="row">

        <div className="col-md-10">
          <div className="home-banner-text">
            <h1 id="bannerPageTitle">{landingPageTitle}</h1>
            <p id="tagline">{landingPageTagline}</p>

            {props.children}
          </div>
        </div>

      </div>
    </div>
  </div>;
}

function LandingPageBannerWithImage(props: React.PropsWithChildren<{}>): JSX.Element {
  return <div className="main-banner bg-grey" id="sign-in">
    <div className="container">
      <div className="row">

        <div className="col-lg-6 flexy-text">
          <div className="home-banner-text flexy-content">
            <h1 id="bannerPageTitle">{landingPageTitle}</h1>
            <p id="tagline">{landingPageTagline}</p>

            {props.children}
          </div>
        </div>

        <div className="d-none d-lg-block col-lg-6 col-xl-6">
          <img src={homeIllustration} className="img-fluid" alt=""/>
        </div>

      </div>
    </div>
  </div>;
}

function PreLandingPageWrapper(props: React.PropsWithChildren<{}>): JSX.Element {
  return <>
    <div className="wrapper">
      <GlobalNav/>

      <LandingPageBannerWithImage>
        {props.children}
      </LandingPageBannerWithImage>

    </div>
    <Footer/>
    <CookieConsentModal siteName="Developer Portal"
                        cookiePolicyUrl="/legal/cookies"/>
  </>
}

function LandingPageWrapper(props: React.PropsWithChildren<{}>): JSX.Element {

  return <>
    <div className="wrapper">
      <GlobalNav/>
      <MainNavbar navbarItems={defaultNavMenuItems}/>

      <LandingPageBanner/>

      {props.children}

    </div>
    <Footer/>
    <CookieConsentModal siteName="Developer Portal"
                        cookiePolicyUrl="/legal/cookies"/>
  </>
}

function BetaWarning(): JSX.Element {
  return <InfoAlert title="This portal is in closed beta.">
    <p>You can register interest, but you may need to wait until we are in open beta to gain access.</p>
  </InfoAlert>
}

function UnAuthenticatedLanding() {
  return <PreLandingPageWrapper>
    <BetaWarning/>

    <ContentLoginButton/>

    <a href="https://www.nationaldigitalplatform.scot/contact-us" target="_blank"
       className="btn btn-outline">Register interest</a>

  </PreLandingPageWrapper>;
}

function LoadingLanding() {
  return <PreLandingPageWrapper>
    <LoadingModal title="Setting up NDP Developer Portal"
                  message="If this is your first sign in it might take up to a minute to get everything ready."
                  spinnerVariant="primary"/>
  </PreLandingPageWrapper>;
}

function UnAuthorizedLanding() {
  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);


  const [showOidForm, setShowOidForm] = useState<boolean>(false);
  const [myOid, setMyOid] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  async function loadUserOid() {
    const userInfo = await api.getUserInfo();
    setErrorMessage(undefined);
    setMyOid(undefined);

    if (!userInfo) {
      setErrorMessage("The server did not respond with any user info");
      return;
    }

    if (!userInfo.idp) {
      setErrorMessage("The server did not respond with any IdP info");
      return;
    }

    setMyOid(userInfo.idp.idp_identifier);
  }


  return <PreLandingPageWrapper>
    <BetaWarning/>

    <p className="text-danger">Your Turas account does not have permission to access the National Digital Platform
      Developer Portal.</p>

    <><a href="https://www.nationaldigitalplatform.scot/contact-us" target="_blank"
          className="btn btn-primary">Register interest</a></>

    {!showOidForm && <>
      <a className="btn btn-link" onClick={() => setShowOidForm(true)}>View support info</a>
    </>}

    {showOidForm && <>
      <hr className="mt-50 mb-50"/>
      <Row className="mt-5">
        <Col md={9} sm={12}>
          <Form className="turasForm">
            <Form.Group className='form-group'>
              <Form.Label>Your Turas OID</Form.Label>
              <InputGroup>
                <Form.Control type="text"
                              value={myOid}
                              placeholder="********"
                              readOnly={true}/>
                {!myOid && <Button className="btn btn-outline" style={{margin: 0, color: "#000"}}
                                   onClick={loadUserOid}><i className="fa fa-eye"
                                                            style={{color: '#000'}}></i></Button>}
                {myOid && <ClipboardButton value={myOid}/>}
              </InputGroup>
              <Form.Text>If asked for your OID by NES support, please provide them with this value.</Form.Text>

            </Form.Group>
          </Form>
          {errorMessage && <ErrorAlert title="Error loading OID"
                                       text={errorMessage}/>}
        </Col>
      </Row>
    </>
    }

  </PreLandingPageWrapper>;
}

function ReviewToSLanding() {
  const [validated, setValidated] = useState(false);
  const msal = useMsal();

  async function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    // Failure path
    if (form.checkValidity() === false) {
      return;
    }

    // Success path
    const adminApi = AdminAPI.fromMsal(msal);
    await adminApi.acceptTermsOfService();

    // refresh the user info, and the page
    await adminApi.getUserInfo();
    window.location.replace('/');
  }

  return <PreLandingPageWrapper>

    <WarningAlert title="">
      <p>Before you can access the NDP Developer Portal you must review and accept the <a href="/terms">Terms and
        Conditions</a></p>
    </WarningAlert>

    <Form noValidate validated={validated} onSubmit={handleSubmit} className="turasForm">

      <TurasCheckbox
        label="I confirm I have read the terms and conditions and agree to be bound by those terms"
        validationErrorLabel="You must..."
        required
      />

      <Button type="submit">Continue</Button>
    </Form>

  </PreLandingPageWrapper>;

}

function AuthenticatedLanding() {
  return <LandingPageWrapper>

    <TurasSection>
      <div className="form-row">

        <LandingBlock title="Developer resources"
                      url="/resources"
                      icon={docsIcon}
        >
          <p>Browse the documentation for supported products.</p>
        </LandingBlock>

        <LandingBlock title="API catalogue"
                      url="/apis"
                      icon={apisIcon}
        >
          <p>Get information about our API catalogue and it’s capabilities.</p>
        </LandingBlock>

        <LandingBlock title="Data stores"
                      url="/data-stores"
                      icon={dataStoresIcon}
        >
          <p>View and manage data stores including FHIR CDR, Media Store and openEHR CDR.</p>
        </LandingBlock>

        <LandingBlock title="Manage sandbox"
                      url="/sandbox"
                      icon={sandboxesIcon}
        >
          <p>Manage your current sandbox and find quick access and authentication details.</p>
        </LandingBlock>

      </div>
    </TurasSection>
  </LandingPageWrapper>;
}

function Landing() {
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();
  const location = useLocation();

  const [currentUser] = useLocalStorage<DeveloperAccount | undefined>(localStorageKeys.currentUser, undefined);
  const [selectedSandboxId, setSelectedSandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  useEffect(() => {
    let rv: any = undefined;

    if (!environment.googleAnalytics)
      return;

    // @ts-ignore
    if (!window.gtag)
      rv = initGoogleAnalytics();

    // @ts-ignore
    if (window.gtag) window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });

    return rv;
  }, [location]);

  useEffect(() => {
    if (!currentUser) return;
    if (selectedSandboxId) return;

    const api = AdminAPI.fromMsal(msal);
    api.getTeamsList('mine', 'approved')
      .then(sandboxes => {
        if (!sandboxes || sandboxes.length === 0) return;

        const firstSandbox = sandboxes[0];
        console.info(`No sandbox selected. Selecting first approved sandbox ${firstSandbox.id}`);
        // @ts-ignore
        if (window.gtag) window.gtag('event', 'select_sandbox',
          {
            'sandbox_id': firstSandbox.id,
            'reason': 'implicit',
          }
        )
        setSelectedSandboxId(firstSandbox.id);
      })
  }, [selectedSandboxId, currentUser])

  // User is unauthenticated
  if (!isAuthenticated)
    return <UnAuthenticatedLanding/>;

  // const user = getCurrentUser();

  // User is authenticated but data isn't loaded yet
  if (!currentUser || !currentUser.permissions)
    return <LoadingLanding/>;

  // User's permissions are incorrect
  if (!currentUser?.permissions?.sandbox_access_granted)
    return <UnAuthorizedLanding/>;

  // User hasn't accepted ToS
  // AB#33987 Removed T&Cs from Dev Portal for the time being
  // if (!currentUser?.permissions?.tos_accepted)
  //   return <ReviewToSLanding/>;

  // All good
  return <AuthenticatedLanding/>;
}

export default Landing;
