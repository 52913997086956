import {NavbarItem} from "./MainNavbar";

export const defaultNavMenuItems: NavbarItem[] = [
  {title: "Home", id: "home", url: "/"},

  {title: "Developer resources", id: "dev-resources", url: "/resources"},

  {
    title: "API Catalogue",
    id: "api-catalogue-menu",
    children: [
      // {title: "API Catalogue", id: "api-catalogue", url: `/apis`},
      {title: "EMPI", id: "empi", url: `/apis/empi`},
      {title: "Vaccinations (NCDS)", id: "ncds", url: `/apis/ncds`},
      {title: "Care Summary Proxy", id: "care-summary", url: `/apis/care-summary`},
    ],
  },

  {
    title: "Data stores",
    id: "data-stores-menu",
    children: [
      // {title: "Data stores", id: "data-stores", url: `/data-stores`},
      {title: "FHIR CDR", id: "fhir-server", url: `/data-stores/fhir`},
      {title: "Media Store", id: "media-store", url: `/data-stores/media-store`},
      {title: "openEHR CDR", id: "openehr", url: `/data-stores/openehr`},
      {title: "Manage sandbox data", id: "sandbox-data", url: `/sandbox/data`},
    ]
  },

  {
    title: "Manage sandbox",
    id: "sandbox-menu",
    children: [
      {title: "Sandbox overview", id: "sandbox-summary", url: `/sandbox`},
      {title: "Quick access details", id: "sandbox-quick-access", url: `/sandbox/quick-access`},
      {title: "Authentication overview", id: "sandbox-auth", url: `/sandbox/auth`},
      {title: "Manage sandbox data", id: "data", url: `/sandbox/data`},
    ]
  },
];

