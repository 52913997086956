import BaseScreen from "../BaseScreen";
import {TurasSection} from "../../components/TurasSection";


export function CorsInstructionMessage() {
  return (

    <div>
      <p>This will prevent you from using the <b>"Try It!"</b> functionality for FHIR within this web app.</p>

      <p>As a temporary bypass you can use the NPM package <code>cors-anywhere</code>. You'll need NodeJS and
        NPM installed.</p>

      <ul>
        <li>Create an empty folder (e.g. <code>simple-cors-proxy</code>)</li>
        <li>CD into this directory</li>
        <li>Download two files <a href="/media/cors-proxy/package.json">package.json</a> and <a
          href="/media/cors-proxy/app.js">app.js</a></li>
        <li>Run <kbd>npm install</kbd></li>
        <li>Run <kbd>npm run start</kbd></li>
      </ul>

      <p>This will create a reverse-proxy at http://localhost:7080, which strips the CORS headers. This web app
        will automatically use the reverse-proxy for FHIR Works requests only. <strong>Obviously, this should not
          be used in prod!</strong></p>
    </div>
  )
}

function CorsInstructionsScreen() {

  return <BaseScreen
    pageTitle="CORS instructions"
    pageSubtitle="Cross-Origin Resource Sharing is a security mechanism that allows restricted resources on a web page to be requested from another domain"
  >
    <TurasSection>
      <h2>We have not enabled CORS on FHIR Works yet</h2>

      <CorsInstructionMessage/>
    </TurasSection>
  </BaseScreen>;
}

export default CorsInstructionsScreen;
