import React from "react";
import {Container} from "react-bootstrap";

export function TurasSection(props: React.PropsWithChildren<{}>): JSX.Element {
  return <section className="turasSection">
    <Container>
      {props.children}
    </Container>
  </section>;
}
