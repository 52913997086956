import React from "react";


export type TurasPageBannerProps = React.PropsWithChildren<{
  text?: string
}>;

export function TurasPageWarningBanner(props: TurasPageBannerProps): JSX.Element {
  return <div className="bg-warning pt-3 pb-3">
    <div className="container">
      <p className="m-0">
      <span className="fa fa-exclamation-triangle"
            aria-hidden="true"></span>{` `}
        {props.text}
        {props.children}
      </p>
    </div>
  </div>
}
