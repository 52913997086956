import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";

import {useMsal} from "@azure/msal-react";

import AdminAPI from "../../../service/AdminAPI";
import {getFriendlyErrorMessage} from "../../../helpers";
import {FhirDataSummaryResponse, GenerateFhirDataRequest, Team} from "../../../models";
import * as ScreenHelpers from "../../ScreenHelpers";
import {localStorageKeys} from "../../../constants";

import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import StyledHyperlink from "../../../components/StyledHyperlink";
import ClipboardButton from "../../../components/forms/ClipboardButton";
import TurasCodePanel from "../../../components/TurasCodePanel";
import TurasSideNav from "../../../components/TurasSideNav";

export default function GenerateFhirDataItemsScreen(): JSX.Element {

  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  type DataGenerationItem = {
    fhirType: string;
    count: number;
  }

  async function generateData(config: DataGenerationItem[]): Promise<FhirDataSummaryResponse | undefined> {
    setErrorMessage(undefined);
    // setGeneratingFhirData(true);
    console.debug(`Generating FHIR data`);

    if (!team) return;

    const counts: GenerateFhirDataRequest = {
      resource_types: config.map(c => ({resource_name: c.fhirType, count: c.count}))
    };

    try {
      const fd = await api.populateRandomFhirData(team.id, counts);
      return fd;

      // Give our poor FHIR server time to catch up
      // TODO: finish displaying results
      // setLoadingFhirData(true);
      // setTimeout(() => loadFhirData(), 2000);
    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
    }
    // setGeneratingFhirData(false);
  }


  useEffect(() => {
    getAndDisplaySandbox();
    // .then(() => loadFhirData());
  }, [sandboxId]);

  type GenerateDataCardProps = {
    fhirType: string;
    description: string;
    availableCounts?: number[];
    requires?: string[];
  }

  function GenerateDataCard(props: GenerateDataCardProps): JSX.Element {
    const [count, setCount] = useState<number>(props.availableCounts ? props.availableCounts[0] : 1);

    const [generatedFhirData, setGeneratedFhirData] = useState<FhirDataSummaryResponse | undefined>();
    const [generatingFhirData, setGeneratingFhirData] = useState<boolean>(false);

    async function handleGenerateData(config: DataGenerationItem[]) {
      setGeneratedFhirData(undefined);
      setGeneratingFhirData(true);
      const results = await generateData(config);
      setGeneratedFhirData(results);
      setGeneratingFhirData(false);
    }


    return <div className="col-sm-12 cardPaddingBottom">
      <div className="card landingBlock h-100" id={`generate-${props.fhirType}`}>
        <div className="card-heading">
          <h3 className="card-title">{props.fhirType}</h3>
        </div>
        <div className="card-body d-flex flex-column">

          <Row>
            <Col md={7} sm={12}>
              <p>{props.description}</p>
              {props.requires && props.requires.length > 0 && <p>
                Requires{' '}
                {props.requires.map(r => <Badge bg="secondary" key={r}>{r}</Badge>)}
              </p>}
              <p>See <StyledHyperlink
                href={`https://hl7.org/FHIR/${props.fhirType.toLowerCase()}.html`}>FHIR documentation</StyledHyperlink>
              </p>

              <Form className="turasForm class mb-2">
                {/* Available counts dropdown (if specified) */}
                {props.availableCounts && <div className='form-group m-0'>
                  <label className='d-block'>Generate</label>
                  <Row>
                    <Col className='col-md-5 pr-0'>
                      <select
                        className="form-control mr-2"
                        value={count}
                        onChange={e => setCount(parseInt(e.target.value))}
                      >
                        {props.availableCounts.map(n => <option key={n}>{n}</option>)}
                      </select>
                    </Col>

                    <Col className='col-md-5'>
                      <Button type="button"
                              variant="success"
                              className="m-0 btn-block"
                              disabled={generatingFhirData}
                              onClick={() => handleGenerateData([{fhirType: props.fhirType, count: count}])}
                      ><i className="fa fa-plus mr-1"></i> Add</Button>
                    </Col>
                  </Row>
                </div>}

                {/* No available counts dropdown */}
                {!props.availableCounts && <Row>
                  <Col md={9} sm={12}>
                    <Button type="button"
                            variant="success"
                            className="m-0 btn-block"
                            disabled={generatingFhirData}
                            onClick={() => handleGenerateData([{
                              fhirType: props.fhirType,
                              count: count
                            }])}
                    ><i className="fa fa-plus mr-1"></i> Add {count} {props.fhirType}{count > 1 && 's'}</Button>
                  </Col>
                </Row>}

              </Form>
            </Col>

            <Col md={5} sm={12} className="border-left">
              {generatingFhirData && <Spinner animation="border" variant="default">
                <span className="invisible">Loading...</span>
              </Spinner>}

              {generatedFhirData?.resource_types.map(rt => <>
                {rt.count === 1 && <>
                  <h4>Generated {rt.resource_name}</h4>

                  <Form className="turasForm">
                    <Form.Group className='form-group'>
                      <Form.Label>{rt.resource_name} ID</Form.Label>
                      <InputGroup>
                        <Form.Control type="text"
                                      value={rt.ids![0]}
                                      readOnly={true}/>
                        <ClipboardButton value={rt.ids![0]}/>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </>}

                {rt.count !== 1 && <>
                  <h4>Generated {rt.count} {rt.resource_name}s</h4>

                  <strong>{rt.resource_name} IDs</strong>

                  <TurasCodePanel language="text" copyButton>
                    {rt.ids?.join(`\n`).trim() || ''}
                  </TurasCodePanel>
                </>}

              </>)}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  }

  type AvailableGenerationItemProps = {
    fhirType: string;
    description: string;
    availableQuantities?: number[];
    requires?: string[];
  }

  const availableGenerationItems: AvailableGenerationItemProps[] = [
    {
      fhirType: "AllergyIntolerance",
      description: "A single Patient's allergy to a substance or intolerance to a certain drug",
      availableQuantities: [1, 5, 10],
      requires: ["Patient"],
    },
    {
      fhirType: "Condition",
      description: "A patient or group's clinical condition, problem, diagnosis, or other event, situation, issue, or clinical concept that has risen to a level of concern",
      availableQuantities: [1, 5, 10],
      requires: ["Patient"],
    },
    {
      fhirType: "Immunization",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: ["Patient"],
    },
    {
      fhirType: "Location",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: [],
    },
    {
      fhirType: "MedicationKnowledge",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: [],
    },
    {
      fhirType: "Organization",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: [],
    },
    {
      fhirType: "Patient",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: [],
    },
    {
      fhirType: "Practitioner",
      description: "",
      availableQuantities: [1, 5, 10],
      requires: [],
    },
  ]

  const pageTitle = "Generate FHIR data for your sandbox";
  const pageSubtitle = "";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >
    <TurasSection>


      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav title="Skip to FHIR resource"
                        items={availableGenerationItems.map(ava => ({
                          label: ava.fhirType, scrollToId: `generate-${ava.fhirType}`
                        }))}/>
          
        </Col>

        <Col md={9} sm={12}>
          <h2>Generate FHIR data</h2>

          {/*<InfoAlert>*/}
          <div className="ml-md-4 mr-md-4">
            <h3><i className="fa fa-info-circle"></i> Resources requiring a Patient</h3>
            <p>Where a Patient is required for one of these FHIR resources, the resource will
              be generated with a random patient ID. You can still use the resource but the Patient will not exist.
              These
              resources are marked with <Badge bg="secondary">Patient</Badge>.</p>
            <p>If you need the patient to exist, use the <a href="datasets">Generate datasets</a> tool to generate all
              the
              resources simultaneously. Alternatively, you can use a tool like <a
                href="/data-stores/fhir/postman">Postman</a> or the <StyledHyperlink
                text="Random Data Generator"
                href="https://random-data.platform.ndp.scot/fhir/patient"/> together with your own test data scripts.
            </p>
          </div>

          <h2>Generate individual FHIR data items</h2>

          <Row>
            {availableGenerationItems.map(ava => <GenerateDataCard fhirType={ava.fhirType}
                                                                   description={ava.description}
                                                                   availableCounts={ava.availableQuantities}
                                                                   requires={ava.requires}
                                                                   key={`generate-${ava.fhirType}`}
            />)}

          </Row>

        </Col>

      </Row>

    </TurasSection>
  </SandboxBaseScreen>;

}
