import React, {useRef, useState} from "react";
import {Button, Overlay, Tooltip} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";

async function copyToClipboard(value: string, setCopied: (value: (((prevState: boolean) => boolean) | boolean)) => void) {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(value);
  } else {
    document.execCommand('copy', true, value);
  }

  setCopied(true);
  setTimeout(() => setCopied(false), 2000);
}

type ClipboardButtonParams = {
  value: string;
  disabled?: boolean;
  buttonVariant?: Variant;
}
export default function ClipboardButton(props: ClipboardButtonParams) {
  const [copied, setCopied] = useState<boolean>(false);
  const target = useRef(null);

  return <Button className={`btn btn-${props.buttonVariant || 'secondary'}`}
                 type="button"
                 ref={target}
                 style={{margin: 0}}
                 disabled={props.disabled}
                 onClick={() => copyToClipboard(props.value, setCopied)}>
    <i className="fa fa-clipboard"></i>
    <Overlay target={target.current} show={copied} placement="right">
      {({placement, arrowProps, show: _show, popper, ...props}) => (
        <Tooltip id="overlay-example" {...props}>
          Copied
        </Tooltip>
      )}
    </Overlay>
  </Button>
}

type ClipboardLinkParams = {
  value: string;
  label?: string;
}

export function ClipboardLink(props: ClipboardLinkParams) {
  const [copied, setCopied] = useState<boolean>(false);
  const target = useRef(null);


  return <a href="#"
            ref={target}
            onClick={(e) => {
              copyToClipboard(props.value, setCopied);
              e.preventDefault();
            }}>

    {props.label || 'Copy'}

    <Overlay target={target.current} show={copied} placement="right">
      {({placement, arrowProps, show: _show, popper, ...props}) => (
        <Tooltip id="overlay-example" {...props}>
          Copied
        </Tooltip>
      )}
    </Overlay>
  </a>
}
