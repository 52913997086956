import React, {useEffect, useState} from 'react';
import {useLocalStorage} from "usehooks-ts";

import {useMsal} from "@azure/msal-react";

import AdminAPI from "../../../service/AdminAPI";
import {FhirDataSummaryResponse, Team} from "../../../models";
import * as ScreenHelpers from "../../ScreenHelpers";
import {localStorageKeys} from "../../../constants";
import {FhirR4ResourceNames} from "../../../fhirHelpers";

import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import TurasCheckbox from "../../../components/TurasCheckbox";
import {getFriendlyErrorMessage} from "../../../helpers";


export default function ClearDataScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [pageLocked, setPageLocked] = useState<boolean>(false);
  const [resetSandboxProcessing, setResetSandboxProcessing] = useState<boolean>(false);

  const [selectedFhirResource, setSelectedFhirResource] = useState<string>('');
  const [deleteFhirResourceProcessing, setDeleteFhirResourceProcessing] = useState<boolean>(false);
  const [deleteFhirResourceResult, setDeleteFhirResourceResult] = useState<FhirDataSummaryResponse | undefined>(undefined);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  // props: {processing: boolean, pageLocked: boolean, submitHandler: () => void}
  function ResetSandboxCard(): JSX.Element {
    const [formValidated, setFormValidated] = useState<boolean>(false);

    function handleSubmit(event: any) {
      event.preventDefault();
      event.stopPropagation();

      setResetSandboxProcessing(false);

      const form = event.currentTarget;

      // Failure path
      if (form.checkValidity() === false) {
        setFormValidated(true);
        return;
      }

      // Success path
      setFormValidated(false);
      setResetSandboxProcessing(true);
      setPageLocked(true);

      const adminApi = AdminAPI.fromMsal(msal);
      // await adminApi.del();
      // alert('delete stuff!');

      // refresh the user info, and the page
      // await adminApi.getUserInfo();
      // window.location.replace('/');
      setTimeout(() => {
        setPageLocked(false);
        setResetSandboxProcessing(false);
      }, 5000);
    }

    return <Card>
      <div className="card-heading">
        <h3 className="card-title">Delete ALL sandbox data</h3>
      </div>
      <Card.Body>
        <Row>
          <Col md={7} sm={12}>
            <p>Use this option to delete data that has been created within your sandbox</p>

            <strong>What WILL be deleted</strong>
            <ul className="list-unstyled">
              <li><i className="fa fa-check text-success"></i> All FHIR data</li>
            </ul>

            <strong>What will NOT be deleted</strong>
            <ul className="list-unstyled">
              <li><i className="fa fa-close text-danger"></i> The sandbox itself</li>
              <li><i className="fa fa-close text-danger"></i> OAuth users and passwords</li>
              <li><i className="fa fa-close text-danger"></i> OAuth client credentials</li>
            </ul>

          </Col>


          {!resetSandboxProcessing && <Col md={5} sm={12}>
            <div className="vertical-center">
              <Form className="turasForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                <p className="text-danger"><i className="fa fa-warning mr-1"></i> This action cannot be undone</p>
                <TurasCheckbox label="Tick this box to confirm"
                               validationErrorLabel="You must tick this box before continuing"
                               required/>
                <Button variant="danger" type="submit"
                        disabled={true || pageLocked}
                ><i className="fa fa-trash-o mr-1"></i> Delete ALL data</Button>
                <p className="text-danger"><i className="fa fa-construction mr-1"></i> This feature is a work in progress for Beta 1</p>
              </Form>
            </div>
          </Col>}

          {resetSandboxProcessing && <Col md={5} sm={12}>
            <h3 className="mt-0">Deleting all data...</h3>
            <p>This will probably take a while. You should avoid using your sandbox during the deletion, otherwise
              some data may not be deleted or the process may fail.
            </p>
            <Spinner animation="border" variant="primary">
              <span className="invisible">Loading</span>
            </Spinner>
            <p>The process is complete when all counts on the <a href="../data">Data overview page</a> reach zero. The
              current page will not update.</p>
          </Col>}

        </Row>
      </Card.Body>
    </Card>;
  }

  function DeleteFhirResourceCard(): JSX.Element {
    const [formValidated, setFormValidated] = useState<boolean>(false);

    async function handleSubmit(event: any) {
      event.preventDefault();
      event.stopPropagation();

      setDeleteFhirResourceProcessing(false);
      setErrorMessage(undefined);

      const form = event.currentTarget;

      // Failure path
      if (form.checkValidity() === false) {
        setFormValidated(true);
        return;
      }

      // Success path
      setFormValidated(false);
      setDeleteFhirResourceResult(undefined);
      setDeleteFhirResourceProcessing(true);
      setPageLocked(true);

      // Perform the delete
      try {
        const adminApi = AdminAPI.fromMsal(msal);
        const result = await adminApi.deleteFhirResourceType(sandboxId!, selectedFhirResource);

        // Store the results
        setDeleteFhirResourceResult(result);
      } catch (e) {
        setErrorMessage({
          title: `Error deleting FHIR resource ${selectedFhirResource}`,
          text: getFriendlyErrorMessage(e)
        });
      }

      setDeleteFhirResourceProcessing(false);
      setPageLocked(false);
    }

    return <Card>
      <div className="card-heading">
        <h3 className="card-title">Delete a specific FHIR resource type</h3>
      </div>
      <Card.Body>
        <Row>
          <Col md={7} sm={12}>
            <p>Use this option to delete all FHIR items with a specific resource type</p>


            <strong>What WILL be deleted</strong>
            <ul className="list-unstyled">
              <li><i className="fa fa-check text-success"></i> All FHIR items with this resource type</li>
            </ul>

            <strong>What will NOT be deleted</strong>
            <ul className="list-unstyled">
              <li><i className="fa fa-close text-danger"></i> Any other FHIR resource type</li>
            </ul>

            <Form className="turasForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
              <div className='form-group m-0'>
                <label className='d-block'>Delete all of</label>
                <Row>

                  <Col md={5} className="pr-0">
                    <select
                      className="form-control mr-2"
                      value={selectedFhirResource}
                      onChange={e => setSelectedFhirResource(e.target.value)}
                      disabled={deleteFhirResourceProcessing}
                      required
                    >
                      <option value="">-- Select FHIR type --</option>
                      {FhirR4ResourceNames.map(n => <option key={n}>{n}</option>)}
                    </select>
                  </Col>
                  <Col md={5}>
                    <Button type="submit"
                            variant="danger"
                            className="m-0 btn-block"
                            disabled={pageLocked}
                    ><i className="fa fa-trash-o mr-1"></i> Delete</Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>

          {deleteFhirResourceProcessing && <Col md={5} sm={12}>
            <h3 className="mt-0">Deleting data...</h3>
            <p>This might take a while. You should avoid using your sandbox during the deletion, otherwise
              some data may not be deleted or the process may fail.
            </p>
            <Spinner animation="border" variant="primary">
              <span className="invisible">Loading</span>
            </Spinner>
            <p>This page will update when the proces is complete...</p>
          </Col>}

          {deleteFhirResourceResult && <Col md={5} sm={12}>
            <h3 className="mt-0">Delete complete</h3>
            <p>Deleted {deleteFhirResourceResult.resource_types[0].count}{' '}
              {deleteFhirResourceResult.resource_types[0].resource_name}
              {deleteFhirResourceResult.resource_types[0].count !== 1 && 's'}</p>
          </Col>}

        </Row>
      </Card.Body>
    </Card>;
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Clear sandbox data";
  const pageSubtitle = "Use this screen to clear some or all of your sandbox data.";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >
    <TurasSection>

      <h2>Reset your sandbox</h2>

      <ResetSandboxCard/>

      <h2>Clear individual data stores</h2>

      <h3>FHIR resources</h3>

      <DeleteFhirResourceCard/>

    </TurasSection>
  </SandboxBaseScreen>;
}
