import axios, {AxiosRequestConfig} from "axios";
import {Binary, Bundle, FhirResource, Resource} from "fhir/r4";

import {environment} from "../config";
import {TeamFhirSettings} from "../models";

export class FHIRWorksAPI {
  private readonly token: string;
  private readonly apiKey: string;
  private readonly tenantId: string;
  private readonly fhirRootUrl: string;

  constructor(token: string, apiKey: string, fhirSettings: TeamFhirSettings) {
    this.token = token;
    this.apiKey = apiKey;
    this.tenantId = fhirSettings.tenant_id;
    this.fhirRootUrl = fhirSettings.fhir_base_url;
  }

  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = this.fhirRootUrl;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // build path
    const url = `${urlRoot}/tenant/${this.tenantId}/${path}`;

    if (environment.fhirWorksApi.corsAnywhere?.enabled)
      return `${environment.fhirWorksApi.corsAnywhere.prefix}${url}`
    else
      return url;
  }

  private getRequestConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json'
    }

    if (this.token)
      headers['Authorization'] = `Bearer ${this.token}`
    if (this.apiKey)
      headers['X-API-Key'] = this.apiKey;

    // If cors-anywhere enabled, add the necessary headers
    if (environment.fhirWorksApi.corsAnywhere?.enabled) {
      for (const key in environment.fhirWorksApi.corsAnywhere.headers) {
        // @ts-ignore
        headers[key] = environment.fhirWorksApi.corsAnywhere.headers[key];
      }
    }

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async pingApi(): Promise<boolean> {
    return axios.get(this.getApiUrl('/any-old-url'))
      .then(() => true)
      .catch(e => {
        if (e.response) {
          // all is good, we don't care about the response, just that it exists
          return true;
        }
        console.error(e.message);
        // We're likely seeing a failure to connect
        return false;
      });
  }


  async listResourceByType<T extends FhirResource>(typeName: string): Promise<Bundle> {
    const url = this.getApiUrl(`/${typeName}`);
    const config = this.getRequestConfig();

    console.debug(`Listing ${typeName} from API...`);

    const response = await axios.get(url, config);
    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    // parse response to Bundle object
    const bundle = response.data as Bundle;
    return bundle;
  }

  async getResourceById<T extends FhirResource>(typeName: string, id: string): Promise<T | undefined> {
    const url = this.getApiUrl(`/${typeName}/${id}`);
    const config = this.getRequestConfig();

    console.debug(`Getting ${typeName} by ID from API...`);

    const response = await axios.get(url, config);
    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    const resource = response.data as T;
    return resource;
  }

  async searchByIdentifier(typeName: string, identifier: string, identifierSystem?: string): Promise<Bundle> {
    const query = identifierSystem
      ? `${identifierSystem}|${identifier}`
      : identifier;
    const url = this.getApiUrl(`/${typeName}?identifier=${encodeURIComponent(query)}`);
    const config = this.getRequestConfig();

    console.debug(`Searching ${typeName} by identifier from API...`);

    const response = await axios.get(url, config);
    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    // parse response to Bundle object
    const bundle = response.data as Bundle;
    return bundle;
  }

  async getFirstByIdentifier<T extends FhirResource>(typeName: string, identifier: string, identifierSystem?: string): Promise<T | undefined> {
    const bundle = await this.searchByIdentifier(typeName, identifier, identifierSystem);

    if (!bundle || bundle.total === 0)
      return undefined;

    const resource = bundle.entry![0].resource as T;

    return resource;
  }

  async getBinary(id: string): Promise<Binary | undefined> {
    const url = this.getApiUrl(`/Binary/${id}`);
    const config = this.getRequestConfig();

    console.debug("Getting Binary by ID from API...");

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        const binary = response.data as Binary;
        return binary;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async postBinary(payload: Binary): Promise<Binary> {
    const url = this.getApiUrl(`/Binary`);
    const config = this.getRequestConfig();

    console.debug("Posting Binary");
    const response = await axios.post(url, payload, config)

    if (response.status !== 201)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    const binary = response.data as Binary;
    return binary;
  }

  async list(typeName: string): Promise<Bundle | undefined> {
    const url = this.getApiUrl(`/${typeName}`);
    const config = this.getRequestConfig();

    console.debug(`Listing ${typeName} from API...`);

    const response = await axios.get(url, config);

    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    const r = response.data as Bundle;
    return r;
  }

  async post<T extends Resource>(payload: T, typeName: string): Promise<T> {
    const url = this.getApiUrl(`/${typeName}`);
    const config = this.getRequestConfig();

    console.debug(`Posting ${typeName}`);
    const response = await axios.post(url, payload, config)

    if (response.status !== 201)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    const r = response.data as T;
    return r;
  }

  async putBinaryCompleteUpload<Binary>(payload: Binary, id: string): Promise<Binary> {
    const url = this.getApiUrl(`Binary/multipart/complete/${id}`);
    const config = this.getRequestConfig();

    console.debug('Putting Binary Complete Upload (Multipart)');
    const response = await axios.put(url, payload, config)

    if (response.status !== 200)
      throw new Error(`Response ${response.status}: ${response.statusText}`)

    const r = response.data as Binary;
    return r;
  }

}
