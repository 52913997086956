import {Button, Modal} from "react-bootstrap";
import React from "react";


type ConfirmActionModalProps = {
  title: string;
  message: React.ReactNode | string;
  yesButtonText?: string;
  noButtonText?: string;
  closeButton?: boolean;
  onYesButtonClick?: () => void;
  onNoButtonClick?: () => void;
  show: boolean;
  onHide?: () => void;
}

export function ConfirmActionModal(props: ConfirmActionModalProps): JSX.Element {
  return <Modal show={props.show} onHide={() => props.onHide && props.onHide()}>
    <Modal.Header closeButton={props.closeButton}>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {props.message}
    </Modal.Body>

    <Modal.Footer>
      {props.onYesButtonClick && <Button
        variant="primary"
        onClick={() => props.onYesButtonClick!()}
      >{props.yesButtonText || 'Yes'}</Button>}

      {props.onNoButtonClick && <Button
        variant="secondary"
        onClick={() => props.onNoButtonClick!()}
      >{props.noButtonText || 'No'}</Button>}
    </Modal.Footer>
  </Modal>
}
