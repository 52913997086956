import React from "react";
import {Card, Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {InfoAlert, WarningAlert} from "../../../components/AlertBox";
import TurasSideNav from "../../../components/TurasSideNav";
import TurasCodePanel from "../../../components/TurasCodePanel";
import LandingBlock from "../../../components/LandingBlock";

import SandboxFlowDiagram from "../../../img/docs/empi/simplified-flow-sandbox.png";


export default function FhirGuide(): JSX.Element {

  return <BaseScreen
    pageTitle="Getting started: FHIR server"
    pageSubtitle="Learn how to integrate the NDP FHIR server into your application so you can store and recall FHIR clinical data">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Generate mock data for your sandbox
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Read resource from the FHIR server</li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Store a FHIR resource in the FHIR server</li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
          </ul>
          <p>Before starting, it might help to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="auth">authentication guide</a></li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>


      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {
                label: "Getting started", scrollToId: "step-getting-started", items: [
                  {label: "FHIR and the Sandbox", scrollToId: "fhir-and-sandbox"},
                ]
              },
              {label: "Step 1: Generate some mock patients", scrollToId: "step-1"},
              {label: "Step 2: Authentication", scrollToId: "step-2"},
              {label: "Step 3: Read a FHIR resource from the server", scrollToId: "step-3"},
              {label: "Step 4: Write a FHIR resource to the server", scrollToId: "step-4"},
            ]}/>
        </Col>

        <Col md={9} sm={12}>

          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>


            <p>The Sandbox environment provides you with a private FHIR server as one of its <a href="/data-stores">data
              storage options</a>. Learning how to read and write to the FHIR server is fundamental to making use of the
              Sandbox services.</p>

            <InfoAlert title="Terminology">
              <ul className="list-unstyled">
                <li><i className="fa fa-book"></i> <a href="https://www.hl7.org/fhir/index.html" target="_blank">Fast
                  Healthcare Interoperability Resources (FHIR)</a> is an international standard for communicating
                  clinical data between software applications.
                </li>
                <li><i className="fa fa-book"></i> <a
                  href="https://aws.amazon.com/about-aws/whats-new/2020/12/introducing-fhir-works-on-aws/"
                  target="_blank">AWS FHIR Works</a> is a FHIR server provided by Amazon Web Services
                </li>
              </ul>
            </InfoAlert>

            <h3 id="fhir-and-sandbox">FHIR and the Sandbox</h3>
            <p>In the Sandbox environment we use your sandbox's FHIR server to drive many of our mock services.
              This allows you to control the data these services will provide to your application, without worrying
              about the governance concerns that come from connecting to real data sources.</p>

            <img src={SandboxFlowDiagram} className="border p-3 bg-white mb-3"/>

            <p>By making use of the FHIR server and other Sandbox services, you can easily inject test data into your
              applications without interference from other users of that service, because all data is private to your
              sandbox.</p>

          </div>


          <div id="step-1" className="guide-step">
            <h2>Step 1: Generate some mock patients</h2>

            <Row>
              <Col md={9} sm={12}>
                <p>Open the Manage sandbox data page. You'll see several options to generate
                  test data for your application. Pick the <strong>Generate FHIR data</strong> option.</p>

                <p>On the Generate FHIR data page, create several FHIR resources, including
                  a <code>Patient</code>, <code>Practitioner</code>, and <code>Condition</code>. These resources will be
                  generated using the Sandbox's <a href="https://random-data.platform.ndp.scot/fhir/patient"
                                                   target="_blank">Random Data Generator</a> and stored in your private
                  FHIR server.</p>


              </Col>
              <LandingBlock title="Manage sandbox data"
                            url="/sandbox/data"
                            lg={3} md={3} sm={12} height100pct={false}>
                Use this link to manage or generate data in your sandbox
              </LandingBlock>
            </Row>


            <h3>See it in the browser</h3>


            <Row>
              <Col md={9} sm={12}>
                <p>Open the FHIR server interactive demo. Use the demo to query you FHIR server
                  for <code>Patient</code>, <code>Practitioner</code>, and <code>Condition</code> resources, plus any
                  others that you may have created in the previous step.</p>
                <p>The interactive demo takes care of other concerns such authentication, API keys, etc, so you can
                  simply try the FHIR server.</p>
              </Col>

              <LandingBlock title="FHIR server interactive demo"
                            url="/data-stores/fhir/try"
                            lg={3} md={3} sm={12} height100pct={false}>
                Try the FHIR server from within your browser.
              </LandingBlock>
            </Row>

            <WarningAlert title="Cross-Origin Resource Sharing (CORS)">
              <p>Unfortunately the server which powers FHIR within the Sandbox does not yet support CORS. In order to
                use the in-browser demo you'll need to follow the CORS instructions at the bottom of the demo
                screen.</p>
            </WarningAlert>

          </div>

          <div id="step-2" className="guide-step">

            <h2>Step 2: Authentication</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Getting started with authentication"
                            url="auth">
                Follow this guide to learn how to use the authentication mechanisms of the Sandbox.
              </LandingBlock>
              <LandingBlock title="Authentication interactive demo"
                            url="/sandbox/auth">
                Try the authentication mechanisms from within your browser
              </LandingBlock>
              <LandingBlock title="Quick access"
                            url="/sandbox/quick-access">
                Use this link to obtain an access token right away
              </LandingBlock>
            </Row>

            <p>The first step of connecting to the FHIR server is to obtain an authentication token from the
              authentication service. In the Sandbox environment this is AWS Cognito. </p>

            <InfoAlert>
              In the Sandbox environment and in production we use AWS Cognito to authenticate services. The
              configuration will probably differ between environments. This is true of all NDP services, although we are
              working to unify our auth mechanisms across the platform.
            </InfoAlert>

            <p>The boxes above provide three methods to obtain a token. If you're in a rush, you can use
              the <strong>Quick access</strong> screen to generate a token immediately. If you want to build a full
              end-to-end system you'll need to follow the <strong>Getting started with authentication</strong> guide.
            </p>

            <h3>Get a token</h3>

            <p>Using one of the methods above, obtain an <code>ID
              token</code> from AWS Cognito. This token is only valid for <strong>60 minutes</strong>, so if you take a
              break, or want to revisit this guide you'll need to obtain a fresh one.</p>
            <p>You'll also need the <code>API key</code> for your private sandbox, available
              from the Quick access screen.</p>

          </div>

          <div id="step-3" className="guide-step">

            <h2>Step 3: Read a FHIR resource from the server</h2>

            <InfoAlert title="Postman">
              <p>Before completing this section you'll need to
                install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool.</p>
              <p>You can use any tool which allows you to prepare and send HTTP requests (e.g. cURL)</p>
            </InfoAlert>

            <p>In this section we'll configure Postman to send an authenticated request to your private FHIR server.
              We'll
              start by listing all <code>Practitioner</code> resources, but the process is the same for individual
              Practitioners, or any other FHIR resource type.</p>

            <Card>
              <div className="card-heading">
                <Card.Title>Authentication server details</Card.Title>
              </div>

              <Card.Body>
                <p>These details are specific to your sandbox. You can find them on the <a href="/sandbox/quick-access">Quick
                  access</a> page. You'll need </p>

                <ul className="list-unstyled">
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>FHIR root URL</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>FHIR tenant ID</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>API key</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> An <code>ID token</code></li>
                </ul>

              </Card.Body>
            </Card>


            <h3>Postman environment</h3>

            <ol>
              <li>Create an environment with a name that clearly identifies your sandbox.</li>
              <li>Add variables called
                <ul>
                  <li><code>FHIR_SERVER_ROOT</code> - type=default</li>
                  <li><code>FHIR_TENANT_ID</code> - type=default</li>
                  <li><code>API_KEY</code> - type=<strong>secret</strong></li>
                  <li><code>COGNITO_ID_TOKEN</code> - type=<strong>secret</strong></li>
                </ul>
              </li>
              <li>Copy the values from Access Details (below) to these variables
                <ul>
                  <li>Root URL to <code>FHIR_SERVER_ROOT</code></li>
                  <li>Tenant ID to <code>FHIR_TENANT_ID</code></li>
                  <li>API Key to <code>API_KEY</code></li>
                  <li>ID Token to <code>COGNITO_ID_TOKEN</code></li>
                </ul>
              </li>
            </ol>

            <p><strong>NB:</strong> your Token is only valid for <strong>1 hour</strong>, so you'll need to refresh it
              occasionally. All the other values are static.</p>

            <h3>Postman collection</h3>

            <ol>
              <li>Create a collection called <code>NDP FHIR Server (Sandbox)</code></li>
              <li>Create a request called <code>FHIR - List Practitioners</code></li>
              <li>Choose request type <code>GET</code> and URL {' '}
                <code>{`{{FHIR_SERVER_ROOT}}`}/tenant/{`{{FHIR_TENANT_ID}}`}/Practitioner</code></li>
              <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
                be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>That's it! Hit <code>Send</code>. You should see a FHIR Bundle of Practitioner objects (or an empty
                bundle if there's no data in your sandbox)
              </li>
            </ol>

          </div>

          <div id="step-4" className="guide-step">

            <h2>Step 4: Write a FHIR resource to the server</h2>

            <p>In this section we'll configure Postman to create a <code>Practitioner</code> resource in your private
              server.</p>

            <h3>Postman collection</h3>

            <ol>
              <li>Open the Postman collection you created in Step 3 (e.g. NDP FHIR Server (Sandbox))</li>
              <li>Create a request called <code>FHIR - CREATE Practitioner</code></li>
              <li>Choose request type <code>POST</code> and URL {' '}
                <code>{`{{FHIR_SERVER_ROOT}}`}/tenant/{`{{FHIR_TENANT_ID}}`}/Practitioner</code></li>
              <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
                be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>In the request body, paste the code from below</li>
              <li>That's it! Hit <code>Send</code>. In the response section you should see the same FHIR resource, but
                with additional information such as <code>id</code> and <code>meta</code>
              </li>
            </ol>

            <h4>Request body</h4>

            <p>Note that this is test data - all names, IDs, etc are randomly generated</p>
            <TurasCodePanel language="json5"
                            title="FHIR Practitioner resource"
                            copyButton>
              {`
{
  "resourceType": "Practitioner",
  "name": [
    {
      "given": [
        "Jodie",
        "Roger"
      ],
      "family": "Collins"
    }
  ],
  "identifier": [
    {
      "system": "http://fhir.nhs.scot.uk/national/gmc-number",
      "value": "HR51 DRG"
    }
  ],
  "address": [
    {
      "line": [
        "6 Sutton ferry"
      ],
      "city": "North Emily",
      "country": "GB-SCT",
      "postalCode": "HA5Y 9HA"
    }
  ]
}`.trim()}
            </TurasCodePanel>

          </div>
        </Col>
      </Row>

    </TurasSection>
  </BaseScreen>;
}
