import React, {useEffect, useState} from 'react';

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";

import {DeveloperAccount, Team} from "../models";
import {getFriendlyErrorMessage, getHumanName} from "../helpers";
import AdminAPI from "../service/AdminAPI";

import BaseScreen, {ErrorMessageProps} from "./BaseScreen";
import {ErrorAlert, InfoAlert} from '../components/AlertBox';
import {TurasSection} from "../components/TurasSection";
import {TurasAccordion, TurasAccordionCard} from "../components/TurasAccordion";
import {useLocalStorage} from "usehooks-ts";
import {localStorageKeys} from "../constants";


export default function MySandboxesScreen() {
  const [selectedSandboxId, setSelectedSandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const [approvedSandboxes, setApprovedSandboxes] = useState<Team[] | undefined>(undefined);
  const [pendingSandboxes, setPendingSandboxes] = useState<Team[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [newRegisterPending, setNewRegisterPending] = useState<boolean>(false);

  function compareSandboxesByName(a: Team, b: Team): number {
    if (a.team_name && !b.team_name) return -1;
    if (!a.team_name && b.team_name) return 1;
    return (a.team_name || a.id).localeCompare(b.team_name || b.id)
  }

  async function loadSandboxes() {
    setErrorMessage(undefined);
    const adminApi = AdminAPI.fromMsal(msal);

    try {
      const [approvedTeams, pendingTeams] = await Promise.all([
        adminApi.getTeamsList('mine', 'approved'),
        adminApi.getTeamsList('mine', 'pending')
      ]);

      approvedTeams.sort(compareSandboxesByName);
      pendingTeams.sort(compareSandboxesByName);

      setApprovedSandboxes(approvedTeams);
      setPendingSandboxes(pendingTeams);
      setLoaded(true);

    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
    }
  }

  useEffect(() => {
    loadSandboxes();
  }, []);

  function RegisterSandboxForm(props: { onSubmit?: () => void }) {
    const nav = useNavigate();
    const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
    const [newTeamName, setNewTeamName] = useState<string>('');
    const [newTeamDescription, setNewTeamDescription] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);

    const msal = useMsal();

    async function handleNewTeamSubmit() {
      setErrorMessage(undefined);
      setNewRegisterPending(false);
      setProcessing(true);

      const adminApi = AdminAPI.fromMsal(msal);

      try {
        const newTeam = await adminApi.createTeam(newTeamName, newTeamDescription);

        if (!newTeam) {
          setErrorMessage({text: 'Something went wrong. The request did not return a new team.'});
          return;
        }

        // If the new team is awaiting approval, warn the user
        if (newTeam.approval_status === "pending") {
          setNewRegisterPending(true);
          // notify the caller
          if (props.onSubmit) props.onSubmit();
          return;
        }

        // if the new team is rejected, warn the user
        if (newTeam.approval_status === "rejected") {
          setErrorMessage({text: 'Something went wrong. Your request to create a new team was rejected.'});
          // notify the caller
          if (props.onSubmit) props.onSubmit();
          return;
        }

        // if the new team is approved, just go there
        nav(`/sandbox/${newTeam.id}`)

        return;
      } catch (e) {
        setErrorMessage({text: getFriendlyErrorMessage(e)});
        setProcessing(false);
      }
    }

    return (<>

      <h2>Register a new sandbox</h2>
      <InfoAlert title="Sandbox approval">
        To control and monitor usage of the Sandbox environment, especially during the beta phase, NES will manually
        review and approve requests for new sandboxes. We may contact you for additional details, including your
        intended
        use case, etc. This may take a few days to approve.
      </InfoAlert>

      {!newRegisterPending && <Form id="newTeamForm" className='turasForm'>

        <div className='form-row'>
          <div className='form-group col'>
            <p>Required fields are marked with an asterisk <span className='requiredFieldMarkerNotice'>*</span></p>
          </div>
        </div>

        <div className='form-row'>
          <Form.Group className="form-group" controlId="newTeamForm.sandboxName">
            <Form.Label className="control-label requiredFieldMarker">Sandbox Name</Form.Label>
            <span className='form-field-guidance-text'>This will be used to differentiate between sandboxes which you can access.</span>

            <Form.Control type="text" className="form-control"
                          placeholder="e.g. Your project's name or team's name"
                          value={newTeamName}
                          onChange={e => setNewTeamName(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className='form-row'>
          <Form.Group className="form-group col-lg-6" controlId="newTeamForm.description">
            <Form.Label className="control-label requiredFieldMarker">Short Description</Form.Label>
            <span
              className='form-field-guidance-text'>A quick summary of how you are planning to use this sandbox.</span>

            <Form.Control type="text" className="form-control"
                          placeholder="e.g. Used by the Care Summary team for ad hoc testing and spikes"
                          value={newTeamDescription}
                          onChange={e => setNewTeamDescription(e.target.value)}
            />
          </Form.Group>
        </div>

      </Form>}
      {errorMessage && <ErrorAlert title={"Error creating sandbox"} text={errorMessage.text}/>}
      {newRegisterPending && <InfoAlert title="Pending approval"
                                        text="Your request has been received and is being reviewed by the team. We'll let you know when your request has been approved."/>}

      <Form.Group className="form-group" controlId="newTeamForm.button">
        <Button type="submit" className="btn btn-primary"
                disabled={processing || (newTeamName.length === 0 || newTeamDescription.length === 0)}
                onClick={handleNewTeamSubmit}>Register Sandbox</Button>
      </Form.Group>


    </>);
  }

  function SandboxCard(props: { sandbox: Team, isActive?: boolean }): JSX.Element {
    const {sandbox} = props;

    function getOwnerString(owner: DeveloperAccount) {
      const name = getHumanName(owner);

      if (name && owner.email)
        return `${name} (${owner.email})`;

      if (name)
        return name

      if (owner.email)
        return owner.email;

      return 'owner';
    }

    function switchSandbox() {
      setSelectedSandboxId(props.sandbox.id);

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'select_sandbox',
        {
          'sandbox_id': props.sandbox.id,
          'reason': 'user_initiated',
        }
      )
    }

    return <Col lg={4} className="my-3">
      <Card className={`card landingBlock h-100 ${props.isActive && 'active'}`}>
        <div className={`card-heading border-bottom mb-1`}>
          <Card.Title as="h3" className={`${sandbox.approval_status === 'pending' && 'text-muted'}`}>
            {sandbox.team_name}
          </Card.Title>
        </div>
        <Card.Body>
          {sandbox.owner && <p className='mb-2'><b>Owner:</b> {getOwnerString(sandbox.owner)}</p>}
          <p>{sandbox.team_description}</p>
        </Card.Body>
        {!props.isActive && sandbox.approval_status === 'approved' && <Card.Footer>
          <Button variant="primary"
                  className="btn-block"
                  onClick={switchSandbox}
          >Switch to sandbox</Button>
        </Card.Footer>}
      </Card>
    </Col>;
  }


  return <BaseScreen
    pageTitle='My sandboxes'
    pageSubtitle={"Sandboxes are used to keep different projects' data separate. Each sandbox " +
      "receives their own access credentials and data is kept separate from other users or teams."}
    errorMessage={errorMessage}
    isLoaded={loaded}
  >
    <TurasSection>
      <h2>My sandboxes</h2>

      <Row>
        {approvedSandboxes?.map(s => <SandboxCard sandbox={s} key={s.id} isActive={s.id === selectedSandboxId}/>)}
      </Row>

      {pendingSandboxes && pendingSandboxes.length > 0 && <>
        <h2>Requested sandboxes</h2>
        <p>These sandboxes have been requested for creation, but not yet approved</p>
        <Row>
          {pendingSandboxes.map(s => <SandboxCard sandbox={s} key={s.id}/>)}
        </Row>
      </>}

      <hr className="mt-50 mb-50"/>

      <TurasAccordion>
        <TurasAccordionCard title="Register a new sandbox" dataKey="new-sandbox-collapse">
          <RegisterSandboxForm
            onSubmit={() => loadSandboxes()}/>
        </TurasAccordionCard>
      </TurasAccordion>
    </TurasSection>

  </BaseScreen>
}
