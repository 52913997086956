import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";
import LandingBlock from "../../../components/LandingBlock";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "Care Summary Proxy service";
  const pageSubtitle = "Makes ECS, KIS and PCS data available as a JSON API";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>
          <h2>A quick introduction</h2>

          <p>
            The Care Summary Proxy service provides a single point of access to patient data held on different
            underlying systems. These systems include
          </p>
          <ul>
            <li>Emergency Care Summary (ECS) data</li>
            <li>Palliative Care Summary (PCS) data</li>
            <li>Key Information Summary (KIS) data</li>
          </ul>

          <p>Data are presented in JSON format, with plans in place to include a FHIR interface.</p>

          <h2>Care Summary Proxy in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="pilot"
            productionServiceStatusDescription={`We've been piloting the service in conjunction with the Turas Care Summary user application. This is in small-scale test and we hope to expand the scale of the test in the near future.`}
            sandboxServiceMaturity="aspirational"
            sandboxServiceStatusDescription="We hope to develop a Sandbox version of the Care Summary Proxy in the near future. The timelines of this will depend on the timeline of the production trials."
            sandboxLimitations={[
              "No Sandbox version of the service is available at present.",
              "The production service is still in pilot. It may not be ready for your needs.",
            ]}
          />

        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        <LandingBlock title="OpenAPI specification"
                      url="https://care-summary.staging.platform.ndp.scot/api-docs/">
          <p>The OpenAPI specification is a standardised method of documenting APIs</p>
        </LandingBlock>

        <LandingBlock title="Interactive demo">
          <p><strong>Coming soon</strong><br/>
            An interactive demo is being developed, but not ready yet.</p>
        </LandingBlock>
      </Row>

    </TurasSection>

  </BaseScreen>;
}
