import axios, {AxiosRequestConfig} from "axios";
import {Bundle, OperationOutcome, Parameters, Patient} from "fhir/r4";

import {environment} from "../config";
import {ApiResponseError} from "../errors";

export class MockEmpiAPI {
  private readonly token: string;
  private readonly apiKey: string;

  private readonly empiApi = environment.empiApi!;

  constructor(token: string, apiKey: string) {
    this.token = token;
    this.apiKey = apiKey;

    if (!environment.empiApi)
      throw new Error("EMPI not enabled in this environment");
  }

  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = this.empiApi.url;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // build path
    const url = `${urlRoot}/${path}`;

    if (this.empiApi.corsAnywhere?.enabled)
      return `${this.empiApi.corsAnywhere.prefix}${url}`
    else
      return url;
  }

  private getRequestConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json'
    }

    if (this.token)
      headers['Authorization'] = `Bearer ${this.token}`
    if (this.apiKey)
      headers['X-API-Key'] = this.apiKey;

    // If cors-anywhere enabled, add the necessary headers
    if (this.empiApi.corsAnywhere?.enabled) {
      for (const key in this.empiApi.corsAnywhere.headers) {
        // @ts-ignore
        headers[key] = this.empiApi.corsAnywhere.headers[key];
      }
    }

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async pingApi(): Promise<boolean> {
    return axios.get(this.getApiUrl('/any-old-url'))
      .then(() => true)
      .catch(e => {
        if (e.response) {
          // all is good, we don't care about the response, just that it exists
          return true;
        }
        console.error(e.message);
        // We're likely seeing a failure to connect
        return false;
      });
  }

  async getPatientByChi(chi: string): Promise<Patient | OperationOutcome> {
    const url = this.getApiUrl(`/Patient/${chi}`);
    const config = this.getRequestConfig();

    console.debug(`Getting Patient by CHI from EMPI...`);

    const response = await axios.get(url, config);

    if (response.status === 404)
      return response.data as OperationOutcome;

    if (response.status !== 200)
      throw ApiResponseError.fromResponse(response);

    const r = response.data as Patient;
    return r;
  }

  async searchPatientByDemographics(params: Parameters): Promise<Bundle> {
    const url = this.getApiUrl(`/Patient/$match`);
    const config = this.getRequestConfig();

    console.debug(`Searching Patient by demographics from EMPI`);
    const response = await axios.post(url, params, config);

    if (response.status !== 200)
      throw ApiResponseError.fromResponse(response);

    const r = response.data as Bundle;
    return r;
  }

}
