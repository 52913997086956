import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {Binary} from "fhir/r4";
import {useMsal} from "@azure/msal-react";
import {useLocalStorage} from "usehooks-ts";

import {Team, TeamApiKey, TeamFhirSettings} from "../../../models";
import {localStorageKeys} from "../../../constants";
import {getFriendlyErrorMessage, humanFileSize, preformatUrlAsHttpRequest} from "../../../helpers";
import * as ScreenHelpers from "../../ScreenHelpers";

import {FHIRWorksAPI} from "../../../service/FHIRWorksAPI";
import AdminAPI from "../../../service/AdminAPI";

import {ErrorMessageProps} from "../../BaseScreen";
import TermsAndConditionsVerifyModal from "./TermsAndConditionsVerifyModal";
import SandboxBaseScreen, {
  SandboxFhirDisabledScreen,
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {TurasFileUploadPanel, TurasMultipartFileUploadStatusPanel} from "../../../components/TurasFileUpload";
import {ErrorAlert} from "../../../components/AlertBox";
import TurasCheckbox from "../../../components/TurasCheckbox";

import {
  doCompleteBinary,
  doPostMultipartBinary,
  doPostSinglePartBinary,
  doPutBinaryContents,
  UploadPart,
  UploadStage
} from "./TryItScreenHelpers";
import {environment} from "../../../config";
import {
  GetBinaryStatusSection,
  GetFileContentsStatusSection,
  MarkBinaryCompleteStatusSection,
  PostBinaryStatusSection,
  PutBinaryContentsStatusSection
} from './TryItScreenComponents';
import LandingBlock from "../../../components/LandingBlock";


export default function TryItScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const adminApi = AdminAPI.fromMsal(msal);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [termsReapprovalVisible, setTermsReapprovalVisible] = useState<boolean>(false);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);


  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | undefined>();
  const [uploadStatus, setUploadStatus] = useState<UploadStage>('Idle');
  const [uploadParts, setUploadParts] = useState<UploadPart[]>([{total: 0, loaded: 0}]);
  const [useMultipart, setUseMultipart] = useState<boolean>(false);

  const [postBinaryRequest, setPostBinaryRequest] = useState<string | undefined>();
  const [postBinaryResult, setPostBinaryResult] = useState<Binary | undefined>();

  const [putBinaryRequest, setPutBinaryRequest] = useState<string | undefined>();
  const [putBinaryResult, setPutBinaryResult] = useState<string | undefined>();

  const [completeBinaryRequest, setCompleteBinaryRequest] = useState<string | undefined>(undefined);
  const [completeBinaryResult, setCompleteBinaryResult] = useState<Binary | undefined>(undefined);

  const [getBinaryRequest, setGetBinaryRequest] = useState<string | undefined>();
  const [getBinaryResult, setGetBinaryResult] = useState<Binary | undefined>();

  const [currentGetUrl, setCurrentGetUrl] = useState<string | undefined>(undefined);


  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, adminApi,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      undefined,
      setApiKey,
      setErrorMessage,
      setIsLoaded);
  }

  async function getFhirApi(): Promise<FHIRWorksAPI | undefined> {
    if (!team) return;
    if (!fhirSettings) return;
    if (!apiKey) return;

    const token = await adminApi.getCognitoAccessToken(team.id);
    const fhirApi = new FHIRWorksAPI(token.id_token, apiKey.api_key_value, fhirSettings);
    return fhirApi;
  }

  async function uploadTryItFile() {
    if (!team) return;
    if (!selectedFile) return;

    setErrorMessage(undefined);
    setUploadErrorMessage(undefined);

    setPostBinaryRequest(undefined);
    setPostBinaryResult(undefined);
    setPutBinaryRequest(undefined);
    setPutBinaryResult(undefined);
    setCompleteBinaryRequest(undefined);
    setCompleteBinaryResult(undefined);
    setGetBinaryRequest(undefined);
    setGetBinaryResult(undefined);
    setCurrentGetUrl(undefined);

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);

    try {
      // PREPARING
      setUploadStatus('Preparing');
      const fhirApi = await getFhirApi();
      if (!fhirApi) return;

      let binary: Binary;
      let mergedUploadParts: UploadPart[];

      setUploadStatus('PostingBinary');
      if (useMultipart) {
        const result = await doPostMultipartBinary(fhirApi, selectedFile, uploadParts);
        binary = result.responseData;
        mergedUploadParts = result.mergedUploadParts;

        let requestUrl = fhirApi.getApiUrl('Binary/multipart');
        if (environment.mediaStore.corsAnywhere?.enabled)
          requestUrl = requestUrl.substring(environment.mediaStore.corsAnywhere.prefix.length);

        setPostBinaryRequest(`
${preformatUrlAsHttpRequest(requestUrl, 'POST')}
        
${JSON.stringify(result.requestData, undefined, 2)}
`.trim());
        setPostBinaryResult(binary);
        setUploadParts(mergedUploadParts);
      } else {
        const result = await doPostSinglePartBinary(fhirApi, selectedFile);
        binary = result.responseData;
        mergedUploadParts = result.mergedUploadParts;

        let requestUrl = fhirApi.getApiUrl('Binary');
        if (environment.mediaStore.corsAnywhere?.enabled)
          requestUrl = requestUrl.substring(environment.mediaStore.corsAnywhere.prefix.length);

        setPostBinaryRequest(`
${preformatUrlAsHttpRequest(requestUrl, 'POST')}

${JSON.stringify(result.requestData, undefined, 2)}
`.trim());
        setPostBinaryResult(binary);
        setUploadParts(mergedUploadParts);
      }


      // PUTTING BINARY CONTENTS
      setUploadStatus('PuttingBinary');
      const putBinaryResults = await doPutBinaryContents(selectedFile, mergedUploadParts, setUploadParts);

      let firstPutUrl = mergedUploadParts[0].putUrl!;
      if (environment.mediaStore.corsAnywhere?.enabled)
        firstPutUrl = firstPutUrl.substring(environment.mediaStore.corsAnywhere.prefix.length);

      setPutBinaryRequest(`
${preformatUrlAsHttpRequest(firstPutUrl, 'PUT')}

### Body
(file or part data)
`.trim())

      if (putBinaryResults.length === 1)
        setPutBinaryResult(`
// The PUT request returns an ETAG header. 
// This is required if you are using multipart upload. This upload is only single part, so you can ignore the ETAG.
${JSON.stringify({headers: {etag: putBinaryResults[0].headers.etag.replaceAll("\"", "")}}, undefined, 2)}
`);
      else
        setPutBinaryResult(`
// Each PUT request returns an individual ETAG header. 
// You'll need these ETAGs to notify S3 the multipart upload is complete.
${JSON.stringify({headers: {etags: putBinaryResults.map(result => result.headers.etag.replaceAll("\"", ""))}}, undefined, 2)}        
`);


      if (useMultipart) {
        setUploadStatus('MarkingComplete');
        const result = await doCompleteBinary(fhirApi, selectedFile, binary, putBinaryResults);

        let requestUrl = fhirApi.getApiUrl(`/Binary/multipart/complete/${binary.id}`);
        if (environment.mediaStore.corsAnywhere?.enabled)
          requestUrl = requestUrl.substring(environment.mediaStore.corsAnywhere.prefix.length);

        setCompleteBinaryRequest(`
${preformatUrlAsHttpRequest(requestUrl, 'PUT', {
          'x-api-key': '(api key)',
          'Authorization': 'Bearer (token)',
        })}

${JSON.stringify(result.requestBinary, undefined, 2)}
`)
        setCompleteBinaryResult(result.resultBinary);
      }

      // GET BINARY RESOURCE
      setUploadStatus('GettingBinary');
      const recalledBinary = await fhirApi.getBinary(binary.id!);

      let requestUrl = fhirApi.getApiUrl(`/Binary/${binary.id}`);
      if (environment.mediaStore.corsAnywhere?.enabled)
        requestUrl = requestUrl.substring(environment.mediaStore.corsAnywhere.prefix.length);

      setGetBinaryRequest(preformatUrlAsHttpRequest(requestUrl, 'GET'));
      setGetBinaryResult(recalledBinary);

      // @ts-ignore
      const getUrl = recalledBinary.presignedGetUrl as string;
      setCurrentGetUrl(getUrl);

      // DONE
      setUploadStatus('Success');
      // setSelectedFile(undefined); // NB: Do not reset the file

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'media_store_try_it',
        {
          'sandbox_id': sandboxId,
          'result': 'success',
          'mode': useMultipart ? 'mutlipart' : 'singlepart',
          'filesize': selectedFile?.size,
        }
      );

    } catch (e) {
      setUploadErrorMessage(getFriendlyErrorMessage(e));
      setUploadStatus('Failure');

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'media_store_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': useMultipart ? 'mutlipart' : 'singlepart',
          'filesize': selectedFile?.size,
          'result': 'fail',
        }
      );
    }

  }

  async function refreshGetBinary() {
    if (!team) return;

    setGetBinaryResult(undefined);
    setCurrentGetUrl(undefined);

    try {
      // PREPARING
      setUploadStatus('GettingBinary');
      const fhirApi = await getFhirApi();

      // GET BINARY RESOURCE
      const binary = postBinaryResult as Binary;
      const recalledBinary = await fhirApi!.getBinary(binary.id!);
      setGetBinaryResult(recalledBinary);

      // @ts-ignore
      const getUrl = recalledBinary.presignedGetUrl as string;
      setCurrentGetUrl(getUrl);

      // DONE
      setUploadStatus('Success');

    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
      setUploadStatus('Failure');
    }
  }

  function calculateFileUploadParts() {
    setErrorMessage(undefined);
    setUploadErrorMessage(undefined);
    setUploadStatus('Idle');

    // calculate parts
    if (!selectedFile) {
      setUploadParts([{loaded: 0, total: 0}]);
      return;
    }

    const fileSize = selectedFile.size;

    if (fileSize < environment.mediaStore.tryIt.multipartMinFileSize && useMultipart)
      setUseMultipart(false);

    if (useMultipart && fileSize >= environment.mediaStore.tryIt.multipartMinFileSize) {
      const numberOfParts = Math.min(
        Math.ceil(fileSize / environment.mediaStore.tryIt.minPartSize),
        environment.mediaStore.tryIt.maxNumParts
      );

      const partSize = Math.floor(fileSize / numberOfParts);
      // final part will probably have a few extra bytes
      // TODO: Calculate and use lastPartSize
      const lastPartSize = 0;

      const parts = Array(numberOfParts).fill({loaded: 0, total: partSize});
      setUploadParts(parts);

    } else {
      setUploadParts([
        {total: selectedFile.size, loaded: 0}
      ])
    }
  }


  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  useEffect(() => {
    calculateFileUploadParts();
  }, [selectedFile, useMultipart])


  const pageTitle = "Media Store Interactive Demo";
  const pageSubtitle = "Call the Media Store API directly from within your browser";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded || !team)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!fhirSettings)
    return <SandboxFhirDisabledScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} team={team}/>;


  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>
      <h2>Upload a file</h2>

      <Row>
        <Col md={9} sm={12}>

          <p>Select a file, then click the upload button. The page below will explain the steps undertaken in order to
            upload the file to Media Store.</p>
          <TurasFileUploadPanel
            accept={{
              'application/octet-stream': [],
              'image/*': [],
              'application/pdf': [],
              'application/json': [],
              'text/*': [],
            }}
            maxSizeBytes={environment.mediaStore.tryIt.maxFileUploadSize}
            onFileDrop={(file) => setSelectedFile(file)}
          />

          <TurasMultipartFileUploadStatusPanel
            filename={selectedFile?.name || ''}
            status={
              !selectedFile ? 'not-selected' :
                uploadStatus === 'Idle' ? 'ready' :
                  ['Preparing', 'PostingBinary', 'PuttingBinary', 'GettingBinary'].includes(uploadStatus) ? 'uploading' :
                    uploadStatus === 'Success' ? 'complete' :
                      uploadStatus === 'Failure' ? 'error' :
                        'not-ready'
            }
            // only allow remove at certain stages
            onRemoveClick={['Idle'].includes(uploadStatus)
              ? () => setSelectedFile(undefined)
              : undefined}
            // onUploadClick={() => uploadTryItFile()}
            parts={uploadParts.map(part => ({
              totalBytes: part.total,
              currentBytes: part.loaded,
              status: part.total === part.loaded ? 'complete' : undefined,
            }))}
          />


          <Form className="turasForm row">
            <Col md={6} sm={12}>
              <TurasCheckbox label="Use multipart upload?"
                             onCheckedChange={e => setUseMultipart(e)}
                             checked={useMultipart}
                             readonly={!['Idle', 'Success', 'Failure'].includes(uploadStatus)}
                             disabled={selectedFile && selectedFile.size < environment.mediaStore.tryIt.multipartMinFileSize}
              />

              <p><i className="fa fa-info-circle text-secondary"></i> To use multipart, the file must be larger
                than {humanFileSize(environment.mediaStore.tryIt.multipartMinFileSize, false, 0)}.</p>
            </Col>

            <Col md={6} sm={12}>
              <p>Multipart uploads split the file into multiple smaller parts, with each part uploaded in parallel. This
                can improve upload speed, but also permits smaller reuploads, if a single part should fail. </p>
            </Col>

            <Col md={12} sm={12}>
              <Button variant="primary"
                      onClick={() => setTermsReapprovalVisible(true)}
                      disabled={selectedFile === undefined
                        || !['Idle'].includes(uploadStatus)}
              >Start upload</Button>

              <p>Click the button above to begin upload. The results of the upload will be displayed below.</p>
            </Col>

            <Col md={12} sm={12}>
              <p><span className="text-danger font-weight-bold"><i className="fa fa-warning"></i> CORS</span><br/>
                A limitation of the beta phase means that CORS will block requests to Media Store from your browser.
                View <a href="/resources/cors">the instructions</a> to temporarily bypass CORS to use the demo.
              </p>
            </Col>


          </Form>


        </Col>

      </Row>

      {/*
      <Row>
        <Col md={9} sm={12} style={{border: '5px dashed #f00'}} className="p-3 m-2">
          <h3 className="mt-1">Hack it!</h3>
          {['Idle', 'Preparing', 'PostingBinary', 'PuttingBinary', 'MarkingComplete', 'GettingBinary', 'Success', 'Failure'].map(s =>
            <Button variant={uploadStatus === s ? "danger" : "warning"}
                    key={`hack-it-btn-${s}`}
                    onClick={() => setUploadStatus(s as UploadStage)}>{s}</Button>)}
        </Col>
      </Row>
*/}

      <hr className="mb-30 mt-30"/>

      <Row>
        <Col md={9} sm={12}>
          <h2 id="results-header">File upload steps</h2>
          <p>The following steps demonstrate the actions required in order to upload data to Media Store.</p>

          {uploadStatus === 'Idle' && <>
            <p><i className="fa fa-info-circle text-secondary"></i> Select a file and click Upload. The HTTP
              requests/responses
              will be displayed here.</p>
          </>}

          {uploadErrorMessage &&
            <ErrorAlert title="Upload error" text={uploadErrorMessage}/>}

        </Col>
      </Row>

      {uploadStatus !== 'Idle' && <>

        {/* Step 1: POST FHIR Binary resource */}
        <PostBinaryStatusSection uploadStatus={uploadStatus}
                                 postBinaryRequest={postBinaryRequest}
                                 postBinaryResult={postBinaryResult}
        />

        {/* Step 2: PUT file contents */}
        <PutBinaryContentsStatusSection uploadStatus={uploadStatus}
                                        putBinaryRequest={putBinaryRequest}
                                        putBinaryResult={putBinaryResult}
                                        selectedFile={selectedFile!}
                                        uploadParts={uploadParts}
        />


        {/* Step 3: Mark multipart upload complete */}
        <MarkBinaryCompleteStatusSection uploadStatus={uploadStatus}
                                         completeBinaryRequest={completeBinaryRequest}
                                         completeBinaryResult={completeBinaryResult}
                                         useMultipart={useMultipart}
        />
      </>}

      <hr className="mb-30 mt-30"/>

      <Row>
        <Col md={9} sm={12}>
          <h3>File download steps</h3>
          <p>Once a file has been uploaded, it can be downloaded again by the same or other applications. The
            following steps demonstrate the actions required in order to download a file from Media Store.</p>

          {uploadStatus === 'Idle' && <>
            <p><i className="fa fa-info-circle text-secondary"></i> Select a file and click Upload. The HTTP
              requests/responses will be displayed here.</p>
          </>}
        </Col>
      </Row>

      {uploadStatus !== 'Idle' && <>

        <GetBinaryStatusSection uploadStatus={uploadStatus}
                                getBinaryRequest={getBinaryRequest}
                                getBinaryResult={getBinaryResult}
        />

        <GetFileContentsStatusSection uploadStatus={uploadStatus}
                                      currentGetUrl={currentGetUrl}
                                      refreshGetBinaryClicked={refreshGetBinary}
        />

        <hr className="mb-30 mt-30"/>

        <Row>
          <Col md={9} sm={12}>
            <h3>Next steps</h3>

            <Row className="justify-content-md-center">
              <LandingBlock title="Getting started with Media Store"
                            url="/resources/getting-started/media-store">
                The getting started guide provides step-by-step instructions, in a non-interactive format.
              </LandingBlock>
              <LandingBlock title="Media Store and FHIR guide"
                            url="/resources/getting-started/media-store-and-fhir">
                This guide shows how to combine Media Store items with FHIR resources. Use this to create complex
                structured and unstructured clinical data, or to provide metadata for files in Media Store.
              </LandingBlock>
            </Row>
          </Col>

        </Row>
      </>}
    </TurasSection>

    {termsReapprovalVisible && <TermsAndConditionsVerifyModal
      onHide={() => setTermsReapprovalVisible(false)}
      onApprove={() => uploadTryItFile()}
    />}
  </SandboxBaseScreen>;
}
