import React from "react";
import {Row} from "react-bootstrap";

import BaseScreen from "../BaseScreen";
import {TurasSection} from "../../components/TurasSection";
import LandingBlock from "../../components/LandingBlock";

export default function LegalLandingScreen(): JSX.Element {
  return <BaseScreen pageTitle="Legal and site information"
                     pageSubtitle=""
  >
    <TurasSection>

      <Row>
        <LandingBlock title="Terms and conditions"
                      url="/legal/terms">
          These terms govern the use of the NDP Developer Portal and Sandbox environment
        </LandingBlock>
        <LandingBlock title="Cookie policy"
                      url="/legal/cookies">
          This site uses cookies. See our cookie policy and adjust your cookie preferences.
        </LandingBlock>
        <LandingBlock title="Accessibility"
                      url="https://www.nes.scot.nhs.uk/legal-and-site-information/accessibility/">
          NHS Education for Scotland is committed to easy access for everyone. We're continually working towards
          improving the accessibility of this website, to make sure we provide equal access to all our users.
        </LandingBlock>
        <LandingBlock title="Privacy"
                      url="https://www.nes.scot.nhs.uk/legal-and-site-information/privacy/">
          NHS Education for Scotland privacy and data protection notice
        </LandingBlock>
      </Row>

    </TurasSection>
  </BaseScreen>;
}
