import React, {useEffect, useState} from "react";

import {handleOptionalCookiesOptIn, handleOptionalCookiesOptOut, hasChosenCookieConsent} from "../../helpers";
import {environment} from "../../config";

import './Cookie.css';

export type CookieConsentModalProps = {
  siteName: string;
  cookiePolicyUrl: string;
}

export function CookieConsentModal(props: CookieConsentModalProps): JSX.Element {

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    // if GA isn't enabled, we don't have any optional cookies to consent to
    if (environment.googleAnalytics) {
      const chosen = hasChosenCookieConsent();
      setShow(!chosen);
    }
  }, []);

  return <>
    <div className="cookieOuter" id="turasCookiesModal" style={{display: show ? 'block' : 'none'}}>

      <div className="cookie">
        <div className="cookie-header">
          <h1>Your privacy on {props.siteName}</h1>
        </div>

        <div className="cookie-body">
          <p>We use cookies to enhance your experience, help us run our services and provide us with anonymised
            analytics. They are never used for marketing purposes. By clicking 'Accept All Cookies' or continuing to use
            this site you agree to <a href={props.cookiePolicyUrl}>our use of cookies</a>. You can opt out with the
            exception of necessary cookies which enable essential functionality. </p>
        </div>

        <div className="cookie-footer">
          <a href="#"
             onClick={(e) => {
               e.preventDefault();
               handleOptionalCookiesOptOut(false);
               setShow(false);
             }}
             className="btn btn-primary">Only use necessary cookies</a>
          <a href="#"
             onClick={(e) => {
               e.preventDefault();
               handleOptionalCookiesOptIn(false);
               setShow(false);
             }}
             className="btn btn-primary">Accept all cookies</a>
        </div>

      </div>
    </div>
  </>;
}
