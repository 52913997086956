import React from "react";
import {TurasSection} from "../../components/TurasSection";
import BaseScreen from "../BaseScreen";
import LandingBlock from "../../components/LandingBlock";
import {Row} from "react-bootstrap";

export default function OverviewScreen(): JSX.Element {

  const pageTitle = "APIs";
  const pageSubtitle = "These APIs allow you to interact with the functions and services of the NDP";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
  >

    <TurasSection>
      {/*<h2>Front end, UI and UX</h2>*/}

      <Row>
        <LandingBlock title="EMPI"
                      url="/apis/empi">
          <p>The Electronic Master Patient Index (EMPI) is designed to be the authoritative source for patient
            demographics.</p>
        </LandingBlock>

        <LandingBlock title="Vaccinations API"
                      url="/apis/ncds">
          <p>The National Clinical Data Store (NCDS) is the authoritative clinical record of vaccinations
            nationally.</p>
        </LandingBlock>

        <LandingBlock title="Care Summary Proxy"
                      url="/apis/care-summary">
          <p>The Care Summary Proxy provides a JSON interface to Emergency Care Summary (ECS), Key Information
            Summary (KIS), and Palliative Care Summary (PCS) data.</p>
        </LandingBlock>

      </Row>
    </TurasSection>
  </BaseScreen>
}
