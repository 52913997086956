import React, {useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";

import {PrePreparedOauthToken, Team, TeamApiKey, TeamFhirSettings} from "../models";
import AdminAPI from "../service/AdminAPI";

import ClipboardButton from "./forms/ClipboardButton";
import {useMsal} from "@azure/msal-react";
import * as ScreenHelpers from "../screens/ScreenHelpers";
import {ErrorMessageProps} from "../screens/BaseScreen";

export default function FhirAccessDetailsForm(props: { team: Team, fhirSettings: TeamFhirSettings }) {
  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();
  const [accessToken, setAccessToken] = useState<PrePreparedOauthToken | undefined>();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);

  const {team, fhirSettings} = props;

  async function loadApiKeySecret() {
    if (!team) return;
    return await ScreenHelpers.loadApiKey(team.id, api, setApiKey, setErrorMessage);
  }

  async function loadAccessToken() {
    if (!team) return;
    return await ScreenHelpers.loadAccessToken(team.id, api, setAccessToken, setErrorMessage);
  }

  return <>
    {errorMessage && <div className="alert alert-danger">
      <>{errorMessage}</>
    </div>}

    <Form className="turasForm">
      <Form.Group className="form-group mb-3" controlId="fhirAccessDetails.rootUrl">
        <Form.Label>Root URL</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control type="text"
                        value={fhirSettings?.fhir_base_url}
                        disabled={!accessToken}
                        readOnly={true}/>
          <ClipboardButton value={fhirSettings?.fhir_base_url || ''}/>
        </InputGroup>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="fhirAccessDetails.tenantId">
        <Form.Label>Tenant ID</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control type="text"
                        value={fhirSettings?.tenant_id}
                        disabled={!accessToken}
                        readOnly={true}/>
          <ClipboardButton value={fhirSettings?.tenant_id}/>
        </InputGroup>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="fhirAccessDetails.fullUrl">
        <Form.Label>Full URL</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control type="text"
                        value={`${fhirSettings?.fhir_base_url}/tenant/${fhirSettings?.tenant_id}/`}
                        disabled={!accessToken}
                        placeholder="********"
                        readOnly={true}/>
          <ClipboardButton value={`${fhirSettings?.fhir_base_url}/tenant/${fhirSettings?.tenant_id}`}/>
        </InputGroup>
      </Form.Group>


      <Form.Group className="form-group mb-3" controlId="fhirAccessDetails.apiKey">
        <Form.Label>API Key</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control type="text"
                        value={apiKey?.api_key_value}
                        placeholder="********"
                        readOnly={true}/>
          {!apiKey && <Button className="btn btn-outline" style={{margin: 0, color:"#000"}}
                              onClick={loadApiKeySecret}><i className="fa fa-eye" style={{color: '#000'}}></i></Button>}
          {apiKey && <ClipboardButton value={apiKey?.api_key_value || ''}
                           disabled={!apiKey}/>}
        </InputGroup>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="fhirAccessDetails.accessToken">
        <Form.Label>ID Token</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control type="text"
                        value={accessToken?.id_token}
                        placeholder="********"
                        readOnly={true}/>
          {!accessToken && <Button className="btn btn-outline" style={{margin: 0, color:"#000"}}
                  onClick={loadAccessToken}><i className="fa fa-eye" style={{color: '#000'}}></i></Button>}
          {accessToken &&<Button className="btn btn-outline" style={{margin: 0, color:"#000"}}
                  onClick={loadAccessToken}><i className="fa fa-rotate-right" style={{color: '#000'}}></i></Button>}
          {accessToken && <ClipboardButton value={accessToken ? accessToken.id_token : ''}
                           disabled={!accessToken}/>}
        </InputGroup>
        <Form.Text className="text-muted">
          This token will be retrieved from the OAuth server by the Admin API. It is only valid for <strong>60
          minutes</strong>
        </Form.Text>
      </Form.Group>

    </Form>
  </>;
}
