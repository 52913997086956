import React, {useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {useLocalStorage} from "usehooks-ts";

import {Team, TeamFhirSettings} from "../../../models";
import {localStorageKeys} from "../../../constants";
import AdminAPI from "../../../service/AdminAPI";

import * as ScreenHelpers from "../../ScreenHelpers"
import {ErrorMessageProps} from "../../BaseScreen";
import FhirAccessDetailsForm from "../../../components/FhirAccessDetailsForm";
import SandboxBaseScreen, {
  SandboxFhirDisabledScreen,
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";


function PostmanInstructionsScreen() {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);


  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [team, setTeam] = useState<Team | undefined>();
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Postman instructions";
  const pageSubtitle = "This page will help you get started using Postman to interact with your sandbox";


  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (!fhirSettings)
    return <SandboxFhirDisabledScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} team={team}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >
    <TurasSection>
      <h2>Step 1 - Environment</h2>

      <ol>
        <li>Create an environment with a name that clearly identifies your sandbox.</li>
        <li>Add variables called
          <ul>
            <li><code>FHIR_SERVER_ROOT</code> - type=default</li>
            <li><code>FHIR_TENANT_ID</code> - type=default</li>
            <li><code>API_KEY</code> - type=<strong>secret</strong></li>
            <li><code>COGNITO_ID_TOKEN</code> - type=<strong>secret</strong></li>
          </ul>
        </li>
        <li>Copy the values from Access Details (below) to these variables
          <ul>
            <li>Root URL to <code>FHIR_SERVER_ROOT</code></li>
            <li>Tenant ID to <code>FHIR_TENANT_ID</code></li>
            <li>API Key to <code>API_KEY</code></li>
            <li>ID Token to <code>COGNITO_ID_TOKEN</code></li>
          </ul>
        </li>
      </ol>

      <p><strong>NB:</strong> your Token is only valid for <strong>1 hour</strong>, so you'll need to
        refresh it occasionally. All the other values are static.</p>

      <h2>Step 2 - Collection</h2>

      <ol>
        <li>Create a collection called <code>NDP FHIR Server (Sandbox)</code></li>
        <li>Create a request called <code>FHIR - List Patients</code></li>
        <li>Choose request type <code>GET</code> and URL {' '}
          <code>{`{{FHIR_SERVER_ROOT}}`}/tenant/{`{{FHIR_TENANT_ID}}`}/Patient</code></li>
        <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
          be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
        <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
          <code>{`{{API_KEY}}`}</code></li>
        <li>That's it! Hit <code>Send</code>. You should see a FHIR Bundle of Patient objects (or an empty
          bundle if there's no data in your sandbox)
        </li>
      </ol>

      <h2>Access details</h2>
      <FhirAccessDetailsForm team={team}
                             fhirSettings={fhirSettings}/>
    </TurasSection>

  </SandboxBaseScreen>;
}

export default PostmanInstructionsScreen;
