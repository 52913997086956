import React from "react";
import {Col, Row} from "react-bootstrap";

import {localStorageKeys} from "../../constants";

import BaseScreen from "../BaseScreen";
import {TurasSection} from "../../components/TurasSection";
import TurasTable from "../../components/TurasTable";
import {GoogleAnalyticsOptOut} from "../../components/OptOuts";

import {LegalSideNav} from "./LegalScreenComponents";

export default function CookiePolicyScreen(): JSX.Element {
  return <BaseScreen pageTitle="Cookies Policy"
                     pageSubtitle=""
  >
    <TurasSection>

      <Row>
        <Col md={3} sm={12}>
          <LegalSideNav/>
        </Col>

        <Col md={9} sm={12}>

          <h2>Use of Cookies on this website</h2>

          <p>A cookie is a small data file that certain websites write to your hard drive when you visit them. This NES
            site uses various types of cookie. These cookies are used to make our websites run more efficiently. They
            also allow our web server to remember and store your preferences as you travel around our pages.</p>

          <p>Strictly necessary cookies enable core functionality, allowing you to move around the website and use
            its features. The website cannot function properly without these cookies. They can only be disabled by
            changing your browser preferences.</p>

          <p>The tables below describes the use of cookies on the National Digital Platform Developer Portal website.</p>

          <h3>Access and login to the Developer Portal (provided by Microsoft Azure B2c)</h3>

          <p>The following cookies are essential to allowing you to log in and ensuring your account is secured against
            unwanted access. These cannot be opted out.</p>

          <TurasTable headers={['Cookie name', 'Purpose', 'Expiry', 'Category']}
                      rows={[
                        {cells: ['x-ms-cpim-csrf', 'Cross-Site Request Forgery token used for CRSF protection', 'Session', 'Necessary']},
                        {cells: ['x-ms-cpim-sso:<directory-id>', 'Used for maintaining the SSO session. This cookie is set as persistent, when Keep Me Signed In is enabled', 'Session', 'Necessary']},
                        {cells: ['x-ms-cpim-trans', 'Used for tracking the transactions (number of authentication requests to Azure AD B2C) and the current transaction', 'Session', 'Necessary']},
                        {cells: ['x-ms-cpim-cache', 'Used for maintaining the request state', 'Session', 'Necessary']},
                        {cells: ['x-ms-cpim-rc', 'Used for storing the relay cookie', 'Session', 'Necessary']},

                        {cells: ['<directory-id>-ndpb2cdirectory.b2clogin.com-', 'Used to keep track of the current user\'s access tokens', 'Persistent', 'Necessary']},
                      ]}/>

          <p>For more info about these 3rd party cookies see <a
            href="https://learn.microsoft.com/en-us/azure/active-directory-b2c/cookie-definitions"
            target="_blank">Azure B2C</a>.</p>

          <h3>Current user preferences (internal to Developer Portal)</h3>

          <p>The following cookies are essential to the functioning of the Developer Portal. These cannot be opted
            out.</p>

          <TurasTable headers={['Cookie name', 'Purpose', 'Expiry', 'Category']}
                      rows={[
                        {cells: [localStorageKeys.currentUser, 'Used to display the current logged in user, and display options depending on their access level', 'Persistent', 'Necessary']},
                        {cells: [localStorageKeys.sandboxId, 'Used to keep track of the currently selected sandbox', 'Persistent', 'Necessary']},
                        {cells: [localStorageKeys.selectedCallbackUrl, 'Used to keep track of the currently selected OAuth 2.0 callback URL', 'Persistent', 'Necessary']},
                      ]}/>

          <h3>Tracking and analytics (provided by Google Analytics)</h3>

          <GoogleAnalyticsOptOut/>

          <TurasTable headers={['Cookie name', 'Purpose', 'Expiry', 'Category']}
                      rows={[
                        {cells: ['_ga', 'Google Analytics, used to store and count pageviews', '2 years', 'Statistics']},
                        {cells: ['_ga_<container-id>', 'Google Analytics, used to persist session state', '2 years', 'Statistics']},
                        {cells: ['ga-disable-<property-id>', 'Used to indicate user has opted out of Google Analytics', '180 days', 'essential']},
                      ]}/>

          <p>For more info about these 3rd party cookies see <a
            href="https://support.google.com/analytics/answer/11397207?hl=en"
            target="_blank">Google Analytics</a>.</p>

          <h2>Collection and use of technical information</h2>

          <p>Technical details in connection with visits to this website are sometimes logged and collected in the NDP
            Hosting platforms (Microsoft Azure and Amazon Web Services).</p>

          <p>Log files of all requests for files, or to configure or use your sandbox may be maintained and analysed.
            Aggregated analyses of these log files are used to monitor website usage. These analyses are used to allow
            us to monitor and evaluate the effectiveness of our websites. All log file information collected by NES is
            kept secure and is not provided to any third parties</p>

          <p>If you are unsure about who to contact about your information rights, please get in touch with the NES Data
            Protection Officer in the first instance.</p>

          <p>Email: <a href="mailto:foidp@nes.scot.nhs.uk">foidp@nes.scot.nhs.uk</a></p>

          <p>You can also find further high level information on your rights in the <a
            href="https://www.nes.scot.nhs.uk/legal-and-site-information/privacy/" target="_blank">NES main privacy
            notice</a>.</p>

        </Col>
      </Row>

    </TurasSection>
  </BaseScreen>;
}
