import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Row} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";
import {useMsal} from "@azure/msal-react";

import {PrePreparedOauthToken, Team, TeamCognitoSettings, TeamFhirSettings} from "../../../models";
import * as Auth from "../../../service/Auth";
import AdminAPI from "../../../service/AdminAPI";
import * as ScreenHelpers from "../../ScreenHelpers"
import {localStorageKeys} from "../../../constants";

import ClipboardButton from "../../../components/forms/ClipboardButton";
import {TurasAccordion, TurasAccordionCard} from "../../../components/TurasAccordion";
import {InfoAlert} from "../../../components/AlertBox";
import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import TurasCodePanel from "../../../components/TurasCodePanel";
import {CallbackUrlActionList, CallbackUrlsList} from "./Components";


export default function SandboxAuthOverviewScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [cognitoSettings, setCognitoSettings] = useState<TeamCognitoSettings | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState<PrePreparedOauthToken | undefined>();
  const [selectedCallbackUrl] = useLocalStorage<string>(localStorageKeys.selectedCallbackUrl, '');

  async function loadAccessToken() {
    if (!team) return;
    return await ScreenHelpers.loadAccessToken(team.id, api, setAccessToken, setErrorMessage);
  }

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      setCognitoSettings,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  useEffect(() => {
    getAndDisplaySandbox()
  }, [sandboxId]);

  function AuthCodeTabContents(props: { team: Team }): JSX.Element {
    const {team} = props;

    function getTryItUri(): string {
      if (!cognitoSettings || !cognitoSettings.settings) return '';

      // trim trailing slashes off url root
      let urlRoot = cognitoSettings.settings.auth_base_url;
      while (urlRoot.endsWith('/'))
        urlRoot = urlRoot.substring(urlRoot.length - 1);

      const url = new URL(urlRoot + '/login');

      url.searchParams.append('client_id', cognitoSettings.settings.web_client_id);
      url.searchParams.append('redirect_uri', selectedCallbackUrl);
      url.searchParams.append('response_type', `code`);
      url.searchParams.append('state', `${team.id}`);

      return url.href;
    }

    return <Form className="turasForm">
      <InfoAlert title="When to use this flow" text="Use this flow for situations where you cannot use a client secret, for example in a client-side web application,
        or mobile application where the executable is shipped to the user's device and so secrets cannot be included."/>

      {cognitoSettings?.users?.map(cog => <div className="form-row" key={cog.username}>
        <Form.Group className="form-group col-sm-6">
          <Form.Label>Username</Form.Label>
          <InputGroup className="">
            <Form.Control as="input"
                          value={cog.username}
                          readOnly={true}/>
            <ClipboardButton value={cog.username}/>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-group col-sm-6">
          <Form.Label>Password</Form.Label>
          <InputGroup className="">
            <Form.Control as="input"
                          value={cog.password}
                          readOnly={true}/>
            <ClipboardButton value={cog.password || ''}/>
          </InputGroup>
        </Form.Group>
      </div>)}

      {cognitoSettings?.users && <p className="form-text mb-3">
        The above username/password represent a single <strong>user</strong> within your system. These credentials
        belong to a person, not to a system.
      </p>}

      <hr/>

      <Form.Group className="form-group">
        <Form.Label>Login URL</Form.Label>
        <InputGroup className="">
          <Form.Control as="input"
                        value={`${cognitoSettings?.settings?.auth_base_url}/login`}
                        readOnly={true}/>
          <ClipboardButton value={`${cognitoSettings?.settings?.auth_base_url}/login`}/>
        </InputGroup>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>Token URL</Form.Label>
        <InputGroup className="">
          <Form.Control as="input"
                        value={`${cognitoSettings?.settings?.auth_base_url}/oauth2/token`}
                        readOnly={true}/>
          <ClipboardButton value={`${cognitoSettings?.settings?.auth_base_url}/oauth2/token`}/>
        </InputGroup>
      </Form.Group>

      <div className="row mb-3">
        <Form.Group className="form-group col-md-6">
          <Form.Label>OAuth Client ID (Web)</Form.Label>
          <InputGroup>
            <Form.Control as="input"
                          value={cognitoSettings?.settings?.web_client_id}
                          readOnly={true}/>
            <ClipboardButton value={cognitoSettings?.settings?.web_client_id || ''}/>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="row mb-3">
        <Form.Group className="form-group col-md-12">
          <Form.Label>Callback URLs</Form.Label>
          <p>Callback URLs are a security feature of OAuth 2.0. They specify where the auth server should send the
            authorization code after the user has logged in.
          </p>
          <p>If required, you can <a href="auth/callback-urls">configure your callback URLs</a> to match your
            application's needs.</p>


          {cognitoSettings && <>
            <CallbackUrlsList cognitoSettings={cognitoSettings}/>

            <CallbackUrlActionList/>
          </>}

        </Form.Group>
      </div>

      {cognitoSettings && <div className="row mt-3">
        <div className="col-sm-12">
          <TurasAccordion>
            <TurasAccordionCard title="Try it!" dataKey="try-it-auth-code">

              <h3>Login URL</h3>
              <TurasCodePanel language="text" copyButton>
                {getTryItUri().replaceAll('?', '\n  ?').replaceAll('&', '\n  &').trim()}
              </TurasCodePanel>

              <p>Click the Try It button to open a new tab with the login URL.</p>
              <p>You'll need to copy the <code>username</code> and <code>password</code> from above into the page that
                appears.</p>
              <p>Once you click "Login" you'll be redirected back to this website, and see the results of the
                login.</p>

              {selectedCallbackUrl !== '' && <a
                href={getTryItUri()}
                target="_blank"
                className="btn btn-primary"
                onClick={() => {
                  // @ts-ignore
                  if (window.gtag) window.gtag('event', 'auth_try_it', {
                    'sandbox_id': sandboxId,
                    'mode': 'authorization_code',
                    'result': 'init',
                  });
                }}
              >Try it!</a>}
              {selectedCallbackUrl === '' && <>
                <Button className="btn btn-primary"
                        disabled>Try it! </Button>
                <div className="text-warning"><i className="fa fa-warning"></i> Select a callback URL above</div>
              </>}
            </TurasAccordionCard>
          </TurasAccordion>
        </div>
      </div>}
    </Form>;
  }

  function ClientCredentialsTabContents(props: { team: Team }): JSX.Element {
    const {team} = props;

    const [tryItResult, setTryItResult] = useState<any>();

    async function doTryIt() {
      setTryItResult(undefined);
      if (!cognitoSettings || !cognitoSettings.settings) return;

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'auth_try_it', {
        'sandbox_id': sandboxId,
        'mode': 'client_credentials',
      });

      const result = await Auth.doLogin_ClientCredentials(cognitoSettings);

      if (result.status === 200)
        setTryItResult(result.data);
    }

    return <Form className="turasForm">
      <InfoAlert title="When to use this flow" text="Use this flow for situations where you can use a client secret, for example in a server-side web application or
        API, where the user will never have access to the source code or runtime environment to extract secrets."/>

      <Form.Group className="form-group">
        <Form.Label>Token URL</Form.Label>
        <InputGroup className="">
          <Form.Control as="input"
                        value={`${cognitoSettings?.settings?.auth_base_url}/oauth2/token`}
                        readOnly={true}/>
          <ClipboardButton value={`${cognitoSettings?.settings?.auth_base_url}/oauth2/token`}/>
        </InputGroup>
      </Form.Group>

      <Row>
        <Form.Group className="form-group col-sm-6">
          <Form.Label>OAuth client ID</Form.Label>
          <InputGroup className="">
            <Form.Control as="input"
                          value={cognitoSettings?.settings?.b2b_client_id}
                          readOnly={true}/>
            <ClipboardButton value={cognitoSettings?.settings?.b2b_client_id || ''}/>
          </InputGroup>
        </Form.Group>

        <Form.Group className="form-group col-sm-6">
          <Form.Label>OAuth client secret</Form.Label>
          <InputGroup className="">
            <Form.Control as="input"
                          value={cognitoSettings?.settings?.b2b_client_secret}
                          readOnly={true}/>
            <ClipboardButton value={cognitoSettings?.settings?.b2b_client_secret || ''}/>
          </InputGroup>
        </Form.Group>

      </Row>

      <p className="form-text mb-3">The above credentials represent another service within your system (e.g. a
        server). They shouldn't be used by users (i.e. humans) for access.<br/>
        <span className="text-danger">Note this is extremely sensitive data. Keep it safe in prod!</span></p>

      <hr/>

      {cognitoSettings && <div className="row">
        <div className="col-sm-12">
          <TurasAccordion>
            <TurasAccordionCard title="Try it!" dataKey="try-it-client-creds">
              <p>Clicking the button below will send the following request</p>

              <TurasCodePanel language="json5">
                {`
POST /oauth2/token HTTP/1.1
Host: ${cognitoSettings?.settings?.auth_base_url.replace('http://', '').replace('https://', '')}
Content-Type: application/x-www-form-urlencoded

grant_type=client_credentials
  &client_id=${cognitoSettings?.settings?.b2b_client_id}
  &client_secret=${cognitoSettings?.settings?.b2b_client_secret}
  &scope=fhir/*:read
`.trim()}
              </TurasCodePanel>

              <Button variant="warning"
                      onClick={() => doTryIt()}
                      className="btn btn-primary" style={{color: '#fff'}}
              >Try it!</Button>


              {tryItResult && <>
                <h4>Result</h4>
                <TurasCodePanel language="json5">
                  {JSON.stringify(tryItResult, null, 2)}
                </TurasCodePanel>
              </>}
            </TurasAccordionCard>
          </TurasAccordion>
        </div>
      </div>}
    </Form>;
  }

  function ShortcutsTabContents(props: { team: Team }): JSX.Element {
    const {team} = props;
    return <Form className="turasForm">
      <h3>Shortcuts</h3>

      <Form.Group className="form-group" controlId="fhirAccessDetails.accessToken">
        <Form.Label>ID Token</Form.Label>
        <InputGroup className="">
          <Form.Control type="text"
                        placeholder="********"
                        value={accessToken?.id_token}
                        readOnly={true}/>
          <Button className="btn btn-outline" style={{margin: 0, color: "#000"}}
                  onClick={loadAccessToken}><i className="fa fa-rotate-right" style={{color: '#000'}}></i></Button>
          <ClipboardButton value={accessToken ? accessToken.id_token : ''}
                           disabled={!accessToken}/>
        </InputGroup>
        <Form.Text className="text-muted">
          This token will be retrieved from the OAuth server by the Admin API. It is only valid for <strong>60
          minutes</strong>
        </Form.Text>
      </Form.Group>
    </Form>;
  }

  const pageTitle = "Authentication and credentials";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;

  // If team is found
  return <SandboxBaseScreen
    pageTitle={pageTitle}
    errorMessage={errorMessage}
    warningMessage={!fhirSettings ? "No FHIR server is associated with this team" : undefined}
    team={team}
    isLoaded={true}
  >

    <TurasSection>
      <InfoAlert title="X-API Key">
        <p>Most API requests (e.g. to the FHIR server) will also require
          the <code>X-API-Key</code> header
          (available from the <a href={`/sandbox/quick-access`}>Quick access</a> screen). However calls to the auth
          server do NOT require this header.</p>
      </InfoAlert>

      <h2>Cognito Access Details</h2>

      <ul className="nav nav-tabs nav-justified" role="tablist">
        <li className="nav-item" role="presentation">
          <a className="nav-link active"
             data-toggle="tab" href="#tab-1-panel"
             id="tab-1-tab"
             role="tab" aria-selected="true">
            Authorization Code</a>
        </li>

        <li className="nav-item" role="presentation">
          <a className="nav-link"
             data-toggle="tab" href="#tab-2-panel"
             id="tab-2-tab"
             role="tab" aria-selected="false">
            Client Credentials</a>
        </li>

        <li className="nav-item" role="presentation">
          <a className="nav-link"
             data-toggle="tab" href="#tab-3-panel"
             id="tab-3-tab"
             role="tab" aria-selected="false">
            Shortcuts</a>
        </li>

      </ul>

      <div className="tab-content">
        <div className="tab-pane active"
             id="tab-1-panel"
             aria-labelledby="tab-1-tab"
             role="tabpanel">
          <AuthCodeTabContents team={team}/>
        </div>
        <div className="tab-pane fade"
             id="tab-2-panel"
             aria-labelledby="tab-2-tab"
             role="tabpanel">
          <ClientCredentialsTabContents team={team}/>
        </div>
        <div className="tab-pane fade"
             id="tab-3-panel"
             aria-labelledby="tab-3-tab"
             role="tabpanel">
          <ShortcutsTabContents team={team}/>
        </div>
      </div>

    </TurasSection>
  </SandboxBaseScreen>;
}
