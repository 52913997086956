import React, {useEffect, useState} from "react";
import {useLocalStorage} from "usehooks-ts";
import {useMsal} from "@azure/msal-react";

import {Team, TeamCognitoSettings, TeamFhirSettings} from "../../../models";
import AdminAPI from "../../../service/AdminAPI";

import * as ScreenHelpers from "../../ScreenHelpers"
import {localStorageKeys} from "../../../constants";
import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {CallbackUrlActionList, CallbackUrlsList} from "./Components";


export default function ConfigureCallbackUrlsScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [cognitoSettings, setCognitoSettings] = useState<TeamCognitoSettings | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);


  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      setCognitoSettings,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  useEffect(() => {
    getAndDisplaySandbox()
  }, [sandboxId]);

  const pageTitle = "OAuth 2.0 callback URLs";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} errorMessage={errorMessage}/>;

  // If team is found
  return <SandboxBaseScreen
    pageTitle={pageTitle}
    errorMessage={errorMessage}
    warningMessage={!fhirSettings ? "No FHIR server is associated with this team" : undefined}
    team={team}
    isLoaded={true}
  >

    <TurasSection>


      <h2>Callback URLs</h2>
      <p>Callback URLs are a security feature of OAuth 2.0. They specify where the auth server should send the
        authorization code after the user has logged in.
      </p>

      {cognitoSettings && <>
        <CallbackUrlsList cognitoSettings={cognitoSettings}
                          team={team}
                          listUpdated={() => getAndDisplaySandbox()}
                          allowAdd allowDelete/>

        <CallbackUrlActionList/>
      </>}

    </TurasSection>
  </SandboxBaseScreen>;
}
