import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";
import {WarningAlert} from "../../../components/AlertBox";
import LandingBlock from "../../../components/LandingBlock";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "openEHR clinical data repository";
  const pageSubtitle = "";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>
          <h2>A quick introduction</h2>

          <p><a href="https://www.openehr.org/" target="_blank">openEHR</a> is an open standard describing the
            management, storage, and exchange of electronic health and care data.</p>

          <p>Most services within the National Digital Platform communicate via <a href="fhir">FHIR</a>, openEHR, or a
            combination of these and other standards.</p>

          <WarningAlert title="In development">
            <p>The Sandbox does not currently provide a version of the openEHR server. This is something that we're
              evaluating as part of our beta programme.</p>
          </WarningAlert>

          <h2>openEHR in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="pilot"
            productionServiceStatusDescription={`We've been piloting an openEHR server for use with internal services. This is in use by some production-level services, but we're still assessing the technology's ability to scale to larger workloads.`}
            sandboxServiceMaturity="aspirational"
            sandboxServiceStatusDescription="We hope to bring the same production server to the Sandbox in 2023. If we continue with the proposed provider then the implementation will be similar to production. However it's still possible that the underlying provider will require to change."
            sandboxLimitations={[
              "No Sandbox version of openEHR is available at present.",
              "The production service is still in pilot. It may not be ready for your needs.",
            ]}
          />

        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        <LandingBlock title="Interactive demo">
          <p><strong>Coming soon</strong><br/>
            An interactive demo is being developed, but not ready yet.</p>
        </LandingBlock>
      </Row>

    </TurasSection>

  </BaseScreen>;
}
