import React, {useEffect, useState} from 'react';
import {Row, Spinner} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";

import {useMsal} from "@azure/msal-react";

import AdminAPI from "../../../service/AdminAPI";
import {getFriendlyErrorMessage, sortResourceTypesByName} from "../../../helpers";
import {FhirDataSummaryResponse, GenerateFhirDataRequest, Team} from "../../../models";
import * as ScreenHelpers from "../../ScreenHelpers";
import {localStorageKeys} from "../../../constants";

import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import LandingBlock from "../../../components/LandingBlock";


export default function DataOverviewScreen() {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [fhirData, setFhirData] = useState<FhirDataSummaryResponse | undefined>();
  const [generatingFhirData, setGeneratingFhirData] = useState<boolean>(false);
  const [loadingFhirData, setLoadingFhirData] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  async function loadFhirData() {
    setErrorMessage(undefined);
    setLoadingFhirData(true);

    console.debug(`Loading FHIR data for team ${sandboxId}`);
    if (!sandboxId) return;

    try {
      const fd = await api.getFhirDataSummary(sandboxId);
      setFhirData(fd);

      // We call this here to ensure a smooth UI experience
    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
    }
    setLoadingFhirData(false);
    setIsLoaded(true);
  }

  async function handleGenerateData(config: { fhirType: string, count: number }[]) {
    setErrorMessage(undefined);
    setGeneratingFhirData(true);
    console.debug(`Generating FHIR data`);

    if (!team) return;

    const counts: GenerateFhirDataRequest = {
      resource_types: config.map(c => ({resource_name: c.fhirType, count: c.count}))
    };

    try {
      const fd = await api.populateRandomFhirData(team.id, counts);

      // Give our poor FHIR server time to catch up
      setLoadingFhirData(true);
      setTimeout(() => loadFhirData(), 2000);
    } catch (e) {
      setErrorMessage({text: getFriendlyErrorMessage(e)});
    }
    setGeneratingFhirData(false);
  }


  useEffect(() => {
    getAndDisplaySandbox()
      .then(() => loadFhirData());
  }, [sandboxId]);

  const pageTitle = "Manage sandbox data";
  const pageSubtitle = "This is a summary of the data within your sandbox. You can generate new data or clean up your sandbox.";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >
    <TurasSection>

      <h2>FHIR Server</h2>
      <p>Below is a summary of the number of each individual FHIR resource within your sandbox's private FHIR
        server.</p>
      <p>Use the <a href="/data-stores/fhir/try">FHIR interactive demo</a> to view the contents of individual resources.
      </p>

      {loadingFhirData && <Spinner animation="border" variant="primary">
        <span className="invisible">Loading...</span>
      </Spinner>}

      {!loadingFhirData && fhirData && <table className="table">
        <thead>
        <tr>
          <th>Resource</th>
          <th>Count</th>
        </tr>
        </thead>

        <tbody>
        {fhirData.resource_types
          .sort(sortResourceTypesByName)
          .map(i =>
            <tr>
              <td>{i.resource_name}</td>
              <td>{generatingFhirData ? '...' : i.count}</td>
            </tr>)}
        </tbody>
      </table>}


      <h2>Data tools</h2>
      <Row>
        <LandingBlock title="Generate datasets"
                      url="data/datasets">
          <p>Generate data to simulate a clinical picture (e.g. a related Patient, Immunization, Drug, Condition,
            etc)</p>
        </LandingBlock>

        <LandingBlock title="Generate FHIR data"
                      url="data/fhir">
          <p>Generate individual FHIR data items for testing</p>
        </LandingBlock>

        <LandingBlock title="Random Data API"
                      url="https://random-data.platform.ndp.scot/fhir/patient">
          <p>Try the NES Random Data Generator tool which is used to generate our random data</p>
        </LandingBlock>

        <LandingBlock title="Clear data"
                      url="data/clear">
          <p>Reset your sandbox, or clear individual data stores</p>
        </LandingBlock>

      </Row>


    </TurasSection>
  </SandboxBaseScreen>;
}
