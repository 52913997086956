import axios, {AxiosProgressEvent, AxiosRequestConfig, AxiosResponse} from "axios";
import {Binary} from "fhir/r4";

import {FHIRWorksAPI} from "../../../service/FHIRWorksAPI";
import {environment} from "../../../config";


export type UploadStage =
  'Idle'
  | 'Preparing'
  | 'PostingBinary'
  | 'PuttingBinary'
  | 'MarkingComplete'
  | 'GettingBinary'
  | 'Success'
  | 'Failure';

export type UploadPart = {
  loaded: number,
  total: number,
  putUrl?: string,
}

export async function doPostMultipartBinary(fhirApi: FHIRWorksAPI, selectedFile: File, uploadParts: UploadPart[]):
  Promise<{ requestData: Binary, responseData: Binary, mergedUploadParts: UploadPart[] }> {

  // POSTING BINARY RESOURCE (MULTIPART)
  console.debug("Posting Binary (Multipart)");

  const requestData = {
    resourceType: "Binary",
    filename: selectedFile.name,
    contentType: selectedFile.type,
    numberOfParts: uploadParts.length
  } as Binary;

  const responseData = await fhirApi.post(requestData, 'Binary/multipart');

  // parse the pre-signed put url
  // @ts-ignore
  const putUrls = responseData.presignedPutUrls as string[];

  if (putUrls.length !== uploadParts.length) {
    throw new Error("Number of PUT URLs received did not match expected number of parts. Cannot continue. " +
      "This is a problem with the Media Store API.")
  }

  // If cors-anywhere enabled, add the necessary URL prefix
  const corsConfig = environment.mediaStore.corsAnywhere;
  const useCors = corsConfig && corsConfig.enabled;

  // Merge the new PUT URLs into the state var
  const mergedUploadParts = uploadParts.map((p, n) => ({
    loaded: p.loaded,
    total: p.total,
    putUrl: useCors ? corsConfig.prefix + putUrls[n] : putUrls[n],
  }));

  return {requestData, responseData, mergedUploadParts};
}

export async function doPostSinglePartBinary(fhirApi: FHIRWorksAPI, selectedFile: File): Promise<{
  requestData: Binary, responseData: Binary, mergedUploadParts: UploadPart[]
}> {
  // POSTING BINARY RESOURCE
  console.debug("Posting Binary");

  const requestData = {
    resourceType: "Binary",
    filename: selectedFile.name,
    contentType: selectedFile.type,
  } as Binary;

  const responseData = await fhirApi.post<Binary>(requestData, 'Binary');


  // @ts-ignore
  const putUrl = responseData.presignedPutUrl as string;

  // If cors-anywhere enabled, add the necessary URL prefix
  const corsConfig = environment.mediaStore.corsAnywhere;
  const useCors = corsConfig && corsConfig.enabled;

  const mergedUploadParts = [{
    loaded: 0,
    total: selectedFile.size,
    putUrl: useCors ? corsConfig.prefix + putUrl : putUrl,
  }];

  return {requestData, responseData, mergedUploadParts};
}

export async function doPutBinaryContents(selectedFile: File,
                                          mergedUploadParts: UploadPart[],
                                          setUploadParts: (value: (((prevState: UploadPart[]) => UploadPart[]) | UploadPart[])) => void): Promise<AxiosResponse<any, any>[]> {
  const promises: Promise<AxiosResponse<any>>[] = [];
  let runningByteIndex = 0;

  // build all of our axios PUT configs
  for (let i = 0; i < mergedUploadParts.length; i++) {
    const part = mergedUploadParts[i];

    const start = runningByteIndex;
    const end = runningByteIndex + part.total;
    runningByteIndex = end;

    // TODO: Verify we got all the bytes
    const blob = selectedFile.slice(start, end);

    const config = {
      onUploadProgress: (e: AxiosProgressEvent) => {
        setUploadParts(current => {
          const currentUploadProgress = [...current];
          currentUploadProgress[i] = {...currentUploadProgress[i], loaded: e.loaded, total: e.total || 1};
          return currentUploadProgress;
        });
      }
    } as AxiosRequestConfig<File>;

    const promise = axios.put(part.putUrl!, blob, config);
    promises.push(promise);
  }

  // upload parts
  const results = await Promise.all(promises);
  return results;
}

export async function doCompleteBinary(
  fhirApi: FHIRWorksAPI, selectedFile: File, binary: Binary, putBinaryResults: AxiosResponse<any>[]
): Promise<{ requestBinary: Binary, resultBinary: Binary }> {

  // @ts-ignore - uploadId is not part of the FHIR standard, it comes from FHIR Works
  const uploadId = binary.uploadId;

  const mappedParts = putBinaryResults.map((response, n) => ({
      ETag: response.headers.etag,
      PartNumber: n + 1
    })
  );

  const requestBinary = {
    resourceType: "Binary",
    contentType: selectedFile.type,
    uploadId: uploadId!,
    uploadedParts: mappedParts
  } as Binary;

  // complete upload
  const resultBinary = await fhirApi.putBinaryCompleteUpload<Binary>(requestBinary, binary.id!);

  return {requestBinary: requestBinary, resultBinary: resultBinary};
}
