import * as config from '../config';

export default function Footer() {
  function enableAllFeatures() {
    config.developerEnableToggledFeatures();
    // reload the screen to refresh the UI
    window.location = window.location;
  }

  return (<footer>
      <div className="container">
        <div className="row">

          <div className="col-md-10 ">

            <div className="row">
              <div className="col-md-6">
                <img className="turasLogo" src="/img/NDP-logo-tagline-white.svg"
                     style={{width: "260px", marginBottom: "3vh"}}/>
                {/* TODO ADD NDP Logo */}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ul className="list-unstyled mb-0">
                  <li><a href="https://www.nes.scot.nhs.uk/legal-and-site-information/accessibility/"
                         target="_blank">Accessibility</a></li>
                  <li><a href="https://www.nes.scot.nhs.uk/legal-and-site-information/privacy/"
                         target="_blank">Privacy</a></li>
                  <li><a href="/legal/terms">Terms and Conditions</a></li>
                  <li><a href="/legal/cookies">Cookie Policy</a></li>
                  {/* <li className="helpdesk">
                            <a href="" className="btn btn-success" style={{padding: '5px 10px'}}> Helpdesk <span className="fa fa-question-circle"></span></a>
                        </li> */}
                </ul>
              </div>
            </div>

          </div>

          <div className="col-md-2">
            <img src="/img/nhs-logo-white-banner.png" alt="" className="img-fluid"/>
          </div>

        </div>
      </div>

      <div className="subfooter" style={{marginTop: "24px"}}>
        <div className="container">
          <div className="row">

            <div className="col">
              <p>©2023 NHS Education for Scotland</p>

              <p onClick={enableAllFeatures}>Version: {config.version} ({config.environmentName})</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
