import React, {useRef, useState} from "react";
import {Button, Col, Dropdown, Form, Row} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";
import {useMsal} from "@azure/msal-react";

import {getFriendlyErrorMessage, getLocalCognitoCallbackUrl} from "../../../helpers";
import {Team, TeamCognitoSettings} from "../../../models";
import AdminAPI from "../../../service/AdminAPI";

import {ClipboardLink} from "../../../components/forms/ClipboardButton";
import {localStorageKeys} from "../../../constants";
import {ConfirmActionModal} from "../../../components/modals/CommonModals";
import {ErrorAlert} from "../../../components/AlertBox";


export function CallbackUrlsList(props: {
  cognitoSettings: TeamCognitoSettings,
  team?: Team,
  listUpdated?: () => void,
  allowAdd?: boolean;
  allowDelete?: boolean;
}): JSX.Element {
  const {cognitoSettings, team, allowAdd, allowDelete} = props;

  const msal = useMsal();
  const adminAPI = AdminAPI.fromMsal(msal);

  const [urlToAdd, setUrlToAdd] = useState<string>('');
  const [addFormWasValidated, setAddFormWasValidated] = useState<boolean>(false);

  const [selectedCallbackUrl, setSelectedCallbackUrl] = useLocalStorage<string>(localStorageKeys.selectedCallbackUrl, '');
  const [urlToDelete, setUrlToDelete] = useState<string | undefined>();

  const [addDeleteError, setAddDeleteError] = useState<{ title: string, message: string } | undefined>();

  const addUrlFieldRef = useRef<HTMLInputElement>(null);

  async function handleDelete() {
    if (!team) return;
    if (!urlToDelete || urlToDelete.length === 0) return;

    setAddDeleteError(undefined);
    const url = urlToDelete!.trim();

    try {
      await adminAPI.deleteCallbackUrl(team.id, url);

      // be nice to the user - set the deleted URL in the "URL to add" field
      setUrlToAdd(url);

      // Notify parent screen
      // NB: don't call listUpdated if there was an error - it'll redraw the whole page and we'll lose the error
      props.listUpdated && props.listUpdated()
    } catch (e) {
      const msg = getFriendlyErrorMessage(e);
      setAddDeleteError({title: "Error deleting URL", message: msg});
    }

    // Clear state
    setUrlToDelete(undefined);
  }

  async function handleAdd(event: React.FormEvent<HTMLFormElement>) {
    if (!team) return;

    event.preventDefault();
    event.stopPropagation();

    // clear state
    setAddDeleteError(undefined);
    addUrlFieldRef.current?.setCustomValidity('');

    const url = urlToAdd!.trim();

    if (url.length === 0){
      setAddFormWasValidated(false);
      return;
    }

    const form = event.currentTarget;
    setAddFormWasValidated(true);

    // Failure path
    if (!form.checkValidity()) {
      return;
    }


    // Validate URL
    const localhostMatcher = /^http:\/\/localhost(:\d{2,5})?(\/.*)?$/gi
    const serverMatcher = /^https:\/\/[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+\.[a-zA-Z0-9_\-]+(:\d{2,5})?(\/.*)?$/gi

    const urlIsValid = ![localhostMatcher, serverMatcher]
      .every(matcher => !url.match(matcher));

    if (!urlIsValid) {
      addUrlFieldRef.current?.setCustomValidity('invalid');
      return;
    }

    // clear validation
    setAddFormWasValidated(false);

    try {
      await adminAPI.addCallbackUrl(team.id, url);

      // Notify parent screen
      // NB: don't call listUpdated if there was an error - it'll redraw the whole page and we'll lose the error
      props.listUpdated && props.listUpdated()

      // Clear state
      setUrlToAdd('');
    } catch (e) {
      const msg = getFriendlyErrorMessage(e);
      setAddDeleteError({title: "Error adding URL", message: msg});
    }

  }


  function CallbackUrlRow(props: { url: string }): JSX.Element {
    const {url} = props;

    return <>
      <Row className="mb-2">
        <Col sm={8}>
          {url === selectedCallbackUrl
            ? <i className="fa fa-check text-success"></i>
            : <i className="fa fa-circle-thin" onClick={() => setSelectedCallbackUrl(url)}></i>}
          <code> {url} </code>

        </Col>

        <Col sm={4}>
          <span className="list-separator"> ( </span>
          {url === selectedCallbackUrl
            ? 'Selected'
            : <a href="#" className="" onClick={(e) => {
              setSelectedCallbackUrl(url);
              e.preventDefault();
            }}
            > Select </a>}

          <span className="list-separator"> | </span>

          <ClipboardLink value={url}/>

          {allowDelete && <>
            <span className="list-separator"> | </span>
            <Dropdown as="span">
              <Dropdown.Toggle as={"a"} variant="primary" className="alert-link">
                Actions
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {url !== selectedCallbackUrl &&
                  <Dropdown.Item onClick={() => setUrlToDelete(url)}>Delete URL</Dropdown.Item>}

                {url === selectedCallbackUrl &&
                  <Dropdown.ItemText className="text-secondary">No actions</Dropdown.ItemText>}

              </Dropdown.Menu>
            </Dropdown>
          </>}

          <span className="list-separator"> ) </span>
        </Col>
      </Row>

    </>;
  }

  return <>
    {cognitoSettings.settings?.web_callback_urls.map(url =>
      <CallbackUrlRow url={url} key={url}/>)}

    {props.allowAdd && <Form className="turasForm"
                             noValidate
                             validated={addFormWasValidated}
                             onSubmit={(e) => handleAdd(e)}>

      <Form.Group className="form-group mb-3 mt-3">
        <Form.Label> Add URL </Form.Label>

        <Row>
          <Col md={8} sm={12}>
            <Form.Control required
                          minLength={3}
                          value={urlToAdd}
                          className="mb-2"
                          ref={addUrlFieldRef}
                          onChange={(e) => setUrlToAdd(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Valid URLs must match one of the following formats:<br/>

              http://localhost (:port) (/callback/path)<br/>
              https://www.server.com (:port) (/callback/path)
            </Form.Control.Feedback>
          </Col>
          <Col md={4} sm={12}>
            <Button type="submit" className="mt-0 mt-sm-2">Add callback URL</Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>}

    {addDeleteError && <ErrorAlert title={addDeleteError.title} text={addDeleteError.message}/>}

    {props.allowDelete && <ConfirmActionModal
      title="Confirm delete"
      message={<p>
        Are you sure you want to delete the callback
        URL<br/><code>{urlToDelete}</code>?
      </p>}
      show={(urlToDelete?.length || 0) > 0}
      onYesButtonClick={() => handleDelete()}
      onNoButtonClick={() => setUrlToDelete(undefined)}
      onHide={() => setUrlToDelete(undefined)}
    />}
  </>;
}

export function CallbackUrlActionList(): JSX.Element {
  const localCallbackUrl = getLocalCognitoCallbackUrl();

  const [selectedCallbackUrl] = useLocalStorage<string>(localStorageKeys.selectedCallbackUrl, '');

  return <div className="mt-3">
    <h3>Configure callbacks for Developer Portal</h3>
    <p className="mb-1">To allow the demos in this web application to function correctly</p>
    <ul className="list-unstyled mt-0">

      {selectedCallbackUrl === ''
        ? <li className="text-warning"><i className="fa fa-warning"></i> Select a callback URL from the list above</li>
        : <li className="text-success"><i className="fa fa-check"></i> Select a callback URL from the list above</li>
      }

      {selectedCallbackUrl !== localCallbackUrl
        ? <li className="text-warning"><i className="fa fa-warning"></i> To use the authentication demos, you should
          use {localCallbackUrl}</li>
        : <li className="text-success"><i className="fa fa-check"></i> To use the authentication demos, you should
          use {localCallbackUrl}</li>
      }
    </ul>
    <p>The selected callback URL is only used by Developer Portal. Your own application will use a custom callback
      URL.</p>
  </div>;
}
