import {Button, Col, ProgressBar, Row, Spinner} from "react-bootstrap";
import TurasCodePanel from "../../../components/TurasCodePanel";
import React from "react";
import {UploadPart, UploadStage} from "./TryItScreenHelpers";
import {Binary} from "fhir/r4";
import {formatFileSize, preformatUrlAsHttpRequest} from "../../../helpers";

function RequestResponseSection(props: {
  stepNumber: number;
  title: string;
  blurb: React.ReactNode | string;

  currentStatus: UploadStage;
  waitingStatuses: UploadStage[],
  activeStatuses: UploadStage[],
  completedStatuses: UploadStage[],

  requestSyntaxFormat: string;
  request?: string,

  responseSyntaxFormat: string;
  response: string | undefined | null,

  requestBlurb?: React.ReactNode | string;
  responseBlurb?: React.ReactNode | string;
}): JSX.Element {

  const activeOrCompletedStatuses = props.activeStatuses.concat(props.completedStatuses).concat(['Failure']);

  return <Row>
    <Col md={9} sm={12}>
      <hr className="mb-30 mt-30"/>

      <h3><i className="fa fa-tasks"></i> Step {props.stepNumber}: {props.title}</h3>
      {props.blurb}
    </Col>

    {props.waitingStatuses.includes(props.currentStatus) &&
      <Col md={12}><Spinner variant="secondary"/></Col>}

    {activeOrCompletedStatuses.includes(props.currentStatus) && <>
      <Col md={6} sm={12}>
        <h4>Request</h4>
        {!props.request && props.activeStatuses.includes(props.currentStatus) && <Spinner variant="primary"/>}
        {!props.request && props.completedStatuses.includes(props.currentStatus) && <>
          <Spinner variant="secondary"/>
          <p className="text-secondary">Still waiting...</p>
        </>}
        {!props.request && props.currentStatus === 'Failure' && <>
          <Spinner variant="danger"/>
          <p className="text-secondary">Error</p>
        </>}

        {props.request && <>
          <TurasCodePanel language={props.requestSyntaxFormat} copyButton>
            {props.request}
          </TurasCodePanel>
        </>}
        {props.requestBlurb}
      </Col>

      <Col md={6} sm={12}>
        <h4>Response</h4>
        {props.response === undefined && props.activeStatuses.includes(props.currentStatus) &&
          <Spinner variant="primary"/>}
        {props.response === undefined && props.completedStatuses.includes(props.currentStatus) && <>
          <Spinner variant="secondary"/>
          <p className="text-secondary">Still waiting...</p>
        </>}
        {props.response === undefined && props.currentStatus === 'Failure' && <>
          <Spinner variant="danger"/>
          <p className="text-secondary">Error</p>
        </>}

        {props.response && props.response.length > 0 && <>
          <TurasCodePanel language={props.responseSyntaxFormat} copyButton>
            {props.response}
          </TurasCodePanel>
        </>}
        {props.responseBlurb}
      </Col>
    </>}
  </Row>;
}

export function PostBinaryStatusSection(props: {
  uploadStatus: UploadStage,
  postBinaryRequest: string | undefined,
  postBinaryResult: Binary | undefined,
}): JSX.Element {
  return <RequestResponseSection
    stepNumber={1}
    title="POST FHIR Binary resource"
    blurb={<p>Send a POST request to <code>/Binary</code> or <code>/Binary/multipart</code>, to generate a FHIR
      resource. The resource is returned along with one or more pre-signed PUT URLs.</p>}

    currentStatus={props.uploadStatus}
    waitingStatuses={['Idle', 'Preparing']}
    activeStatuses={['PostingBinary']}
    completedStatuses={['PuttingBinary', 'MarkingComplete', 'GettingBinary', 'Success']}

    requestSyntaxFormat="http"
    request={props.postBinaryRequest}
    responseSyntaxFormat="json"
    response={props.postBinaryResult ? JSON.stringify(props.postBinaryResult, undefined, 2) : undefined}

    responseBlurb={<p><i className="fa fa-info-circle"></i>{' '}
      Binary.id = <code>{props.postBinaryResult?.id || "pending..."}</code><br/>
      This ID from the Binary resource should be used to reference the file with other FHIR resources</p>}
  />;
}


export function PutBinaryContentsStatusSection(props: {
  uploadStatus: UploadStage,
  putBinaryRequest: string | undefined,
  putBinaryResult: string | undefined,
  selectedFile?: File,
  uploadParts: UploadPart[],
}): JSX.Element {

  return <RequestResponseSection
    stepNumber={2}
    title="PUT file contents"
    blurb={<p>Use the pre-signed PUT URLs to upload the file contents to AWS S3.</p>}

    currentStatus={props.uploadStatus}
    waitingStatuses={['Idle', 'Preparing', 'PostingBinary']}
    activeStatuses={['PuttingBinary']}
    completedStatuses={['MarkingComplete', 'GettingBinary', 'Success']}

    requestSyntaxFormat="http"
    request={props.putBinaryRequest}

    responseSyntaxFormat="json"
    response={props.putBinaryResult}
    responseBlurb={!props.putBinaryResult && <>
      <p>Uploading {props.selectedFile?.name} (
        {formatFileSize(props.uploadParts.reduce((x, y) => x + y.loaded, 0))} of {formatFileSize(props.uploadParts.reduce((x, y) => x + y.total, 0))})
      </p>
      <ProgressBar animated
                   className="mb-2"
                   now={props.uploadParts.reduce((x, y) => x + y.loaded, 0) / props.uploadParts.reduce((x, y) => x + y.total, 0) * 100}/>
    </>}
  />;
}

export function MarkBinaryCompleteStatusSection(props: {
  uploadStatus: UploadStage,
  completeBinaryRequest: string | undefined,
  completeBinaryResult: Binary | undefined,
  useMultipart: boolean,
}): JSX.Element {

  return <RequestResponseSection
    stepNumber={3}
    title="Mark multipart upload complete"
    blurb={<p>Send a PUT request to <code>/Binary/multipart/complete</code>, to notify S3 that the multipart upload is
      complete. This is only required if <strong>multipart upload</strong> is used. Single part uploads do not
      require this step.</p>}

    currentStatus={props.uploadStatus}
    waitingStatuses={['Idle', 'Preparing', 'PostingBinary', 'PuttingBinary']}
    activeStatuses={['MarkingComplete']}
    completedStatuses={['GettingBinary', 'Success']}

    requestSyntaxFormat="http"
    request={props.useMultipart
      ? props.completeBinaryRequest
      : "Not required if useMultipart=false"}

    responseSyntaxFormat="json"
    response={props.useMultipart
      ? props.completeBinaryResult ? JSON.stringify(props.completeBinaryResult, undefined, 2) : undefined
      : "// Not required if useMultipart=false"}
  />;
}

export function GetBinaryStatusSection(props: {
  uploadStatus: UploadStage,
  getBinaryRequest: string | undefined,
  getBinaryResult: Binary | undefined,
}): JSX.Element {

  return <RequestResponseSection
    stepNumber={4}
    title="GET FHIR Binary resource"
    blurb={<p>Send a GET request to <code>/Binary/{`{id}`}</code>, to load the FHIR Binary resource which represents
      this file. The resource is returned along with a pre-signed GET URL.</p>}

    currentStatus={props.uploadStatus}
    waitingStatuses={['Idle', 'Preparing', 'PostingBinary', 'PuttingBinary', 'MarkingComplete']}
    activeStatuses={['GettingBinary']}
    completedStatuses={['Success']}

    requestSyntaxFormat="http"
    request={props.getBinaryRequest}

    responseSyntaxFormat="json"
    response={props.getBinaryResult ? JSON.stringify(props.getBinaryResult, undefined, 2) : undefined}
  />;
}

export function GetFileContentsStatusSection(props: {
  uploadStatus: UploadStage,
  currentGetUrl?: string,
  refreshGetBinaryClicked: () => void;
}): JSX.Element {

  return <RequestResponseSection
    stepNumber={5}
    title="GET file contents"
    blurb={<p>Send a GET request to <code>/Binary/{`{id}`}</code>, to load the FHIR Binary resource which represents
      this file. The resource is returned along with a pre-signed GET URL.</p>}

    currentStatus={props.uploadStatus}
    waitingStatuses={['Idle', 'Preparing', 'PostingBinary', 'PuttingBinary', 'MarkingComplete', 'GettingBinary']}
    activeStatuses={['Success']}
    completedStatuses={[]}

    requestSyntaxFormat="http"
    request={props.currentGetUrl ? preformatUrlAsHttpRequest(props.currentGetUrl, 'GET') : undefined}

    responseSyntaxFormat="json"
    response={null}
    responseBlurb={props.currentGetUrl && <>
      <p>
        <a href={props.currentGetUrl} target="_blank" className="btn btn-primary">
          <i className="fa fa-external-link"></i> Open file</a>
        <Button variant="secondary"
                onClick={() => props.refreshGetBinaryClicked()}>
          <i className="fa fa-refresh"></i> Refresh URL</Button>
      </p>
      <p className="small text-muted"><i className="fa fa-warning"></i> This URL is only valid for 5 minutes. If it
        times out, hit the Refresh URL button above to refresh. This will repeat Step 4 and Step 5.</p>
    </>}
  />;
}
