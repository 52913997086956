import React from "react";
import {Card, Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {WarningAlert} from "../../../components/AlertBox";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";
import LandingBlock from "../../../components/LandingBlock";

import SandboxFlowDiagram from "../../../img/docs/empi/simplified-flow-sandbox.png";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "FHIR clinical data repository";
  const pageSubtitle = "";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>

          <WarningAlert title="Sandbox only">
            <p>NES Technology Service does not currently provide a production version of the FHIR server. This is
              something that we're evaluating as part of our beta programme.</p>
          </WarningAlert>


          <h2>A quick introduction</h2>

          <p><a href="https://www.hl7.org/fhir/index.html" target="_blank">Fast Healthcare Interoperability Resources
            (FHIR)</a> is an internationally recognised standard, managed by HL7, for exchanging electronic health
            and care data.</p>

          <p>Most services within the National Digital Platform communicate via FHIR, <a href="openehr">openEHR</a>, or
            a combination of these and other standards.</p>

          <p>To help you get started developing, your sandbox includes a private FHIR server (provided by AWS FHIR
            Works). In addition, many of the mock services within your sandbox use the FHIR server as a data store, to
            simulate sharing data in a complex, multi-application health and care environment.</p>

          <p>
            You can use your private server to:
          </p>
          <ul>
            <li>Evaluate and learn FHIR itself</li>
            <li>Supply data to the mock services within your sandbox</li>
          </ul>

          <h2>FHIR in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="aspirational"
            productionServiceStatusDescription="We're still evaluating the available options to provide the FHIR service. This includes technology options and a service model. We may only provide a subset of FHIR resources."
            sandboxServiceMaturity="pilot"
            sandboxServiceStatusDescription="The Sandbox features one possible implementation of the FHIR server. If we continue with the proposed technologies then the implementation may not change significantly. However it's still possible that the underlying technologies will require to change."
            sandboxLimitations={[
              "We haven't released the FHIR service to production yet.",
              "No business logic is enforced when you interact directly with the FHIR server (e.g. a Patient need not exist before you can create an Appointment for them)."
            ]}
          />

          <h2>Powering sandbox services</h2>
          <p>In the Sandbox environment we use your sandbox's FHIR server as a data source to drive other mocked
            services. For example, when the EMPI service is made available as part of the beta programme we will use
            your sandbox's FHIR server as the source for patient demographics. This will allow you to control the data
            EMPI will provide to your application, without worrying about the governance concerns that come from
            connecting to real demographics providers.</p>

          <Row>
            <Card className="ml-md-2">
              <Card.Img src={SandboxFlowDiagram}/>
            </Card>
          </Row>

        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        <LandingBlock title="Interactive demo"
                      url="fhir/try">
          <p>Explore the API in a sandbox environment from within your browser</p>
        </LandingBlock>

        <LandingBlock title="Postman instructions"
                      url="fhir/postman">
          <p>Connect to the API using Postman (or your favourite API test tool)</p>
        </LandingBlock>

        <LandingBlock title="Manage data"
                      url="/sandbox/data">
          <p>View and generate test data for your sandbox environment</p>
        </LandingBlock>

        <LandingBlock title="FHIR Documentation"
                      url="https://www.hl7.org/fhir/index.html">
          <p>Open the official HL7 FHIR documentation</p>
        </LandingBlock>
      </Row>

    </TurasSection>

  </BaseScreen>;
}
