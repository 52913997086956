import React, {useEffect, useState} from 'react';

import {Button, Form, InputGroup} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";
import {Team, TeamApiKey, TeamFhirSettings} from "../../../models";
import {localStorageKeys} from "../../../constants";
import AdminAPI from "../../../service/AdminAPI";
import {FHIRWorksAPI} from "../../../service/FHIRWorksAPI";
import {getFriendlyErrorMessage} from "../../../helpers";
import {useMsal} from "@azure/msal-react";
import * as ScreenHelpers from "../../ScreenHelpers";
import {ErrorAlert, InfoAlert, WarningAlert} from '../../../components/AlertBox';
import {CorsInstructionMessage} from '../../resources/CorsInstructions';
import {ErrorMessageProps} from '../../BaseScreen';
import SandboxBaseScreen, {
  SandboxFhirDisabledScreen,
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {LargeLoadingSpinner} from "../../../components/LoadingSpinner";
import TurasCodePanel from "../../../components/TurasCodePanel";


export default function TryItScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [fhirSettings, setFhirSettings] = useState<TeamFhirSettings | undefined>();
  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [tryItType, setTryItType] = useState<string>('Appointment');
  const [tryItData, setTryItData] = useState<any>();
  const [tryItErrorMessage, setTryItErrorMessage] = useState<string | undefined>(undefined);
  const [tryItLoading, setTryItLoading] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setFhirSettings,
      undefined,
      setApiKey,
      setErrorMessage,
      setIsLoaded);
  }

  async function sendFhirRequest() {
    if (!team) return;
    if (!fhirSettings) return;
    if (!apiKey) return;

    setTryItErrorMessage("");
    setTryItData(undefined);
    setTryItLoading(true);

    try {
      const adminApi = AdminAPI.fromMsal(msal);

      const token = await adminApi.getCognitoAccessToken(team.id);
      const fhirApi = new FHIRWorksAPI(token.id_token, apiKey.api_key_value, fhirSettings);

      const data = await fhirApi.list(tryItType);
      setTryItData(data);

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'fhir_try_it',
        {
          'resource_type': tryItType,
          'sandbox_id': sandboxId,
          'result': 'success',
          'count': data?.total,
        }
      );

    } catch (e) {
      setTryItErrorMessage(getFriendlyErrorMessage(e));

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'fhir_try_it',
        {
          'resource_type': tryItType,
          'sandbox_id': sandboxId,
          'result': 'fail'
        }
      );
    } finally {
      setTryItLoading(false);
    }

  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "FHIR server - Try it";
  const pageSubtitle = "Use the form below to send quick basic requests to your FHIR server";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded || !team)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!fhirSettings)
    return <SandboxFhirDisabledScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} team={team}/>;


  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>
      {tryItErrorMessage && <ErrorAlert title={tryItErrorMessage}
                                        text="This is most likely due to a CORS issue. See below for instructions on how to bypass it. If that doesn't work please contact us."/>}

      <Form id="tryItTypeForm" className="turasForm" onSubmit={e => e.preventDefault()}>
        <Form.Group controlId="fhirAccessDetails.rootUrl" className="form-group">
          <InputGroup style={{marginBottom: '2vh'}}>
            <InputGroup.Text>GET</InputGroup.Text>
            <InputGroup.Text><code>/tenant/{fhirSettings.tenant_id}/</code></InputGroup.Text>
            <input className="form-control"
                   placeholder="Patient"
                   value={tryItType}
                   onChange={e => setTryItType(e.target.value)}/>
            <Button className="btn btn-primary" type="submit" onClick={sendFhirRequest}
                    style={{margin: 0}}>Send</Button>
          </InputGroup>
          <div>
            {[
              'AllergyIntolerance',
              'Condition',
              'Location',
              'MedicationKnowledge',
              'Practitioner',
            ].map(i => <a href="#"
                          key={`try-it-${i}`}
                          className="px-3"
                          onClick={(e) => {
                            setTryItType(i);
                            e.preventDefault();
                          }}>{i}</a>)}
          </div>

        </Form.Group>
      </Form>
      {tryItType === 'Patient' && <WarningAlert title="FHIR Patient resource">
        Although the Sandbox FHIR server supports <code>Patient</code> you should use the <a
        href={`/apis/empi`}>EMPI service</a> or another service as an authoritative source for patient
        demographics. In this Sandbox environment, you can use the FHIR Patient resource to supply data to your
        private EMPI service.
      </WarningAlert>}

      {tryItLoading && <LargeLoadingSpinner/>}

      {tryItData && <>
        <TurasCodePanel language="json5" copyButton>
          {JSON.stringify(tryItData, null, 2)}
        </TurasCodePanel>

        {tryItData['total'] === 0 && <div className="mt-3"><InfoAlert title="Not seeing any data?">
          Use the <a href={`/sandbox/data`}>Manage Data</a> page to generate some.
        </InfoAlert></div>}
      </>}


      <h2>Warning: CORS may not yet have been enabled in this sandbox</h2>
      <CorsInstructionMessage/>
    </TurasSection>

  </SandboxBaseScreen>;
}
