import React from "react";
import {NavbarUserInfo} from "./UserHandler";

export default function GlobalNav(): JSX.Element {
  return (
    <nav id="universal-nav">
      <div className="container flex-nowrap" style={{display: 'flex', justifyContent:'space-between'}}>

        <a className="navbar-brand flex-grow-1 justify-content-start" href="/" style={{display: 'flex'}}>
          <img src="/img/NDP-logo-small-blue.svg" alt="" className=""/>
          <p>NDP Developer Portal</p>
        </a>

        <NavbarUserInfo/>
      </div>

    </nav>
  );
}
