import dayjs, {OpUnitType} from "dayjs";
import {DomainResource, FhirResource} from "fhir/r4";
import {IMsalContext} from "@azure/msal-react";

import {fhirIdentifierSystem} from "./constants";
import {DeveloperAccount} from "./models";
import {environment} from "./config";

export function urlIsExternal(url: string): boolean {
  return url.startsWith('https://') || url.startsWith('http://');
}

export function getIdentifierSystemDisplayText(system: string | undefined): string {
  if (!system) return '';

  const systems: { [key: string]: string } = {}
  systems[fhirIdentifierSystem.chiNumber] = 'CHI Number';
  systems[fhirIdentifierSystem.gmcNumber] = 'GMC Number';
  systems[fhirIdentifierSystem.nmcNumber] = 'NMC Number';
  systems[fhirIdentifierSystem.snomedCt] = 'SNOMED CT code';
  systems[fhirIdentifierSystem.healthBoardCypher] = 'Health board cypher';
  systems[fhirIdentifierSystem.isdCode] = 'ISD Code';
  systems[fhirIdentifierSystem.iso3166part1] = 'ISO 3166 part 1';
  systems[fhirIdentifierSystem.iso3166part2] = 'ISO 3166 part 2';
  systems[fhirIdentifierSystem.locationType] = 'NES Location type';
  systems[fhirIdentifierSystem.ncdsInternalId] = 'NCDS Internal ID';
  systems[fhirIdentifierSystem.gpPracticeCode] = 'GP Practice Code';
  systems[fhirIdentifierSystem.turasLocationId] = 'Turas Location ID';

  if (system in systems)
    return systems[system];

  return system;
}

export function formatStringDate(ds: string | undefined, inFormat: string, outFormat: string): string {
  if (!ds) return "";

  const d1 = dayjs(ds, inFormat);
  return d1.format(outFormat);
}

export function compareStringDates(ds1: string, ds2: string, format: string, granularity: OpUnitType = 'millisecond'): number {
  const d1 = dayjs(ds1, format);
  const d2 = dayjs(ds2, format);

  if (d1.isSame(d2, granularity))
    return 0;
  if (d1.isBefore(d2, granularity))
    return -1;
  // if (d1.isAfter(d2, granularity))
  return 1;
}

export function getContainedById<T extends FhirResource>(searchId: string, parent: DomainResource, resourceType: string): T | undefined {

  if (searchId.startsWith('#')) {
    // trim off leading # and search by resource ID
    searchId = searchId.substring(1);
    const filtered = parent
      .contained?.filter(c => c.id === searchId && c.resourceType === resourceType)
      .map(c => c as T);

    if (!filtered) {
      console.warn(`Contained ${resourceType} by ID ${searchId} not found`);
      return;
    }

    return filtered[0];
  } else {
    console.warn(`Don't know how to search for ${resourceType} by referenced ID ${searchId}`);
  }
}

export function formatFileSize(size: number): string {
  let outSize, unit;

  if (size < 1024) {
    outSize = size;
    unit = 'bytes';
  } else if (size < 1024 * 1024) {
    outSize = size / 1024;
    unit = 'KiB';
  } else if (size < 1024 * 1024 * 1024) {
    outSize = size / 1024 / 1024;
    unit = 'MiB';
  } else { // if (size < 1024 * 1024 * 1024 * 1024) {
    outSize = size / 1024 / 1024 / 1024;
    unit = 'GiB';
  }

  outSize = Math.round(outSize * 10) / 10;
  return `${outSize} ${unit}`;
}

export function getFriendlyErrorMessage(e: any) {
  console.error(e);

  if (typeof e === 'string')
    return e;

  if (e instanceof Error)
    return (e as Error).message;

  return 'Unknown error occurred';
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';

export function preformatUrlAsHttpRequest(urlString: string, operation: HttpMethod, headers?: { [k: string]: string }): string {
  const url = new URL(urlString);

  const headersString = headers
    ? Object.entries(headers).map(([k, v]) => `${k}: ${v}`).join('\n')
    : '';

  return `
${operation} ${url.pathname} HTTP/1.1
Host: ${url.host}
${headersString}

${url.search ? '### Querystring contents' : ''}
${url.search.replaceAll('&', '\n  &')}
`.trim();
}

export function preformatJsonAsHttpRequest(
  urlString: string, operation: HttpMethod, headers: { [k: string]: string } | undefined, payload: object
): string {
  return `
${preformatUrlAsHttpRequest(urlString, operation, headers)}

${JSON.stringify(payload, undefined, 2)}
`.trim();
}

export function isLoggedIn(msal: IMsalContext): boolean {
  return msal && msal.accounts && msal.accounts.length > 0;
}

export function getHumanName(user: DeveloperAccount | undefined): string {
  if (!user) return '';

  if (user.name) {
    return `${user.name.given} ${user.name.family}`;
  }

  return `${user.given_name} ${user.family_name}`;
}

export function sortResourceTypesByName(o1: { resource_name: string }, o2: { resource_name: string }) {
  if (!o1 && !o2) return 0;
  if (o1 && !o2) return -1;
  if (!o1 && o2) return 1;
  return o1.resource_name.localeCompare(o2.resource_name);
}

export function humanFileSize(bytes: number, nonSi = false, dp = 1) {
  const thresh = nonSi ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = nonSi
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function getLocalCognitoCallbackUrl(): string {
  return window.location.protocol + '//' + window.location.host + '/callback';
}

function cookieExpiryDate(daysInFuture = 0): string {
  if (daysInFuture <= 0)
    return "Thu, 01 Jan 1970 00:00:00 UTC";

  const date = new Date();
  date.setTime(date.getTime() + (daysInFuture * 24 * 60 * 60 * 1000));
  return date.toUTCString();
}

export function handleOptionalCookiesOptOut(refresh: boolean = true) {
  console.log(`Opting out of GA for property ${environment.googleAnalytics?.trackingId}`);

  // @ts-ignore
  if (window.gtag) window.gtag('consent', 'update', {
    'analytics_storage': 'denied',
  });

  document.cookie = `_ga=; expires=${cookieExpiryDate(-1)}; path=/;`;
  if (environment.googleAnalytics) {
    const pid = environment.googleAnalytics.trackingId.replace('G-', '');
    const disableCookieId = 'ga-disable-' + pid;

    document.cookie = `_ga_${pid}=; expires=${cookieExpiryDate(-1)}; path=/;`;
    document.cookie = `${disableCookieId}=1; expires=${cookieExpiryDate(180)}; path=/; SameSite=Lax;`;
  }


  // reload the screen to refresh the UI
  if (refresh)
    window.location = window.location;
}

export function handleOptionalCookiesOptIn(refresh: boolean = true) {
  console.log(`Opting in to GA for property ${environment.googleAnalytics?.trackingId}`);

  if (environment.googleAnalytics) {
    const pid = environment.googleAnalytics.trackingId.replace('G-', '');
    const disableCookieId = 'ga-disable-' + pid;

    document.cookie = `${disableCookieId}=; expires=${cookieExpiryDate(-1)}; path=/;`;
  }

  // @ts-ignore
  if (window.gtag) window.gtag('consent', 'update', {
    'analytics_storage': 'granted',
  });

  // reload the screen to refresh the UI
  if (refresh)
    window.location = window.location;
}

export function hasChosenCookieConsent(): boolean {
  const cookieString = document.cookie;

  if (!cookieString || cookieString.length === 0) return false;

  const cookies = cookieString.split(';')
    .map(c => c.trim());

  // If _ga cookie exists we've previously consented
  if (cookies.some(c => c.startsWith('_ga=')))
    return true;

  if (environment.googleAnalytics) {
    const pid = environment.googleAnalytics.trackingId.replace('G-', '');
    const disableCookieId = 'ga-disable-' + pid;

    // If ga-disable-xxx cookie exists we've previously opted out
    if (cookies.some(c => c.startsWith(disableCookieId + '=')))
      return true;
  }

  return false;
}

// Required to pass validation
export default {}
