import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";

import ArchDiagram from "../../../img/docs/mediastore/simplfied-arch.png";
import FhirDiagram from "../../../img/docs/mediastore/simple-fhir-tree.png";
import LandingBlock from "../../../components/LandingBlock";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "Media Store";
  const pageSubtitle = "";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>
          <h2>A quick introduction</h2>

          <p>Media Store is designed to provide a solution to store unstructured data (binary files, images, audio, etc)
            alongside the metadata required to allow the unstructured data to be incorporated into one or more
            applications.</p>

          <Row>
            <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}>
              <Card className="cardPaddingBottom">
                <div className="card-heading">
                  <Card.Title as="h3">Example use case</Card.Title>
                </div>
                <Card.Body>
                  <p>You might want to build a system for a patient to record images of a wound healing after
                    surgery.</p>
                  <p>In this case, the <strong>patient-facing application</strong> would upload images to Media Store.
                    The GP could view those images via the <strong>GP IT system</strong> during a consultation. If the
                    patient needs referred back to the surgical specialty, the consultant surgeon could view the same
                    images in the <strong>hospital IT system</strong>.
                  </p>

                </Card.Body>
              </Card>
            </Col>

          </Row>

          <h3>Architecture</h3>
          <p>Media Store is built on top of two trusted technologies: the NDP <a href="fhir">FHIR server</a> to
            maintain metadata, searchability, etc, and <a href="https://aws.amazon.com/s3/" target="_blank">AWS
              S3</a> as a resilient and secure data store.</p>

          <img src={ArchDiagram} className="border p-3 bg-white"/>

          <h3>Usage</h3>
          <p>Media Store combines binary storage with FHIR metadata. In the simplest terms, when you upload a file to
            Media Store, it is wrapped in a <a href="https://www.hl7.org/fhir/binary.html" target="_blank">FHIR
              Binary</a> resource. You are then free to link this to other FHIR resources to build more complex clinical
            data models (e.g. <code>Media</code> or <code>DiagnosticReport</code>).</p>

          <p>If your application does not use FHIR, you can simply use the unique ID provided by
            the <code>Binary</code> resource to reference the binary file within your own data structures.</p>

          <img src={FhirDiagram} className="border p-3 bg-white"/>

          <h2>Media Store in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="aspirational"
            productionServiceStatusDescription="Media Store is still in the requirements gathering phase. We'll use information from this beta programme and various collaborations to design the working service."
            sandboxServiceMaturity="pilot"
            sandboxServiceStatusDescription="The Sandbox features one possible implementation of Media Store. If we continue with the proposed technologies then the implementation may not change significantly. However it's still possible that the underlying technologies will require to change."
            sandboxLimitations={[
              "We haven't released Media Store to production yet.",
              "File sizes, retention, etc may be limited to ensure service quality.",
            ]}
          />

        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        <LandingBlock title="Interactive demo"
                      url="media-store/try">
          <p>Explore the API in a sandbox environment from within your browser</p>
        </LandingBlock>

        <LandingBlock title="Manage data"
                      url="/sandbox/data">
          <p>View and generate test data for your sandbox environment</p>
        </LandingBlock>

      </Row>

    </TurasSection>

  </BaseScreen>;
}
