import React from "react";

export type BreadcrumbItem = {
  title: string;
  url?: string;
  active?: boolean;
}

export default function Breadcrumbs(props: { breadcrumbs: BreadcrumbItem[] }): JSX.Element {

  function BreadcrumbListItem(props: React.PropsWithChildren<{ item: BreadcrumbItem }>): JSX.Element {
    let classes = ['breadcrumb-item'];
    if (props.item.active) classes.push('active');

    return <li className="breadcrumb-item" id={classes.join(' ')}>
    {props.item.url && <a href={props.item.url}>{props.item.title}</a>}

    {!props.item.url && <>{props.item.title}</>}

    {props.children}
</li>
  }

  return <nav className="" id="breadcrumbs" aria-label="breadcrumb">
  <ol className="breadcrumb" id="breadcrumbInner">
      {props.breadcrumbs.map((b, n) => {
        return <BreadcrumbListItem item={b} key={`breadcrumb-${n}`}/>
      })}
    </ol>
  </nav>;
}
