import React, {useCallback} from "react";
import {Button, ProgressBar} from "react-bootstrap";

import './TurasFileUpload.css';

import fileUploadImage from '../img/icons/file_upload.png';
import {ErrorCode, FileRejection, useDropzone} from "react-dropzone";
import {humanFileSize} from "../helpers";

export type TurasFileUploadPanelProps = {
  onFileDrop?: (file: File) => void;
  onFileReject?: (file: File, reason: ErrorCode | string) => void;
  maxSizeBytes: number;
  accept?: { [key: string]: string[] };
}

export function TurasFileUploadPanel(props: TurasFileUploadPanelProps): JSX.Element {

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (props.onFileReject)
      rejectedFiles?.forEach(rf => props.onFileReject!(rf.file, rf.errors[0].code));
    if (props.onFileDrop)
      acceptedFiles?.forEach(f => props.onFileDrop!(f));
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    multiple: false,
    maxSize: props.maxSizeBytes,
    accept: props.accept,
    onDrop
  });

  return <div {...getRootProps()}>

    <input {...getInputProps()} />

    <div className={`file-upload-target ${isDragActive ? 'active' : ''}`}>
      <i className="fa fa-cloud-upload"> </i>
      <div>
        <div>Drag and drop or <span className="text-primary">browse your files</span></div>
        {props.maxSizeBytes && <div>Maximum size {humanFileSize(props.maxSizeBytes, false, 0)}</div>}
      </div>
    </div>
  </div>;
}

export type FileUploadStatus = 'not-selected' | 'not-ready' | 'ready' | 'uploading' | 'complete' | 'error'
type BaseFileUploadStatusPanelProps = {
  filename: string;
  status: FileUploadStatus;
  errorMessage?: string;
  useNonSiUnits?: boolean;
  onUploadClick?: () => void;
  onRemoveClick?: () => void;
  onCancelClick?: () => void;
}
type FileUploadPartProps = {
  totalBytes: number;
  currentBytes: number;
  status?: FileUploadStatus;
}
export type TurasMultipartFileUploadStatusPanelProps = BaseFileUploadStatusPanelProps & {
  parts: FileUploadPartProps[];
}

export function TurasMultipartFileUploadStatusPanel(props: TurasMultipartFileUploadStatusPanelProps): JSX.Element {

  function getPartUploadPercent(part: FileUploadPartProps): number {
    if (part.totalBytes <= 0) return 0;
    if (part.currentBytes <= 0) return 0;
    if (part.currentBytes >= part.totalBytes) return 100;

    const pct = part.currentBytes / part.totalBytes * 100;
    return pct;
  }

  function humanFileSize(bytes: number, nonSi = false, dp = 1) {
    const thresh = nonSi ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = nonSi
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  return <>
    <div className="file-upload-item">
      <img src={fileUploadImage}
           alt=""
           aria-hidden="true"/>

      <div className={props.status === 'error' ? 'text-danger' : ''}>
        <div>{props.status !== 'not-selected' ? props.filename : ''}</div>
        {props.parts.map((part, n) => {
          const partStatus = part.status || props.status;
          const partPct = partStatus === 'not-selected'
            ? 0
            : getPartUploadPercent(part);

          return <div key={`part-${n}`}>
            <ProgressBar now={partPct}
                         variant={partStatus === 'error' ? 'danger'
                           : partStatus === 'complete' ? 'success'
                             : ''}
              // label={`${60}%`} visuallyHidden
            />
            <div>
              {partStatus === 'not-selected' &&
                <span>(no file selected)</span>}
              {partStatus !== 'not-selected' &&
                <span>{humanFileSize(part.currentBytes, props.useNonSiUnits)} of {humanFileSize(part.totalBytes, props.useNonSiUnits)}</span>}

              {partStatus === 'not-ready' &&
                <span></span>}

              {partStatus === 'ready' &&
                <span>Ready to upload</span>}

              {partStatus === 'uploading' &&
                <span>Uploading {partPct.toFixed(0)}%</span>}

              {partStatus === 'complete' &&
                <span>100%</span>}

              {partStatus === 'error' &&
                <span className="text-danger">{props.errorMessage || 'Error'}</span>}
            </div>
          </div>;
        })}
      </div>

      <div>
        {['uploading'].includes(props.status) && props.onCancelClick &&
          <div><i className="fa fa-times-circle"></i> <Button
            variant="link" onClick={props.onCancelClick}>Cancel</Button></div>}

        {['ready'].includes(props.status) && props.onUploadClick &&
          <div><i className="fa fa-upload"></i> <Button
            variant="link" onClick={props.onUploadClick}>Upload</Button></div>}

        {['not-ready', 'ready', 'complete', 'error'].includes(props.status) && props.onRemoveClick &&
          <div><i className="fa fa-trash-o"></i> <Button
            variant="link" onClick={props.onRemoveClick}>Remove</Button></div>}
      </div>

    </div>
  </>;
}

export type TurasFileUploadStatusPanelProps = BaseFileUploadStatusPanelProps & {
  totalBytes: number;
  currentBytes: number;
}

export function TurasFileUploadStatusPanel(props: TurasFileUploadStatusPanelProps): JSX.Element {
  return <TurasMultipartFileUploadStatusPanel
    parts={[{totalBytes: props.totalBytes, currentBytes: props.currentBytes}]}
    {...props}
  />
}
