import React from "react";

export type AlertProps = React.PropsWithChildren<{
  title?: string;
  text?: string | JSX.Element;
  className?: string;
}>;
export type GenericAlertProps = AlertProps & {
  faIcon: string;
  alertBg: 'danger' | 'warning' | 'info' | 'success' | 'primary' | 'secondary'
}

export function GenericAlert(props: GenericAlertProps) {
  return <div className={`alert-${props.alertBg} alert ${props.className}`}>
    <div className="alert-icon">
      <span className={`fa ${props.faIcon}`} aria-hidden="true">
      </span>
    </div>
    <div className="alert-content">
      {props.title && <p className="mb-0"><strong>{props.title}</strong></p>}
      {props.text && <p>{props.text}</p>}
      {props.children}
    </div>
  </div>;
}

export function ErrorAlert(props: AlertProps) {
  return (
    <div className={`alert-danger alert ${props.className}`}>
      <div className="alert-icon">
                <span className="fa fa-bolt" aria-hidden="true">
                </span>
      </div>
      <div className="alert-content">
        {props.title && <p className="mb-0"><strong>{props.title}</strong></p>}
        {props.text && <p>{props.text}</p>}
        {props.children}
      </div>
    </div>
  )
}


export function WarningAlert(props: AlertProps) {
  return (
    <div className={`alert-warning alert ${props.className}`}>
      <div className="alert-icon">
                <span className="fa fa-exclamation-triangle" aria-hidden="true">
                </span>
      </div>
      <div className="alert-content">
        {props.title && <p className="mb-0"><strong>{props.title}</strong></p>}
        {props.text && <p>{props.text}</p>}
        {props.children}
      </div>
    </div>
  )
}

export function InfoAlert(props: AlertProps) {
  return (
    <div className={`alert-info alert ${props.className}`}>
      <div className="alert-icon">
                <span className="fa fa-info-circle" aria-hidden="true">
                </span>
      </div>
      <div className="alert-content">
        {props.title && <p className="mb-0"><strong>{props.title}</strong></p>}
        {props.text && <p>{props.text}</p>}
        {props.children}
      </div>
    </div>
  )
}

export function SuccessAlert(props: AlertProps) {
  return (
    <div className={`alert-success alert ${props.className}`}>
      <div className="alert-icon">
                <span className="fa fa-check" aria-hidden="true">
                </span>
      </div>
      <div className="alert-content">
        {props.title && <p className="mb-0"><strong>{props.title}</strong></p>}
        {props.text && <p>{props.text}</p>}
        {props.children}
      </div>
    </div>
  )
}
