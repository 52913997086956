import axios, {AxiosRequestConfig} from "axios";
import {Bundle, FhirResource, Immunization, OperationOutcome, Patient, Resource, ValueSet} from "fhir/r4";

import {environment} from "../config";
import {ApiResponseError} from "../errors";

export class MockVaccinationsAPI {
  private readonly token: string;
  private readonly apiKey: string;

  private readonly vaccinationsApi = environment.vaccinationsApi!;

  constructor(token: string, apiKey: string) {
    this.token = token;
    this.apiKey = apiKey;

    if (!environment.vaccinationsApi)
      throw new Error("Vaccinations API not enabled in this environment");
  }

  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = this.vaccinationsApi.url;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // build path
    const url = `${urlRoot}/${path}`;

    if (this.vaccinationsApi.corsAnywhere?.enabled)
      return `${this.vaccinationsApi.corsAnywhere.prefix}${url}`
    else
      return url;
  }

  private getRequestConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json'
    }

    if (this.token)
      headers['Authorization'] = `Bearer ${this.token}`
    if (this.apiKey)
      headers['X-API-Key'] = this.apiKey;

    // If cors-anywhere enabled, add the necessary headers
    if (this.vaccinationsApi.corsAnywhere?.enabled) {
      for (const key in this.vaccinationsApi.corsAnywhere.headers) {
        // @ts-ignore
        headers[key] = this.vaccinationsApi.corsAnywhere.headers[key];
      }
    }

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async pingApi(): Promise<boolean> {
    return axios.get(this.getApiUrl('/any-old-url'))
      .then(() => true)
      .catch(e => {
        if (e.response) {
          // all is good, we don't care about the response, just that it exists
          return true;
        }
        console.error(e.message);
        // We're likely seeing a failure to connect
        return false;
      });
  }

  async getPatientByChi(chi: string): Promise<Patient | undefined> {
    const url = this.getApiUrl(`/Patient?identifier=${chi}`);
    const config = this.getRequestConfig();

    console.debug(`Getting Patient by CHI from NCDS...`);

    const response = await axios.get(url, config);

    if (response.status !== 200)
      throw ApiResponseError.fromResponse(response);

    const bundle = response.data as Bundle;
    if (bundle.total === 0 || !bundle.entry)
      return undefined;

    const patient = bundle.entry[0] as Patient;
    return patient;
  }

  async getImmunizationsByChi(chi: string): Promise<Immunization[] | OperationOutcome | undefined> {
    const url = this.getApiUrl(`/Immunization?identifier=${chi}`);
    const config = this.getRequestConfig();

    console.debug(`Getting Immunizations by CHI from NCDS...`);

    const response = await axios.get(url, config);

    if (response.status === 404)
      return response.data as OperationOutcome;

    if (response.status !== 200)
      throw ApiResponseError.fromResponse(response);

    const immunizations = response.data as Immunization[];
    return immunizations;
  }

  async getValueSet(identifier: string): Promise<ValueSet | undefined> {
    const url = this.getApiUrl(`/ValueSet?identifier=${identifier}`);
    const config = this.getRequestConfig();

    console.debug(`Getting ValueSet from NCDS...`);

    const response = await axios.get(url, config);

    if (response.status !== 200)
      throw ApiResponseError.fromResponse(response);

    const result = response.data as ValueSet;
    return result;
  }

  async post<Treturn extends Resource>(typeName: string, payload: FhirResource, expectResponse?: number): Promise<Treturn> {
    const url = this.getApiUrl(`/${typeName}`);
    const config = this.getRequestConfig();

    console.debug(`Posting ${typeName}`);
    const response = await axios.post(url, payload, config)

    if (expectResponse && response.status !== expectResponse)
      throw ApiResponseError.fromResponse(response);

    const r = response.data as Treturn;
    return r;
  }

}
