import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../BaseScreen";
import {TurasSection} from "../../components/TurasSection";
import {LegalSideNav} from "./LegalScreenComponents";

export default function TermsAndConditionsScreen() {

  function Clause(props: React.PropsWithChildren<{ idx: number }>): JSX.Element {
    return <div className="mb-2" style={{display: "flex"}}>
      <div style={{width: '2rem'}}>{props.idx}.</div>
      <div style={{flexGrow: '1', flexBasis: '0'}}>{props.children}</div>
    </div>
  }

  return <BaseScreen
    pageTitle="Terms and Conditions"
    pageSubtitle="These terms govern the use of the NDP Developer Portal and Sandbox environment"
  >
    <TurasSection>
      <Row>
        <Col md={4} sm={12}>
          <LegalSideNav
            additionalItemsOn="Terms and conditions"
            additionalItems={[
              {label: "Developer Portal terms and conditions", scrollToId: "dev-portal-terms"},
              {
                label: "Sandbox environment terms and conditions", scrollToId: "sandbox-terms",
                items: [
                  {label: "Introduction", scrollToId: "sandbox-introduction"},
                  {label: "The Sandbox Environment", scrollToId: "sandbox-environment"},
                  {
                    label: "Authorisation, Registration and Onboarding",
                    scrollToId: "sandbox-auth-reg-onboarding"
                  },
                  {label: "NES Role in the Sandbox Environment", scrollToId: "sandbox-nes-role"},
                  {label: "Permitted Use of the Sandbox", scrollToId: "sandbox-permitted-use"},
                  {
                    label: "Authorisation, Registration and Onboarding",
                    scrollToId: "sandbox-auth-reg-onboarding"
                  },
                  {label: "Data Protection", scrollToId: "sandbox-data-protection"},
                  {
                    label: "Confidentiality & Freedom of Information",
                    scrollToId: "sandbox-confidentiality-foi"
                  },
                  {label: "Communications relating to the Sandbox", scrollToId: "sandbox-comms"},
                  {label: "Audit Rights", scrollToId: "sandbox-audit"},
                  {
                    label: "Termination and Suspension and Deletion of Accounts",
                    scrollToId: "sandbox-termination-suspension-deletion"
                  },
                  {label: "Liability", scrollToId: "sandbox-liability"},
                  {label: "Changes to the Terms and Conditions", scrollToId: "sandbox-changes"},
                  {label: "General", scrollToId: "sandbox-general"},
                ]
              },
            ]}/>
        </Col>

        <Col md={8} sm={12}>
          <div id="dev-portal-terms" className="guide-step">
            <h2>Developer Portal terms and conditions</h2>
            <p>Your use of the Developer Portal and its content is governed by the NES <a
              href="https://www.nes.scot.nhs.uk/legal-and-site-information/terms-and-conditions">terms and
              conditions</a>.
            </p>

          </div>

          <div id="sandbox-terms" className="guide-step mb-0">

            <h2>Sandbox environment terms and conditions</h2>

            <p className="text-secondary">Version dated <strong>17th March 2023</strong></p>
          </div>

          <div id="sandbox-introduction" className="guide-step border-top-0 mt-0">
            <h3>Introduction</h3>

            <Clause idx={1}>
              These sandbox terms and conditions (the “Terms and Conditions”) apply to you, the company or organisation
              signing up for sandbox access (“you”) and us, NHS Education for Scotland (“NES”, “we” or “us”). NES is a
              Special Health Board for the whole of Scotland constituted pursuant to the National Health Service
              (Scotland) Act 1978 (as amended).
            </Clause>
            <Clause idx={2}>
              The Terms and Conditions govern your access and use of our sandbox environment which we make available to
              developers in the health and care sector to support the development of products and services based on
              services and data that NES provides (the “Sandbox” or “Sandbox Environment”).
            </Clause>
            <Clause idx={3}>
              The individual accepting these Terms and Conditions and accessing and using the Sandbox Environment
              confirms that they are your authorised representative and have the authority to bind you to these Terms
              and Conditions.
            </Clause>
            <Clause idx={4}>
              By using the Sandbox Environment, you confirm that you accept these Terms and Conditions and that you
              agree to comply with them. If you do not agree to these Terms and conditions, you must not use the Sandbox
              Environment. You agree to inform your employees, agents, and contractors who use the Sandbox of these
              Terms and Conditions, and you will be responsible for their compliance with them.
            </Clause>

          </div>

          <div id="sandbox-environment" className="guide-step">
            <h3>The Sandbox Environment</h3>

            <Clause idx={5}>
              The Sandbox Environment is a replica environment of the development environment used by NES developers,
              that allows you to explore the services that are available on the National Digital Platform operated by
              NES (“National Digital Platform”) and to test against NES non-production services rather than with real
              data or live services. The Sandbox will provide both structured and unstructured storage, with a variety
              of features to support development within the Sandbox Environment. Examples include (but are not limited
              to) FHIR server and Media Store, as well as a Master Patient Index.
            </Clause>
            <Clause idx={6}>
              Within the Sandbox Environment, it will be possible to have project sandboxes (“Project Sandboxes”) for
              individual projects. These Project Sandboxes will have access controls that allow teams to collaborate in
              a specified environment.
            </Clause>
            <Clause idx={7}>
              We grant you access to the Sandbox free of charge on the basis that you will use it only for the
              development of products and services that rely on NES’ products and services and in accordance with these
              Terms and Conditions. The Sandbox is not for commercial use.
            </Clause>

          </div>

          <div id="sandbox-auth-reg-onboarding" className="guide-step">
            <h3>Authorisation, Registration and Onboarding</h3>

            <Clause idx={8}>
              Individuals who wish to participate in the Sandbox (“Participants”) will be subject to an authorisation
              process before access to the Sandbox will be provided. This authorisation process will be at the sole
              discretion of NES.
            </Clause>
            <Clause idx={9}>
              Participants will only be granted access to the Sandbox if they are currently working in a Health Board in
              Scotland.
            </Clause>
            <Clause idx={10}>
              If they do not have an existing Turas account using an @nhs email address, Participants will be required
              to register for a Turas account using an @nhs email address and will not be able to log in and use the
              Sandbox Environment without a Turas account.
            </Clause>
            <Clause idx={11}>
              Participants will be required to confirm acceptance of these Terms and Conditions before access is
              provided to the Sandbox.
            </Clause>
            <Clause idx={12}>
              Participants should notify NES if they cease working for a Health Board for any reason and make any
              necessary arrangements for resultant changes to the Sandbox (e.g. handover or other requirements).
            </Clause>
            <Clause idx={13}>
              Participants should also notify NES if they no longer require Sandbox access for any other reason. NES
              will support handover or other requirements as required.
            </Clause>
            <Clause idx={14}>
              Participants should contact NES about the Sandbox via delivery.digital@nes.scot.nhs.uk with “Sandbox” in
              the subject heading.
            </Clause>
            <Clause idx={15}>
              These Terms and Conditions will operate alongside the NES and Turas terms and conditions, which also apply
              to you and to Participants participating in the Sandbox:
              <ol>
                <li>Terms and conditions | NHS Education for Scotland</li>
                <li>Terms and conditions | Turas | Learn (nhs.scot)</li>
              </ol>
            </Clause>


          </div>

          <div id="sandbox-nes-role" className="guide-step">
            <h3>NES Role in the Sandbox Environment</h3>

            <Clause idx={16}>
              NES will use reasonable efforts to provide support for the Sandbox Environment during standard business
              hours. However, we do not give any service level guarantee for NES support.
            </Clause>
            <Clause idx={17}>
              NES may, as appropriate, collaborate and agree an approach for your journey through the Sandbox as
              necessary. This may include advice on individual testing parameters, measures for outcomes, reporting
              requirements, safeguards, timescales, milestones and term of the sandbox.
            </Clause>

          </div>

          <div id="sandbox-permitted-use" className="guide-step">
            <h3>Permitted Use of the Sandbox</h3>

            <Clause idx={18}>
              When using the Sandbox in line with these Terms and Conditions, you agree that you will:
              <ol>
                <li>not permit any third party to access the Sandbox Environment using your credentials and maintain
                  adequate security measures to prevent unauthorised access and use by any person;
                </li>
                <li>notify NES as soon as you become aware of any unauthorised access or use of the Sandbox by any
                  person;
                </li>
                <li>not interfere or attempt to interfere with the proper working of the Sandbox, disrupt the Sandbox,
                  or any gain or attempt to gain unauthorised access to any computer system or network connected to the
                  Sandbox;
                </li>
                <li>ensure that Participants limit their use of the Sandbox to work they are carrying out on your behalf
                  ;
                </li>
                <li>not upload or use any Personal Data (as defined in the Data Protection Act 2018 and the UK GDPR);
                </li>
                <li>not introduce malware or malicious content;</li>
                <li>not reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code form or
                  structure of the Sandbox, except as might be allowed by any applicable law; and
                </li>
                <li>not take any action which imposes an unreasonable or disproportionately large load on the Sandbox's
                  infrastructure.
                </li>
              </ol>
            </Clause>
            <Clause idx={19}>
              You are required to agree, and take all reasonable precautions to ensure, that no NES or other NHS assets
              (tangible or intangible) are damaged or compromised by your use of the Sandbox Environment.
            </Clause>
            <Clause idx={20}>
              From time to time on a case-by-case basis, NES may require protections, safeguards or assurances regarding
              your use of the Sandbox including any proposed innovations e.g. confirming that uploads of data have been
              scanned for malicious code, confirming that uploaded data does not contain real Personal Data or similar
              checks or safeguards.
            </Clause>

          </div>

          <div id="sandbox-auth-reg-onboarding" className="guide-step">
            <h3>Authorisation, Registration and Onboarding</h3>

            <Clause idx={21}>
              You agree that all intellectual property rights in the Sandbox Environment are, and will remain, the
              property of NES or its licensors. You agree that you have no rights in or to the Sandbox Environment other
              than the limited and temporary right to access and use it, in accordance with these Terms and Conditions.
            </Clause>
            <Clause idx={22}>
              You will retain your intellectual property rights in, and responsibility for, all content and materials
              that you contribute to the Sandbox Environment which either (i) already exist at the start of your
              involvement with the Sandbox Environment, or (ii) are otherwise developed by you outside of the Sandbox
              (“Existing Intellectual Property”). You will only submit Existing Intellectual Property that you have the
              right to share, use and develop, and you will fully comply with any third party licenses relating to the
              Existing Intellectual Property.
            </Clause>
            <Clause idx={23}>
              Subject always to paragraph 21 ,all intellectual property rights obtained, created or developed by you
              during your participation in the Sandbox relating to your proposed innovation ("New Intellectual
              Property") will vest in you, subject to any contrary agreement you may have with a third party.
            </Clause>
            <Clause idx={24}>
              NES may use Existing Intellectual Property and New Intellectual Property as is reasonable to enable it to
              exercise its rights and perform its functions or obligations in connection with the Sandbox Environment,
              and you grant or must procure the grant of royalty-free and non-exclusive licences to NES, for such use of
              the Existing Intellectual Property and the New Intellectual Property.
            </Clause>
            <Clause idx={25}>
              You warrant and undertake to ensure that the use by NES of any Existing Intellectual Property and/or New
              Intellectual Property in accordance with these Terms and Conditions will not infringe the rights of any
              third party.
            </Clause>
            <Clause idx={26}>
              You agree to defend, indemnify, and hold harmless NES from and against any liability or loss (including,
              without limitation, any legal costs) incurred by NES as a result of, or in connection with, NES’ use of
              Existing Intellectual Property and New Intellectual Property in accordance with these Terms and
              Conditions.
            </Clause>

          </div>

          <div id="sandbox-data-protection" className="guide-step">
            <h3>Data Protection</h3>

            <Clause idx={27}>
              Unless otherwise agreed, any Personal Data (such as contact details for Participants) provided to NES as
              part of your participation in the Sandbox will be processed by NES as a Controller in line with our
              Privacy Notice. You are responsible for providing a copy of our Privacy Notice to any employees or other
              data subjects whose personal data you share with NES during your participation in the Sandbox.
            </Clause>
            <Clause idx={28}>
              You acknowledge, agree and warrant that no Personal Data (other than other than the contact details of
              Participants) should be uploaded to, or used in the Sandbox. A random data generator has been included for
              your use within the sandbox and this should be used to create data sets for testing.
            </Clause>
            <Clause idx={29}>
              You must not use real CHI numbers in the Sandbox Environment. A random data generator has been included in
              the Sandbox to ensure that only ‘safe’ CHI numbers (i.e. false CHI numbers starting with “99”) are used.
              If you need to use more realistic ‘unsafe’ CHI numbers (i.e. any CHI number that does not start with “99”)
              for the purposes of your proposed innovation, you must seek prior written authorisation from NES, and you
              shall remain fully liable for your use of such unsafe CHI numbers.
            </Clause>
            <Clause idx={30}>
              You will indemnify NES against any and all losses, including administrative fines, which NES incurs or
              suffers arising out of or in connection with any claim, proceedings or enforcement action made in
              connection with any breach by you of paragraph 28.
            </Clause>
            <Clause idx={31}>
              You acknowledge and agree that your participation in the Sandbox does not affect your responsibility or
              liability if Personal Data is improperly used or disclosed, corrupted or damaged by you in the course of
              engaging with the Sandbox.
            </Clause>

          </div>

          <div id="sandbox-confidentiality-foi" className="guide-step">
            <h3>Confidentiality & Freedom of Information</h3>

            <Clause idx={32}>
              You acknowledge and agree that the Sandbox and all proprietary technology, know-how, and documentation
              related to it, constitute confidential information of NES.
            </Clause>
            <Clause idx={33}>
              You must clearly identify to NES, in writing, which information you provide to us you consider to be your
              confidential information and provide clear reasons why you regard such information to be confidential.
            </Clause>
            <Clause idx={34}>
              Each of us and you will not at any time disclose to any person or entity any confidential information of
              the other party received in connection with your use of the Sandbox, save as permitted by paragraph 35.
            </Clause>
            <Clause idx={35}>
              Each party may disclose the other party's confidential information:
              <ol>
                <li>to its employees, officers, representatives, contractors, subcontractors or advisers on a need to
                  know basis and shall ensure that such employees, officers, representatives, contractors,
                  subcontractors or advisers to whom it discloses the other party's confidential information are also
                  obliged to keep the other party’s information confidential; and
                </li>
                <li>as may be required by law, a court of competent jurisdiction or any governmental or regulatory
                  authority.
                </li>
              </ol>
            </Clause>
            <Clause idx={36}>
              Neither you nor us shall use the other party's confidential information for any purpose other than to
              exercise its rights and perform its obligations under or in connection with these terms and conditions.
            </Clause>
            <Clause idx={37}>
              Your and our obligation to maintain the confidentiality of confidential information does not apply to
              information which:
              <ol>
                <li>was known to you or us, as applicable, before receiving such information;</li>
                <li>is in the public domain, other as a result of a breach of these Terms and Conditions; or</li>
                <li>was received from a third party who was legally entitled to make an unrestricted disclosure</li>
              </ol>
            </Clause>
            <Clause idx={38}>
              Nothing in these Terms and Conditions, shall preclude you or NES from making public under the Freedom of
              Information (Scotland) Act 2002 and/or the Environmental Information (Scotland) Regulations 2004 and/or
              any codes or regulations applicable from time to time relating to access to public authorities’
              information (“FOI”), details of any matters relating to the matters covered by these Terms and Conditions
              unless (i) such details constitute a trade secret; (ii) the disclosure of such details would or would be
              likely to prejudice substantially the commercial interests of any person (including but not limited to you
              or NES); or (iii) such details fall within any other exemption under FOI, provided always that application
              of any such exemption shall be at the sole discretion of the party in receipt of the FOI request. You and
              NES will take all reasonable steps to provide the other party with notice of any intended disclosures
              under FOI prior to releasing such information and shall provide all such assistance and co-operation as
              may be reasonably required by party in receipt of the FOI request to comply with its obligations under
              FOI.
            </Clause>

          </div>

          <div id="sandbox-comms" className="guide-step">
            <h3>Communications relating to the Sandbox</h3>

            <Clause idx={39}>
              NES may, at its own discretion, make information available relating to use of the Sandbox, for example, a
              list of organisations who are engaging with the Sandbox published on the National Digital Platform. For
              clarity, NES will not publish any of the data involved. Where any details of your proposed innovation(s)
              may be published, NES will consult you prior to any such publication.
            </Clause>
            <Clause idx={40}>
              You shall not make any publication or communication regarding the Sandbox without NES’ prior written
              consent and approval of the content of such publication or communication.
            </Clause>

          </div>

          <div id="sandbox-audit" className="guide-step">
            <h3>Audit Rights</h3>

            <Clause idx={41}>
              41. NES reserves the right to monitor engagement in the Sandbox, including (but not limited to) checking
              for any breaches of these Terms and Conditions, misuse of Personal Data, use of ‘unsafe’ CHI numbers,
              security threats, or behaviour that otherwise detrimental to NES or the Sandbox Environment.
            </Clause>

          </div>

          <div id="sandbox-termination-suspension-deletion" className="guide-step">
            <h3>Termination and Suspension and Deletion of Accounts</h3>

            <Clause idx={42}>
              Unless extended by NES, or terminated in accordance with these Terms and Conditions, your involvement in
              the Sandbox terminates on the expiry of the agreed term agreed with us during the onboarding process. If
              NES ceases to operate the Sandbox, your participation in the Sandbox will automatically cease with
              immediate effect.
            </Clause>
            <Clause idx={43}>
              Either you or NES may at any time, on two weeks written notice, terminate your involvement in the Sandbox.
            </Clause>
            <Clause idx={44}>
              NES may at any time terminate, with immediate effect, your involvement in the Sandbox if you commit a
              material or repeated breach of these Terms and Conditions, which is not capable of remedy.
            </Clause>
            <Clause idx={45}>
              If NES identifies or becomes aware of activity or suspected activity that breaches these Terms and
              Conditions or may be unlawful, NES reserves the right to suspend access to a Project Sandbox, to the
              Sandbox Environment as a whole, or to terminate your engagement with the Sandbox at NES’ sole discretion.
              NES also reserves the right to report such activity to any regulatory body or the police where appropriate
              or necessary in the circumstances. NES will also assist such bodies with any investigations as required.
            </Clause>
            <Clause idx={46}>
              NES reserves the right to suspend or permanently disable your access to the Sandbox Environment in the
              event of your violation of these Terms and Conditions or any activity which brings NES or the Sandbox into
              disrepute, including as a result of unlawful activity. This may include (but is not limited to):
              <ol>
                <li>Uploading illegal content,</li>
                <li>Uploading real personal data,</li>
                <li>Misuse,</li>
                <li>Introducing malware or malicious content,</li>
                <li>Any attempts to manipulate NES functionality,</li>
                <li>Exceeding or persistent exceeding of agreed usage,</li>
                <li>Creating or allowing access to other people other than by the onboarding process,</li>
                <li>Facilitating unauthorised access,</li>
                <li>Sharing credentials,</li>
                <li>Any publicity or other activity that brings the National Digital Platform, NES or its services into
                  disrepute,
                </li>
                <li>Failure to notify changes of role or access (for example, failing to notify NES to remove access),
                </li>
                <li>Using the Sandbox for any activity beyond its intended use.</li>
              </ol>
            </Clause>
            <Clause idx={47}>
              Where a Project Sandbox has been dormant (i.e. there has been a lack of interaction with the Project
              Sandbox for 3 months), NES reserves the right to archive, delete or otherwise manage such Project Sandbox
              in its sole discretion.
            </Clause>
            <Clause idx={48}>
              You must take any action agreed with NES or requested by NES upon termination or exit from the Sandbox
              however arising.
            </Clause>
            <Clause idx={49}>
              You acknowledge that, given the innovative nature of the Sandbox, NES is entitled to suspend or terminate
              the Sandbox project at any time should its operation of the Sandbox lead to detrimental unexpected
              consequences for NES or Sandbox Participants.
            </Clause>
            <Clause idx={50}>
              The following timeframes shall apply to the retention of information in the Sandbox Environment:
              <ol>
                <li>Participant account information - where a Participant has been dormant for 3 months (i.e. has not
                  accessed their account) – NES will carry out a soft delete of the Participant’s account information,
                  and shall carry out a hard delete of that information at the date 7 years from the date of last
                  access.
                </li>
                <li>Project Sandboxes – where a Project Sandbox has been dormant for 3 months NES will carry out a soft
                  delete of that Project Sandbox, with hard delete at 7 years.
                </li>
                <li>Content within Sandboxes - due to storage restrictions all content within the Sandbox Environment
                  shall be irreversibly deleted if it has not been accessed or used for a period of 3 months.
                </li>
                <li>Participant Requests – if a Participant requests that content be deleted from the Sandbox, NES will
                  irreversibly delete the specified content without undue delay. Where a Participant requests that their
                  user account is deleted, NES shall carry out a soft delete of the account information but will retain
                  an audit copy for a period of 7 years before hard deletion.
                </li>
              </ol>
              In this paragraph 50, “soft delete” means removal of information from the Sandbox so that it cannot be
              seen or accessed in the Sandbox environment but a copy is retained in NES systems and “hard delete” means
              that the entry will be permanently removed from all areas of the Sandbox environment and cannot be
              re-instated.
            </Clause>

          </div>

          <div id="sandbox-liability" className="guide-step">
            <h3>Liability</h3>

            <Clause idx={51}>
              We will use reasonable care in our dealings with you in relation to the Sandbox and your proposed
              innovation(s). Given the nature of the Sandbox and NES’ involvement, however, NES does not accept any
              liability or responsibility in relation to your use of the Sandbox, your proposed innovation and/or these
              Terms and Conditions, whether in contract, delict (including negligence) or tort or otherwise.
            </Clause>
            <Clause idx={52}>
              Nothing in the Terms and Conditions seeks to limit NES’ liability in any way which is not permitted by
              law, including our liability to you for fraud or fraudulent misrepresentation.
            </Clause>

          </div>

          <div id="sandbox-changes" className="guide-step">
            <h3>Changes to the Terms and Conditions</h3>

            <Clause idx={53}>
              NES retains the right to amend these Terms and Conditions from time to time. Every time you wish to
              use the Sandbox Environment, please check these Terms and Conditions to ensure you understand the terms
              that apply at that time.
            </Clause>

          </div>

          <div id="sandbox-general" className="guide-step">
            <h3>General</h3>

            <Clause idx={54}>
              Your involvement in the Sandbox does not create any partnership or joint venture between you and NES,
              constitute either you or NES the agent of the other, nor authorise either you or NES to make or enter into
              any commitments for or on behalf of the other party.
            </Clause>
            <Clause idx={55}>
              Only you and NES have rights under these Terms and Conditions. A person who is not a party to these Terms
              and Conditions, has no rights to enforce it or enjoy any benefits under it.
            </Clause>
            <Clause idx={56}>
              You acknowledge that you have not relied on any representation made by or on behalf of NES which is not
              set out in these Terms and Conditions.
            </Clause>
            <Clause idx={57}>
              Any formal notice to be given under the Terms and Conditions shall be in writing and may be served by
              personal delivery, first class recorded post, or e-mail to the address of you or us (as applicable) set
              out in the onboarding, or such other address as you or we have notified to the other for formal notices.
              Notices shall be deemed served on the next working day after delivery. An email shall be deemed delivered
              when sent unless an error message is received or, where an out of office message is received, on the date
              the out of office message states the recipient is to return.
            </Clause>
            <Clause idx={58}>
              To the extent that these Terms and Conditions do not constitute an NHS contract for the purposes of
              Section 17A of the National Health Service (Scotland) Act 1978, any matter, claim or dispute arising out
              of or in connection with these Terms and Conditions, whether contractual or non-contractual, is to be
              governed by and determined in accordance with Scots law. You and we irrevocably submit to the jurisdiction
              of the Scottish courts.
            </Clause>

          </div>

        </Col>
      </Row>
    </TurasSection>

  </BaseScreen>;
}
