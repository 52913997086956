import React, {useEffect, useState} from "react";
import {Team, TeamApiKey} from "../../../models";
import {ErrorMessageProps} from "../../BaseScreen";
import * as ScreenHelpers from "../../ScreenHelpers";
import {useMsal} from "@azure/msal-react";
import AdminAPI from "../../../service/AdminAPI";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {getFriendlyErrorMessage} from "../../../helpers";
import {MockEmpiAPI} from "../../../service/MockEmpiAPI";
import {Parameters, Patient} from "fhir/r4";
import {LargeLoadingSpinner} from "../../../components/LoadingSpinner";
import {ErrorAlert, InfoAlert} from "../../../components/AlertBox";
import TurasRadioGroup from "../../../components/TurasRadioGroup";
import {environment} from "../../../config";
import {ApiResponseError} from "../../../errors";
import {localStorageKeys} from "../../../constants";
import {useLocalStorage} from "usehooks-ts";
import TurasCodePanel from "../../../components/TurasCodePanel";

export default function TryItScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const adminAPI = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [searchChi, setSearchChi] = useState<string>();
  const [searchChiValidated, setSearchChiValidated] = useState(false);

  const [matchName, setMatchName] = useState<string>();
  const [matchAddress, setMatchAddress] = useState<string>();
  const [matchGender, setMatchGender] = useState<string>();
  const [matchDob, setMatchDob] = useState<string>();
  const [searchDemographicsValidated, setSearchDemographicsValidated] = useState(false);

  const [tryItRequestData, setTryItRequestData] = useState<string>();
  const [tryItData, setTryItData] = useState<any>();
  const [tryItErrorMessage, setTryItErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, adminAPI,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      setApiKey,
      setErrorMessage,
      setIsLoaded);
  }

  async function doSearchByChi() {
    if (!team) return;
    if (!apiKey) return;
    if (!searchChi) return;

    setTryItErrorMessage(undefined);
    setTryItData(undefined);

    setTryItRequestData(`
GET /Patient/${searchChi} HTTP/1.1
Host: ${environment.empiApi!.url.replace('http://', '').replace('https://', '')}
X-API-Key: (API KEY)
Authorization: Bearer (TOKEN) 
    `.trim());

    try {
      // get a new auth token
      const token = await adminAPI.getCognitoAccessToken(team.id);

      // Call the mock EMPI API
      const empiApi = new MockEmpiAPI(token.id_token, apiKey.api_key_value);

      const data = await empiApi.getPatientByChi(searchChi);
      setTryItData(data || '(empty)');

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'empi_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_chi',
          'result': 'success',
        }
      );

    } catch (e) {
      if (e instanceof ApiResponseError)
        setTryItErrorMessage({
          title: e.message,
          text: e.responseData ? JSON.stringify(e.responseData) : '',
        });
      else
        setTryItErrorMessage({
          title: "Error",
          text: getFriendlyErrorMessage(e)
        });
      // @ts-ignore
      if (window.gtag) window.gtag('event', 'empi_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_chi',
          'result': 'fail',
        }
      );
    }
  }

  async function doSearchByDemographics() {
    if (!team) return;
    if (!apiKey) return;

    setTryItErrorMessage(undefined);
    setTryItData(undefined);


    const name = matchName ? [{family: matchName}] : undefined;
    const address = matchAddress ? [{line: [matchAddress]}] : undefined;
    const queryParams = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "resource",
          resource: {
            resourceType: "Patient",
            name,
            address,
            birthDate: matchDob,
            gender: matchGender,
          } as Patient
        }
      ]
    } as Parameters;

    setTryItRequestData(`
POST /Patient/$match HTTP/1.1
Host: ${environment.empiApi!.url.replace('http://', '').replace('https://', '')}
X-API-Key: (API KEY)
Authorization: Bearer (TOKEN) 
Content-Type: application/json

${JSON.stringify(queryParams, undefined, 2)}
    `.trim());

    try {
      // get a new auth token
      const token = await adminAPI.getCognitoAccessToken(team.id);

      // Call the mock EMPI API
      const empiApi = new MockEmpiAPI(token.id_token, apiKey.api_key_value);

      const data = await empiApi.searchPatientByDemographics(queryParams);
      setTryItData(data);

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'empi_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_demographics',
          'result': 'success',
          'count': data?.total,
        }
      );
    } catch (e) {
      if (e instanceof ApiResponseError)
        setTryItErrorMessage({
          title: e.message,
          text: e.responseData ? JSON.stringify(e.responseData) : '',
        });
      else
        setTryItErrorMessage({
          title: "Error",
          text: getFriendlyErrorMessage(e)
        });

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'empi_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_demographics',
          'result': 'fail',
        }
      );
    }
  }


  function searchByChi(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setSearchChiValidated(true);

    // Check validation
    if (form.checkValidity() === false)
      return;


    // hide the validation result if we're happy with the result
    setSearchChiValidated(false);
    setSearchDemographicsValidated(false);

    // Perform the search
    doSearchByChi();

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);
  }

  function searchByDemographics(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setSearchDemographicsValidated(true);

    // Check validation
    if (form.checkValidity() === false)
      return;
    if (!matchName && !matchAddress && !matchGender && !matchDob)
      return;

    // hide the validation result if we're happy with the result
    setSearchDemographicsValidated(false);
    setSearchChiValidated(false);

    // Perform the search
    doSearchByDemographics()

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Electronic Master Patient Index (EMPI) - Try it";
  const pageSubtitle = "Call the EMPI API from within your browser";

  if (!environment.empiApi)
    return <SandboxBaseScreen
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      team={team}
      errorMessage={{
        title: "EMPI disabled",
        text: "EMPI integration is not enabled in this sandbox environment"
      }}
      isLoaded={true}
    />

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded || !team)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>
      <p>You can use the form below to send requests directly to your sandbox's private EMPI instance. The request data
        and response are displayed below, so you can easily incorporate them into your application.</p>

      <Row>
        <Col lg={6} md={12}>
          <h3>Search by CHI</h3>

          <Form className="turasForm"
                noValidate
                validated={searchChiValidated}
                onSubmit={searchByChi}>
            <p>Required fields are marked with an asterisk <span className="requiredFieldMarkerNotice">*</span></p>

            <Form.Group className="form-group mb-3">
              <Form.Label>CHI <span className="requiredFieldMarkerNotice">*</span></Form.Label>
              <Form.Control type="text"
                            className="form-control-sm"
                            minLength={10}
                            maxLength={10}
                            pattern="[0-9]{10}"
                            required={true}
                            value={searchChi}
                            onChange={e => setSearchChi(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid CHI (10 numeric digits).
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit">Search</Button>

          </Form>
        </Col>

        <Col lg={6} md={12}>
          <h3>Search by demographics</h3>

          <Form className="turasForm"
                noValidate
                onSubmit={searchByDemographics}>
            <p>All fields are optional, but you must complete at least one field</p>

            <Form.Group className="form-group mb-3">
              <Form.Label>Name</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="text"
                              value={matchName}
                              onChange={e => setMatchName(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="form-group mb-3">
              <Form.Label>Address (street, city, or postcode)</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="text"
                              value={matchAddress}
                              onChange={e => setMatchAddress(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <TurasRadioGroup
              title="Gender"
              items={[
                {value: "male", label: "Male"},
                {value: "female", label: "Female"},
              ]}
              onValueChange={(v) => setMatchGender(v)}
            />

            <Form.Group className="form-group mb-3">
              <Form.Label>Birth date</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="text"
                              value={matchDob}
                              onChange={e => setMatchDob(e.target.value)}
                              placeholder="YYYY-DD-MM"
                />
              </InputGroup>
            </Form.Group>

            {searchDemographicsValidated && <div className="invalid-feedback" style={{'display': 'block'}}>
              Please complete at least one field.
            </div>}

            <Button type="submit">Search</Button>

          </Form>
        </Col>
      </Row>

      <InfoAlert title="Sandbox data and authentication" className="mt-3">
        <p>It's important to note that the data for the Sandbox EMPI comes directly from your private FHIR server. All
          the patients in your FHIR server are made available via the EMPI API. This obviously differs from the
          production
          EMPI, which uses national CHI as a data source.</p>
        <p>Authentication differs slightly in production too. We still use AWS Cognito in prod, but we use a slightly
          different configuration from that in the Sandbox.</p>
      </InfoAlert>


      {tryItRequestData && <>
        <h3>Request</h3>
        <TurasCodePanel language="json5" copyButton>
          {tryItRequestData.trim()}
        </TurasCodePanel>

        <p>You can find the API Key and Bearer token on the <a href={`/sandbox/quick-access`}>Quick
          access page</a> (these details are shared with FHIR, in the Sandbox). You can find out how to request your
          own access token on the <a href={`/sandbox/auth`}>Authentication page</a>.</p>


        <h3 id="results-header">Results</h3>

        {!tryItErrorMessage && !tryItData &&
          <LargeLoadingSpinner/>}

        {tryItErrorMessage &&
          <ErrorAlert {...tryItErrorMessage}/>}

        {tryItData && <>
          <TurasCodePanel language="json5" copyButton>
            {JSON.stringify(tryItData, null, 2)}
          </TurasCodePanel>

          {/* Data hint for searching by CHI */}
          {tryItData['resourceType'] === 'OperationOutcome'
            && tryItData['issue']
            && tryItData['issue'].length
            && tryItData['issue'][0]['code'] === 'not-found'
            && <div className="mt-3"><InfoAlert title="Not seeing any data?">
              Use the <a href={`/sandbox/data`}>Manage Data</a> page to generate some.
            </InfoAlert></div>}

          {/* Data hint for searching by demographics */}
          {tryItData['total'] === 0 && <div className="mt-3"><InfoAlert title="Not seeing any data?">
            Use the <a href={`/sandbox/data`}>Manage Data</a> page to generate some.
          </InfoAlert></div>}
        </>}
      </>}

    </TurasSection>

  </SandboxBaseScreen>;
}
