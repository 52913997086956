import React from "react";
import "../turas-overrides.css";
import {Modal, ModalBody, ModalHeader, ModalTitle, Spinner} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";

export function LargeLoadingSpinner(): JSX.Element {
  return (
    <div style={{display: "flex"}} className="loading-container">
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    </div>
  );
}

export type LoadingModalProps = {
  title: string;
  message: string;
  spinnerVariant?: Variant;
}

export function LoadingModal(props: LoadingModalProps): JSX.Element {
  return <>
    <Modal show={true}>
      <ModalHeader closeButton={false}>
        <ModalTitle>{props.title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p>{props.message}</p>
        <div className="text-center">
          <Spinner variant={props.spinnerVariant}
                   style={{
                     width: '70px',
                     height: '70px',
                   }}/>
        </div>
      </ModalBody>
    </Modal>
  </>;
}
