import React, {useEffect, useState} from "react";
import {Team, TeamApiKey} from "../../../models";
import {ErrorMessageProps} from "../../BaseScreen";
import * as ScreenHelpers from "../../ScreenHelpers";
import {useMsal} from "@azure/msal-react";
import AdminAPI from "../../../service/AdminAPI";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getFriendlyErrorMessage} from "../../../helpers";
import {MockVaccinationsAPI} from "../../../service/MockVaccinationsAPI";
import {LargeLoadingSpinner} from "../../../components/LoadingSpinner";
import {ErrorAlert, InfoAlert, WarningAlert} from "../../../components/AlertBox";
import {environment} from "../../../config";
import {Parameters, ValueSet} from "fhir/r4";
import TurasTable from "../../../components/TurasTable";
import {ApiResponseError} from "../../../errors";
import {localStorageKeys} from "../../../constants";
import {useLocalStorage} from "usehooks-ts";
import TurasCodePanel from "../../../components/TurasCodePanel";

export default function TryItScreen(): JSX.Element {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const adminAPI = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<TeamApiKey | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [searchChi, setSearchChi] = useState<string>();
  const [selectedValueSet, setSelectedValueSet] = useState<string>("vaccine-code");
  const [selectedReport, setSelectedReport] = useState<string>("updated-patients");
  const [searchChiValidated, setSearchChiValidated] = useState(false);

  const [tryItRequestData, setTryItRequestData] = useState<string>();
  const [tryItData, setTryItData] = useState<any>();
  const [tryItValueSet, setTryItValueSet] = useState<ValueSet | undefined>();
  const [tryItReportData, setTryItReportData] = useState<any | undefined>();
  const [tryItErrorMessage, setTryItErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, adminAPI,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      setApiKey,
      setErrorMessage,
      setIsLoaded);
  }

  function clearTryItData() {
    setTryItErrorMessage(undefined);
    setTryItData(undefined);
    setTryItValueSet(undefined);
    setTryItReportData(undefined);
  }

  async function doSearchByChi() {
    if (!team) return;
    if (!apiKey) return;
    if (!searchChi) return;

    clearTryItData();

    setTryItRequestData(`
GET /Immunizations?identifier=${searchChi} HTTP/1.1
Host: ${environment.vaccinationsApi!.url.replace('http://', '').replace('https://', '')}
X-API-Key: (API KEY)
Authorization: Bearer (TOKEN) 
    `.trim());

    try {
      // get a new auth token
      const token = await adminAPI.getCognitoAccessToken(team.id);

      // Call the mock Vaccinations API
      const vaccinationsApi = new MockVaccinationsAPI(token.id_token, apiKey.api_key_value);

      const data = await vaccinationsApi.getImmunizationsByChi(searchChi);
      setTryItData(data || '(empty)');

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_chi',
          'result': 'success',
        }
      );
    } catch (e) {
      if (e instanceof ApiResponseError)
        setTryItErrorMessage({
          title: e.message,
          text: e.responseData ? JSON.stringify(e.responseData) : '',
        });
      else
        setTryItErrorMessage({
          title: "Error",
          text: getFriendlyErrorMessage(e)
        });

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'search_by_chi',
          'result': 'success',
        }
      );
    }
  }

  async function doLoadValueSet() {
    if (!team) return;
    if (!apiKey) return;
    if (!selectedValueSet) return;

    clearTryItData();

    setTryItRequestData(`
GET /ValueSet?identifier=${selectedValueSet} HTTP/1.1
Host: ${environment.vaccinationsApi!.url.replace('http://', '').replace('https://', '')}
X-API-Key: (API KEY)
Authorization: Bearer (TOKEN) 
    `.trim());

    try {
      // get a new auth token
      const token = await adminAPI.getCognitoAccessToken(team.id);

      // Call the mock Vaccinations API
      const vaccinationsApi = new MockVaccinationsAPI(token.id_token, apiKey.api_key_value);

      const data = await vaccinationsApi.getValueSet(selectedValueSet);
      setTryItData(data || '(empty)');
      setTryItValueSet(data);

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'load_value_set',
          'result': 'success',
          'resource_type': selectedValueSet,
        }
      );
    } catch (e) {
      if (e instanceof ApiResponseError)
        setTryItErrorMessage({
          title: e.message,
          text: e.responseData ? JSON.stringify(e.responseData) : '',
        });
      else
        setTryItErrorMessage({
          title: "Error",
          text: getFriendlyErrorMessage(e)
        });
      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'load_value_set',
          'result': 'fail',
          'resource_type': selectedValueSet,
        }
      );
    }

  }

  async function doLoadReport() {
    if (!team) return;
    if (!apiKey) return;
    if (!selectedReport) return;

    clearTryItData();

    const requestParams: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "file-type",
          valueString: selectedReport
        },
        {
          name: "number-of-records",
          valueInteger: 100
        }
      ]
    }

    setTryItRequestData(`
# NB: In production, you'll pull a report file rather than sending a generate request
# GET /updated-patients/<filename> HTTP/1.1
# Host: vaccination-updates.products.ndp.scot
# Authorization: Bearer (TOKEN)

POST /batch-files-proxy HTTP/1.1
Host: ${environment.vaccinationsApi!.url.replace('http://', '').replace('https://', '')}
X-API-Key: (API KEY)
Authorization: Bearer (TOKEN)

${JSON.stringify(requestParams, undefined, 2)}
    `.trim());

    try {
      // get a new auth token
      const token = await adminAPI.getCognitoAccessToken(team.id);

      // Call the mock Vaccinations API
      const vaccinationsApi = new MockVaccinationsAPI(token.id_token, apiKey.api_key_value);

      const data = await vaccinationsApi.post('batch-files-proxy', requestParams);
      setTryItData(data || '(empty)');
      setTryItReportData(data);

      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'load_report',
          'result': 'success',
          'resource_type': selectedReport,
        }
      );
    } catch (e) {
      if (e instanceof ApiResponseError)
        setTryItErrorMessage({
          title: e.message,
          text: e.responseData ? JSON.stringify(e.responseData) : '',
        });
      else
        setTryItErrorMessage({
          title: "Error",
          text: getFriendlyErrorMessage(e)
        });
      // @ts-ignore
      if (window.gtag) window.gtag('event', 'ncds_try_it',
        {
          'sandbox_id': sandboxId,
          'mode': 'load_report',
          'result': 'fail',
          'resource_type': selectedReport,
        }
      );
    }
  }

  function searchByChi(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setSearchChiValidated(true);

    // Check validation
    if (form.checkValidity() === false)
      return;


    // hide the validation result if we're happy with the result
    setSearchChiValidated(false);

    // Perform the search
    doSearchByChi();

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);
  }

  function loadValueSet(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // Perform the request
    doLoadValueSet();

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);
  }

  function loadReport(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // Perform the request
    doLoadReport();

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Vaccinations API (NCDS) - Try it";
  const pageSubtitle = "Call the Vaccinations API from within your browser";

  if (!environment.vaccinationsApi)
    return <SandboxBaseScreen
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      team={team}
      errorMessage={{
        title: "Vaccinations API disabled",
        text: "Vaccinations API integration is not enabled in this sandbox environment"
      }}
      isLoaded={true}
    />

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded || !team)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>
      <p>You can use the form below to send requests directly to your sandbox's private Vaccination API. The request
        data and response are displayed below, so you can easily incorporate them into your application.</p>


      <ul className="nav nav-tabs nav-justified mb-3" role="tablist">
        <li className="nav-item" role="presentation">
          <a className="nav-link active"
             data-toggle="tab" href="#tab-1-panel"
             id="tab-1-tab"
             role="tab" aria-selected="true">
            Immunizations</a>
        </li>
        <li className="nav-item" role="presentation">
          <a className="nav-link"
             data-toggle="tab" href="#tab-2-panel"
             id="tab-2-tab"
             role="tab" aria-selected="false">
            Value Sets</a>
        </li>
        {<li className="nav-item" role="presentation">
          <a className="nav-link"
             data-toggle="tab" href="#tab-3-panel"
             id="tab-3-tab"
             role="tab" aria-selected="false">
            Batch Files</a>
        </li>}
      </ul>

      <div className="tab-content">
        <div className="tab-pane active"
             id="tab-1-panel"
             aria-labelledby="tab-1-tab"
             role="tabpanel">

          <Form className="turasForm"
                noValidate
                validated={searchChiValidated}
                onSubmit={searchByChi}>

            <h3>Description</h3>
            <p>The NCDS API returns an array of <a href="https://www.hl7.org/fhir/immunization.html" target="_blank">FHIR
              Immunization</a> for the specified patient (or 404 if the patient doesn't exist)</p>

            <h3>Search by CHI</h3>

            <Row className="form-row">
              <Form.Group className="form-group mb-3 col-sm-8 col-md-6 col-lg-4">
                <Form.Label>CHI</Form.Label>
                <Form.Control type="text"
                              minLength={10}
                              maxLength={10}
                              pattern="[0-9]{10}"
                              required={true}
                              value={searchChi}
                              onChange={e => setSearchChi(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid CHI (10 numeric digits).
                </Form.Control.Feedback>
              </Form.Group>

            </Row>

            <Row className="form-row">
              <Col sm={12} md={12} lg={8}>
                <WarningAlert title="Sandbox data">
                  <p>The Sandbox converts the Immunization, Practitioner, Location, etc resources from your private <a
                    href={`/sandbox/fhir`}>FHIR server</a> into NCDS FHIR responses</p>
                </WarningAlert>
              </Col>
            </Row>

            <Button type="submit">Search</Button>

          </Form>

        </div>

        <div className="tab-pane fade"
             id="tab-2-panel"
             aria-labelledby="tab-2-tab"
             role="tabpanel">

          <Form className="turasForm"
                noValidate
                onSubmit={loadValueSet}>

            <h3>Description</h3>

            <p>The NCDS API returns a <a href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR
              ValueSet</a> which represents the set of data requested</p>

            <h3>Lookup ValueSets</h3>

            <Form.Group className="form-group mb-3">
              <Form.Label htmlFor="value-set-dropdown">ValueSet name</Form.Label>

              <Row className="form-row">
                <Col sm={12} md={6} lg={4}>
                  <Form.Select
                    id="value-set-dropdown"
                    className="form-control select mb-2"
                    required={true}
                    value={selectedValueSet}
                    onChange={e => setSelectedValueSet(e.target.value)}>

                    <option value="vaccine-code">Vaccine Type</option>
                    <option value="target-disease">Vaccine Target Disease</option>
                    <option value="body-site">Body Site</option>
                    <option value="administration-route">Drug Administration Route</option>
                    <option value="status">Immunization Status</option>
                    <option value="status-reason">Immunization Status Reason</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="form-row">
                <Col sm={12} md={12} lg={8}>
                  <WarningAlert title="Sandbox data">
                    {selectedValueSet === 'vaccine-code' &&
                      <p>The Sandbox converts the <a
                        href="https://www.hl7.org/fhir/medicationknowledge.html"
                        target="_blank">MedicationKnowledge</a> resources from your private FHIR server into a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                    {selectedValueSet === 'target-disease' &&
                      <p>The Sandbox converts the <a
                        href="https://www.hl7.org/fhir/condition.html"
                        target="_blank">Condition</a> resources from your private FHIR server into a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                    {selectedValueSet === 'body-site' &&
                      <p>The Sandbox returns a fixed set of anatomical body sites as a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                    {selectedValueSet === 'administration-route' &&
                      <p>The Sandbox returns a fixed set of routes as a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                    {selectedValueSet === 'status' &&
                      <p>The Sandbox returns a fixed set of immunization status codes as a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                    {selectedValueSet === 'status-reason' &&
                      <p>The Sandbox returns a fixed set of immunization status reasons as a <a
                        href="https://www.hl7.org/fhir/valueset.html" target="_blank">FHIR ValueSet</a></p>}

                  </WarningAlert>
                </Col>
              </Row>
            </Form.Group>

            <Button type="submit">Send request</Button>

          </Form>
        </div>

        <div className="tab-pane fade"
             id="tab-3-panel"
             aria-labelledby="tab-3-tab"
             role="tabpanel">

          <Form className="turasForm"
                noValidate
                onSubmit={loadReport}>

            <h3>Description</h3>

            <p>The NCDS service generates regular reports (hourly/daily/weekly) summarising data for various purposes.
              These can be accessed via the API and used within your application.</p>

            <p>The Sandbox environment allows you to generate some of these reports on demand, so that you can test your
              application without having to wait for the next scheduled reporting period. Reports are generated from the
              data held within your private FHIR server, and are representative of reports you'll receive from the
              production service.</p>

            <h3>Generate a report</h3>

            <Form.Group className="form-group mb-3">
              <Form.Label htmlFor="report-dropdown">Report name</Form.Label>

              <Row className="form-row">
                <Col sm={12} md={6} lg={4}>
                  <Form.Select
                    id="report-dropdown"
                    className="form-control select mb-2"
                    required={true}
                    value={selectedReport}
                    onChange={e => setSelectedReport(e.target.value)}>

                    <option value="updated-patients">Updated patients</option>
                  </Form.Select>
                </Col>
              </Row>

            </Form.Group>

            <Button type="submit">Generate report</Button>

          </Form>
        </div>
      </div>


      <InfoAlert title="Sandbox data and authentication" className="mt-3">
        <p>It's important to note that the data for the Sandbox Vaccination API comes directly from your private FHIR
          server. All the patients in your FHIR server are made available via this API. This differs from the
          production API, which uses a private database and may not contain all the patients available in National
          CHI.</p>
        <p>Authentication differs slightly in production too. We still use AWS Cognito in prod, but we use a slightly
          different configuration from that in the Sandbox.</p>
      </InfoAlert>


      {tryItRequestData && <>
        <h3>Request</h3>
        <TurasCodePanel language="json5" copyButton>
          {tryItRequestData.trim()}
        </TurasCodePanel>

        <p>You can find the API Key and Bearer token on the <a href={`/sandbox/quick-access`}>Quick
          access page</a> (these details are shared with FHIR, in the Sandbox). You can find out how to request your
          own access token on the <a href={`/sandbox/auth`}>Authentication page</a>.</p>


        <h3 id="results-header">Results</h3>

        {!tryItErrorMessage && !tryItData &&
          <LargeLoadingSpinner/>}

        {tryItErrorMessage &&
          <ErrorAlert {...tryItErrorMessage}/>}

        {tryItValueSet && <TurasTable
          headers={[
            'Display',
            'Code',
            'System',
            'Text',
          ]}
          rows={tryItValueSet.jurisdiction?.map(e => {
            return {
              cells: [
                e.coding![0].display || '',
                e.coding![0].code || '',
                e.coding![0].system || '',
                e.text || '',
              ]
            }
          }) || []}
        />}


        {tryItData && <>
          <TurasCodePanel language="json5" copyButton>
            {JSON.stringify(tryItData, null, 2)}
          </TurasCodePanel>

          {/* Data hint for searching by CHI */}
          {tryItData['resourceType'] === 'OperationOutcome'
            && tryItData['issue']
            && tryItData['issue'].length
            && tryItData['issue'][0]['code'] === 'not-found'
            && <div className="mt-3"><InfoAlert title="Not seeing any data?">
              Use the <a href={`/sandbox/data`}>Manage Data</a> page to generate some.
            </InfoAlert></div>}

          {/* Data hint for searching by demographics */}
          {tryItData['total'] === 0 && <div className="mt-3"><InfoAlert title="Not seeing any data?">
            Use the <a href={`/sandbox/data`}>Manage Data</a> page to generate some.
          </InfoAlert></div>}
        </>}
      </>}

    </TurasSection>

  </SandboxBaseScreen>;
}
