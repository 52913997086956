import React from "react";

export function TurasAccordionCard(props: React.PropsWithChildren<{
  title: string;
  dataKey: string;
}>): JSX.Element {
  return <div className="card">
    <h2 className="card-header" id={`${props.dataKey}-header`}>
      <div className="mb-0">
      <button type="button"
              className="btn collapsed"
              data-toggle="collapse" data-target={`#${props.dataKey}-collapse`}
              aria-controls={`${props.dataKey}-collapse`} aria-expanded="false">
        {props.title}
      </button>
      </div>
    </h2>
    <div id={`${props.dataKey}-collapse`}
         className="collapse"
         aria-labelledby={`${props.dataKey}-header`} data-parent={`#${props.dataKey}-header`}>
      <div className="card-body">
        {props.children}
      </div>
    </div>
  </div>;
}

export function TurasAccordion(props: React.PropsWithChildren<{}>): JSX.Element {
  return <div className="accordion">
    {props.children}
  </div>;
}
