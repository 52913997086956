import React from "react";
import {urlIsExternal} from "../helpers";


/**
 * Use this hyperlink instead of a simple <a ..> tag when you need to dynamically generate hyperlinks after the page
 * loads.
 *
 * This is required because, at page load time (and via jquery) there is a script to scan the DOM and apply styling
 * to hyperlinks - e.g. to add the logo for external links
 */
export default function StyledHyperlink(props: React.PropsWithChildren<{
  text?: string,
  href?: string,
  className?: string;
  /**
   * If noExternal=true, the component will not add the external link icon
   */
  noExternal?: boolean;
}>): JSX.Element {
  const isExternal = props.href && urlIsExternal(props.href);

  return <a href={props.href || undefined}
            target={isExternal ? '_blank' : undefined}
            rel={isExternal ? 'noreferrer' : undefined}
            className={props.className}
  >{props.text}{props.children}{isExternal && !props.noExternal && <>{' '}<i
    className="fa fa-external-link external-link"/></>}</a>;
}
