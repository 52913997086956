import React, {ReactNode, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {environment} from "../config";

import MainNavbar, {NavbarItem} from "../components/MainNavbar";
import GlobalNav from "../components/GlobalNav";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import {ErrorAlert, WarningAlert} from "../components/AlertBox";
import {LargeLoadingSpinner} from "../components/LoadingSpinner";
import {TurasSection} from "../components/TurasSection";
import {TurasPageWarningBanner} from "../components/TurasPageBanner";
import {BreadcrumbItem} from "../components/Breadcrumbs";
import {CookieConsentModal} from "../components/modals/Cookie";

import {getBreadcrumbs} from "../components/BreadcrumbItems";
import {defaultNavMenuItems} from "../components/NavbarItems";
import {initGoogleAnalytics} from "./ScreenHelpers";

export type ErrorMessageProps = {
  title?: string;
  text: string;
}

export type BaseScreenProps = React.PropsWithChildren<{
  pageTitle?: string,
  pageSubtitle?: string,
  errorMessage?: ErrorMessageProps,
  warningMessage?: string,
  warningBannerMessage?: string | ReactNode;
  breadcrumbs?: BreadcrumbItem[],
  isLoaded?: boolean,
  navbarItems?: NavbarItem[],
}>

export default function BaseScreen(props: BaseScreenProps) {

  const location = useLocation();

  useEffect(() => {

    let rv: any = undefined;

    if (!environment.googleAnalytics)
      return;

    // @ts-ignore
    if (!window.gtag)
      rv = initGoogleAnalytics();

    // @ts-ignore
    if (window.gtag) window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });

    return rv;
  }, [location]);

  function buildScreen(children?: ReactNode | undefined): JSX.Element {
    return <div>
      <div className="wrapper">
        {props.warningBannerMessage && <TurasPageWarningBanner>{props.warningBannerMessage}</TurasPageWarningBanner>}
        <GlobalNav/>
        <MainNavbar navbarItems={props.navbarItems || defaultNavMenuItems}/>
        {(props.pageTitle) &&
          <PageHeader title={props.pageTitle}
                      subtitle={props.pageSubtitle}
                      breadcrumbs={props.breadcrumbs || getBreadcrumbs(location.pathname)}/>}

        <div id="maincontent">
          {(props.errorMessage || props.warningMessage) && <TurasSection>
            {props.errorMessage && <ErrorAlert title={props.errorMessage.title} text={props.errorMessage.text}/>}
            {props.warningMessage && <WarningAlert text={props.warningMessage}/>}
          </TurasSection>}

          {children}
        </div>
      </div>
      <Footer/>
      <CookieConsentModal siteName="Developer Portal"
                          cookiePolicyUrl="/legal/cookies"/>
    </div>;
  }

  // TODO Skip to Content

  // TODO: handle case where user is able to navigate to here, but isn't logged in or accepted ToS

  // LoadingSpinner
  // NB: isLoaded may be undefined - implies loaded=true
  if (props.isLoaded === false) {
    return buildScreen(<TurasSection>
      <LargeLoadingSpinner/>
    </TurasSection>);
  }

  return buildScreen(props.children);
}
