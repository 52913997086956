import axios, {AxiosResponse} from "axios";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from "@azure/msal-browser";

import {localStorageKeys} from "../constants";
import {environment} from "../config";
import {DeveloperAccount, TeamCognitoSettings} from "../models";
import AdminAPI from "./AdminAPI";

const lsCurrentUserKey = localStorageKeys.currentUser;


export function storeCurrentUser(user: DeveloperAccount | undefined) {
  if (!user) {
    window.localStorage.removeItem(lsCurrentUserKey);
    return;
  }

  window.localStorage.setItem(lsCurrentUserKey, JSON.stringify(user));
}

export function getCurrentUser(): DeveloperAccount | undefined {
  const userString = window.localStorage.getItem(lsCurrentUserKey);

  if (!userString || userString.length === 0) return undefined;

  try {
    const user = JSON.parse(userString) as DeveloperAccount;
    return user;
  } catch (e) {
    console.warn(`Invalid user data in ${lsCurrentUserKey}`, e);
    return undefined
  }
}

export function doLoginRedirect(instance: IPublicClientApplication) {
  instance.loginRedirect({scopes: environment.adminApi.msalConfig.scopes})
    .catch(e => {
      console.error(e);
    });
}

export async function doPostLogin(msal: PublicClientApplication) {
  const adminApi = AdminAPI.fromMsalInstance(msal);
  await adminApi.doPostLogin();
}

export async function doLogout(msal: IPublicClientApplication) {
  await msal.logoutRedirect({});
  window.localStorage.clear();
}

export function handleTryItCallback(cognitoSettings: TeamCognitoSettings, code: string, callbackUrl: string): Promise<any> {
  if (!cognitoSettings.settings)
    throw new Error(`Team does not have Cognito settings`);

  const url = `${cognitoSettings.settings.auth_base_url}/token`;
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const data = new URLSearchParams();
  data.append('grant_type', 'authorization_code');
  data.append('client_id', cognitoSettings.settings.web_client_id);
  data.append('code', code);
  data.append('scope', 'openid+profile+email');
  data.append('redirect_uri', callbackUrl);

  return axios.post(url, data, {headers});
}

export function doLogin_ClientCredentials(cognitoSettings: TeamCognitoSettings): Promise<AxiosResponse> {
  const settings = cognitoSettings.settings!;

  const url = `${settings.auth_base_url}/oauth2/token`;
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const data = new URLSearchParams();
  data.append('grant_type', 'client_credentials');
  data.append('client_id', settings.b2b_client_id);
  data.append('client_secret', settings.b2b_client_secret);
  data.append('scope', 'fhir/*:read');

  return axios.post(url, data, {headers});
}

/**
 * Returns MSAL token info, either via a cache lookup or silent request. If these fail,
 * a login redirect is issued and this method returns 'undefined'
 * @param msalInstance
 * @param accountInfo
 */
export async function getMsalTokenData(msalInstance: IPublicClientApplication, accountInfo: AccountInfo): Promise<AuthenticationResult | undefined> {
  try {
    return await getMsalTokenDataSilent(msalInstance, accountInfo);
  } catch (e) {

    if (!(e instanceof InteractionRequiredAuthError)) {
      console.error('Failed to authenticate silently', e);
      throw e;
    }
  }

  try {
    // fallback to interaction when silent call fails
    await getMsalTokenDataRedirect(msalInstance, accountInfo);
    return undefined;
  } catch (e2) {
    console.error('Failed to authenticate via redirect');
    throw e2;
  }
}

async function getMsalTokenDataSilent(msalInstance: IPublicClientApplication, accountInfo: AccountInfo): Promise<AuthenticationResult> {
  // Silently acquires an access token
  const silentRequest: SilentRequest = {
    scopes: environment.adminApi.msalConfig.scopes,
    account: accountInfo,
    forceRefresh: false,
  };

  // NB caching takes place inside acquireTokenSilent(..)
  // console.info('Attempting to authenticate silently');
  return await msalInstance.acquireTokenSilent(silentRequest);
}


async function getMsalTokenDataRedirect(msalInstance: IPublicClientApplication, accountInfo: AccountInfo): Promise<void> {
  const redirectRequest: RedirectRequest = {
    scopes: environment.adminApi.msalConfig.scopes,
    loginHint: accountInfo.username,
  }

  // console.info('Attempting to authenticate redirect');
  return await msalInstance.acquireTokenRedirect(redirectRequest);
}


async function getMsalTokenDataPopup(msalInstance: IPublicClientApplication, accountInfo: AccountInfo): Promise<AuthenticationResult> {
  const popupRequest: PopupRequest = {
    scopes: environment.adminApi.msalConfig.scopes,
    account: accountInfo,
  };

  // console.info('Attempting to authenticate via popup');
  return await msalInstance.acquireTokenPopup(popupRequest);
}

