import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";

export type NavbarItem = {
  id: string;
  title: string;
  url?: string;
  children?: NavbarItem[];
  disabled?: boolean;
}

function NavMenuDropdownMenuSeparator(): JSX.Element {
  return <div className="dropdown-divider"></div>;
}

function NavMenuDropdownMenuTitle(props: { item: NavbarItem }): JSX.Element {
  return <div id={`main-nav-item-${props.item.id}`}
              className="dropdown-item"
              style={{fontWeight: "bold", textSizeAdjust: '120%'}}>
    {props.item.title}
  </div>;
}

function NavMenuDropdownMenuItem(props: { item: NavbarItem }): JSX.Element {
  return <NavDropdown.Item id={`main-nav-item-${props.item.id}`}
                           disabled={props.item.disabled}
                           href={props.item.url || '#'}
                           className="nav-link">
    {props.item.title}
  </NavDropdown.Item>;
}

function NavMenuDropdownMenu(props: { item: NavbarItem }): JSX.Element {
  return <NavDropdown id="main-nav-dropdown"
                      disabled={props.item.disabled}
                      title={props.item.title}>

    {props.item.children?.map(child => {
        // is this a separator?
        if (child.title === '---')
          return <NavMenuDropdownMenuSeparator key={`main-nav-item-${child.id}`}/>

        if (!child.url)
          return <NavMenuDropdownMenuTitle
            item={child}
            key={`main-nav-item-${child.id}`}/>

        return <NavMenuDropdownMenuItem
          key={`main-nav-item-${child.id}`}
          item={child}/>
      }
    )}
  </NavDropdown>;
}

function NavMenuItem(props: { item: NavbarItem }): JSX.Element {
  // Is this a sub menu?
  if (props.item.children)
    return <NavMenuDropdownMenu item={props.item}
                                key={`main-nav-item-${props.item.id}`}/>;

  // Is this a top level item?
  return <Nav.Item id={`main-nav-item-${props.item.id}`}
                   key={`main-nav-item-${props.item.id}`}>
    <Nav.Link href={props.item.url || '#'}
              key={`main-nav-item-${props.item.id}-link`}
              disabled={props.item.disabled}>
      {props.item.title}
    </Nav.Link>
  </Nav.Item>;
}

export default function MainNavbar(props: {
  navbarItems?: NavbarItem[]
}) {

  return <Navbar expand="xl" id="main-nav" className="navbar-dark bg-dark">
    <Container>
      <span className="menu-text float-right">Menu</span>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="navbarSupportedContent">
        {props.navbarItems &&
          <Nav className="mr-auto">
            {props.navbarItems.map(item => <NavMenuItem item={item}
                                                        key={`main-nav-item-${item.id}`}/>)}
          </Nav>
        }
        <Nav.Item className="d-flex">
          <Nav.Link href="/sandbox-list"><i className="fa fa-exchange" aria-hidden="true"></i> Switch sandbox</Nav.Link>
        </Nav.Item>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}



