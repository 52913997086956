import React from "react";
import {TurasSection} from "../../components/TurasSection";
import BaseScreen from "../BaseScreen";
import LandingBlock from "../../components/LandingBlock";
import {Row} from "react-bootstrap";

export default function OverviewScreen(): JSX.Element {

  const pageTitle = "Data stores";
  const pageSubtitle = "These data stores are provide by the NDP to help you store structured and unstructured medical data.";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
  >

    <TurasSection>
      {/*<h2>Front end, UI and UX</h2>*/}

      <Row>
        <LandingBlock title="FHIR clinical data repository"
                      url="/data-stores/fhir">
          <p>Store structured health data in the HL7 FHIR format</p>
        </LandingBlock>

        <LandingBlock title="Media Store"
                      url="/data-stores/media-store">
          <p>Store unstructured health data (e.g. images, documents, binary files)</p>
        </LandingBlock>

        <LandingBlock title="openEHR clinical data repository"
                      url="/data-stores/openehr">
          <p>Store structured health data in the openEHR format</p>
        </LandingBlock>

      </Row>
    </TurasSection>
  </BaseScreen>
}
