import React, {useEffect, useState} from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";

import * as Auth from "../service/Auth";
import {doLoginRedirect, getCurrentUser, getMsalTokenData} from "../service/Auth";
import {getHumanName} from "../helpers";

export function LoginButton() {
  const {instance} = useMsal();

  return (
    <a className="navbar-brand" href="#" onClick={() => doLoginRedirect(instance)}><p>Sign in</p></a>
  )
}

export function ContentLoginButton() {
  const {instance} = useMsal();

  return (
    <a className="btn btn-primary" href="#" onClick={() => doLoginRedirect(instance)}>Sign in</a>
  )
}

function LogoutButton() {
  const {instance} = useMsal();

  return <button className="btn btn-primary btn-block"
                 type="button"
                 onClick={() => {
                   Auth.doLogout(instance)
                     .then(() => window.location.href = '/');
                 }}
                 role="button">Sign out</button>;
}


function UserIconAndDropdown() {
  const {instance, accounts} = useMsal();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);

  const [name, setName] = useState<string>();
  const [initials, setInitials] = useState<string>();
  const [email, setEmail] = useState<string>();

  function getUserInitials(name?: string): string {
    if (!name) return '??';

    // A bit naive, but just take the first char of every name element
    const tokens = name.split(' ');
    const initials = tokens.map(token => token.substring(0, 1)).join('');

    return initials;
  }

  useEffect(() => {
    if (!accounts || !accounts.length)
      return;

    getMsalTokenData(instance, accounts[0])
      .then((tokenData: any) => {

        const user = getCurrentUser();
        const name = getHumanName(user);
        setName(name);
        setInitials(getUserInitials(name));
        setEmail(user?.email)
      });

  }, []);

  const userDetailsModal = (
    <div className="dropdown-menu dropdown-menu-right dropdown-content show" style={{
      position: 'absolute',
      transform: 'translate3d(-331px, 33px, 0px)',
      top: '0px',
      left: '0px',
      willChange: 'transform'
    }}>
      <div className="dropdown-content">
        <div className="userInitial">
          <p>{initials}</p>
        </div>
        <div className="user-info">
          <p className="user-info-name">{name}</p>
          <p>{email}</p>
          {/* <p><a href="" className="p-0">View or edit profile</a></p> */}
        </div>
        <hr className="mt-10"/>
        <LogoutButton/>
      </div>
    </div>
  )

  return (
    <div id="user-dropdown" className="dropdown show ">
      <a className="dropdown-toggle header-text" data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false" onClick={() => setShowUserModal(!showUserModal)}>
        <div className="userInitial">
          <p>{initials}</p>
        </div>
      </a>
      {showUserModal && userDetailsModal}
    </div>)

}

export function NavbarUserInfo() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ul className="list-unstyled m-0">
      {isAuthenticated
        ? <UserIconAndDropdown/>
        : <LoginButton/>}
    </ul>
  )
}
