import React from "react";
import TurasSideNav, {TurasSideNavItemProps} from "../../components/TurasSideNav";

export type LegalSideNavProps = {
  additionalItemsOn?: string;
  additionalItems?: TurasSideNavItemProps[];
}

export function LegalSideNav(props: LegalSideNavProps): JSX.Element {
  const defaultItems: TurasSideNavItemProps[] = [
    {label: "Terms and conditions", url: "/legal/terms"},
    {label: "Cookie policy", url: "/legal/cookies"},
    {
      label: "Accessibility",
      url: "https://www.nes.scot.nhs.uk/legal-and-site-information/accessibility/"
    },
    {
      label: "Privacy",
      url: "https://www.nes.scot.nhs.uk/legal-and-site-information/privacy/"
    },
  ];

  return <TurasSideNav
    title="Legal and site information"
    items={defaultItems}
    additionalItemsOn={props.additionalItemsOn}
    additionalItems={props.additionalItems}
  />;
}
