import React, {useEffect, useState} from 'react';
import {useLocalStorage} from "usehooks-ts";
import {Row} from "react-bootstrap";

import {useMsal} from "@azure/msal-react";

import {Team, TeamFhirSettings} from "../../models";
import AdminAPI from "../../service/AdminAPI";

import {environment} from "../../config";
import {localStorageKeys} from "../../constants";

import * as ScreenHelpers from '../ScreenHelpers'
import {ErrorMessageProps} from '../BaseScreen';
import LandingBlock from "../../components/LandingBlock";
import {TurasSection} from "../../components/TurasSection";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "./SandboxBaseScreen";

function SandboxOverviewScreen() {
  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);
  const [teamFhirSettings, setTeamFhirSettings] = useState<TeamFhirSettings | undefined>();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      setTeamFhirSettings,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  useEffect(() => {
    getAndDisplaySandbox();
  }, [sandboxId]);

  const pageTitle = "Sandbox overview";
  const pageSubtitle = "";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >

    <TurasSection>

      <h2>Sandbox actions</h2>
      <Row>
        <LandingBlock title="Manage sandbox data"
                      url="/sandbox/data">
          Generate some data for your sandbox.
        </LandingBlock>

        <LandingBlock title="Quick access"
                      url="/sandbox/quick-access">
          If you know what you're looking for, most common URLs, access keys, tokens, etc are here.
        </LandingBlock>

        <LandingBlock title="Authentication details"
                      url="/sandbox/auth">
          Get your dummy access details and explore how to request tokens
        </LandingBlock>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Use your sandbox</h2>
      <p>These services provide an interactive demo, which can be used within your browser. All demo data is stored
        within your sandbox.</p>

      <h3>Services</h3>
      <Row>
        {environment.empiApi
          ? <LandingBlock title="EMPI"
                          url="/apis/empi/try">
            <p>The Electronic Master Patient Index (EMPI) is designed to be the authoritative source for patient
              demographics.</p>
          </LandingBlock>
          : <LandingBlock title="EMPI">
            <p>The Electronic Master Patient Index (EMPI) is designed to be the authoritative source for patient
              demographics.</p>
            <p><strong>Coming soon</strong><br/>
              An interactive demo is being developed, but not ready yet.</p>
          </LandingBlock>}

        {environment.empiApi
          ? <LandingBlock title="Vaccinations API"
                          url="/apis/ncds/try">
            <p>The National Clinical Data Store (NCDS) is the authoritative clinical record of vaccinations
              nationally.</p>

          </LandingBlock>
          : <LandingBlock title="Vaccinations API">
            <p>The National Clinical Data Store (NCDS) is the authoritative clinical record of vaccinations
              nationally.</p>
            <p><strong>Coming soon</strong><br/>
              An interactive demo is being developed, but not ready yet.</p>
          </LandingBlock>}

      </Row>

      <h3>Data stores</h3>
      <Row>
        {teamFhirSettings && <LandingBlock
          title="FHIR clinical data repository"
          url="/data-stores/fhir/try">
          <p>Access your private FHIR server</p>
        </LandingBlock>}

        {teamFhirSettings && <LandingBlock
          title="Media Store"
          url="/data-stores/media-store/try">
          <p>Access your private Media Store</p>
        </LandingBlock>}

        <LandingBlock title="openEHR clinical data repository">
          <p>Access to the sandbox openEHR server</p>
          <p><strong>Coming soon</strong><br/>
            An interactive demo is being developed, but not ready yet.</p>
        </LandingBlock>
      </Row>
    </TurasSection>

  </SandboxBaseScreen>

}

export default SandboxOverviewScreen;
