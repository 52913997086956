import axios, {AxiosRequestConfig} from "axios";
import {IMsalContext} from "@azure/msal-react";

import {
  CreateTeamRequest,
  DeveloperAccount,
  FhirDataSummaryResponse,
  GenerateFhirDataRequest,
  IdpUserRepresentation,
  PrePreparedOauthToken,
  Team,
  TeamApiKey,
  TeamCognitoSettings,
  TeamFhirSettings
} from "../models";
import {environment} from "../config";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {TeamApprovalStatus} from "../types";
import * as Auth from './Auth';
import {getMsalTokenData} from './Auth';
import {ApiResponseError} from "../errors";
import {FhirR4ResourceNames} from "../fhirHelpers";

export type UserInfoEndpointResponse = {
  idp?: IdpUserRepresentation,
  sandbox: DeveloperAccount
}


export default class AdminAPI {
  private readonly msalInstance: IPublicClientApplication;
  private readonly msalAccounts: AccountInfo[];

  static fromMsal(msal: IMsalContext): AdminAPI {
    return new AdminAPI(msal.instance, msal.accounts);
  }

  static fromMsalInstance(msalInstance: IPublicClientApplication) {
    return new AdminAPI(msalInstance, msalInstance.getAllAccounts());
  }

  private constructor(msalInstance: IPublicClientApplication, accounts: AccountInfo[]) {
    this.msalInstance = msalInstance;
    this.msalAccounts = accounts;
  }

  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = environment.adminApi.url;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // @ts-ignore
    if (environment.adminApi.corsAnywhere?.enabled) {
      // build the path
      // @ts-ignore
      return `${environment.adminApi.corsAnywhere.prefix}${urlRoot}/${path}`
    } else
      // build path
      return `${urlRoot}/${path}`
  }

  private async getRequestConfig(): Promise<AxiosRequestConfig> {
    const tokenData = await getMsalTokenData(this.msalInstance, this.msalAccounts[0]);

    const headers: any = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenData?.idToken}`,
    }

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async doPostLogin(): Promise<void> {
    const url = this.getApiUrl(environment.adminApi.endpoints.user.postLogin);
    const config = await this.getRequestConfig();

    try {
      const response = await axios.post(url, undefined, config);
      if (response.status !== 200)
        throw ApiResponseError.fromResponse(response);

    } catch (e) {
      console.error(e);
      throw e;
    }

    await this.getUserInfo();
  }

  async getUserInfo(): Promise<UserInfoEndpointResponse | undefined> {
    const url = this.getApiUrl(environment.adminApi.endpoints.user.userinfo);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status === 404)
          return undefined;

        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const userData = response.data as UserInfoEndpointResponse;
        Auth.storeCurrentUser(userData.sandbox);
        return userData;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getTeamsList(scope?: 'mine' | 'all', status?: TeamApprovalStatus): Promise<Team[]> {
    const urlBase = this.getApiUrl(`/team/`);
    const url = new URL(urlBase);
    if (scope) url.searchParams.append('scope', scope);
    if (status) url.searchParams.append('status', status);
    const config = await this.getRequestConfig();

    return axios.get(url.href, config)
      .then(response => {
        if (response.headers['content-type'] === 'text/plain')
          // This is probably a cors-anywhere page
          return [];

        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as Team[];
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getTeam(sandboxId: string): Promise<Team | undefined> {
    const url = this.getApiUrl(`/team/${sandboxId}/`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status === 404)
          return undefined;

        // HACK: The API will return 403 if the team is not found
        if (response.status === 403)
          return undefined;

        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as Team;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getApiKeys(sandboxId: string): Promise<TeamApiKey[]> {
    const url = this.getApiUrl(`/team/${sandboxId}/api-keys`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as TeamApiKey[];
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getFhirSettings(sandboxId: string): Promise<TeamFhirSettings> {
    const url = this.getApiUrl(`/team/${sandboxId}/fhir/settings`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as TeamFhirSettings;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getCognitoSettings(sandboxId: string): Promise<TeamCognitoSettings> {
    const url = this.getApiUrl(`/team/${sandboxId}/cognito/settings`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as TeamCognitoSettings;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getCognitoAccessToken(sandboxId: string): Promise<PrePreparedOauthToken> {
    const url = this.getApiUrl(`/team/${sandboxId}/cognito/token`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as PrePreparedOauthToken;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async getFhirDataSummary(sandboxId: string): Promise<FhirDataSummaryResponse> {
    const url = this.getApiUrl(`/team/${sandboxId}/fhir/summary`);
    const config = await this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as FhirDataSummaryResponse;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async populateRandomFhirData(sandboxId: string, request: GenerateFhirDataRequest): Promise<FhirDataSummaryResponse> {
    const url = this.getApiUrl(`/team/${sandboxId}/fhir/generate`);
    const config = await this.getRequestConfig();

    return axios.post(url, request, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as FhirDataSummaryResponse;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async createTeam(teamName: string, teamDescription: string): Promise<Team> {
    const url = this.getApiUrl(`/team`);
    const data = {
      team_name: teamName,
      team_description: teamDescription,
    } as CreateTeamRequest;
    const config = await this.getRequestConfig();

    return axios.post(url, data, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as Team;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async acceptTermsOfService(): Promise<void> {
    const currentUser = Auth.getCurrentUser();

    if (!currentUser) return;

    const url = this.getApiUrl(environment.adminApi.endpoints.user.acceptTerms);
    const config = await this.getRequestConfig();

    try {
      const data = {
        'action': 'accept-terms'
      };
      const response = await axios.post(url, data, config);
      if (response.status !== 200)
        throw ApiResponseError.fromResponse(response);

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async deleteFhirResourceType(sandboxId: string, fhirType: string): Promise<FhirDataSummaryResponse> {

    if (!fhirType || !FhirR4ResourceNames.includes(fhirType))
      throw new Error(`Unrecognised FHIR type: ${fhirType}`);

    const url = this.getApiUrl(`/team/${sandboxId}/fhir/resources/${fhirType}`);
    const config = await this.getRequestConfig();

    return axios.delete(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const r = response.data as FhirDataSummaryResponse;
        return r;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  async addCallbackUrl(sandboxId: string, urlToAdd: string) {
    const url = this.getApiUrl(`/team/${sandboxId}/cognito/callbackurl`);
    const config = await this.getRequestConfig();
    const data = {
      url: urlToAdd,
    }

    await axios.post(url, data, config);
  }

  async deleteCallbackUrl(sandboxId: string, urlToDelete: string) {
    const url = this.getApiUrl(`/team/${sandboxId}/cognito/callbackurl`);
    const config = await this.getRequestConfig();
    config.data = {url: urlToDelete}

    await axios.delete(url, config);
  }
}
