import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";
import LandingBlock from "../../../components/LandingBlock";
import {environment} from "../../../config";

import ProdFlowDiagram from "../../../img/docs/ncds/simplified-flow-prod.png";
import SandboxFlowDiagram from "../../../img/docs/ncds/simplified-flow-sandbox.png";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "Vaccinations API (NCDS)";
  const pageSubtitle = "The National Clinical Data Store (NCDS) is the authoritative clinical record of vaccinations nationally";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>
          <h2>A quick introduction</h2>

          <p><strong>National Clinical Data Store (NCDS)</strong> is the historical name for the <strong>Vaccinations
            API</strong> provided by the National Digital Platform. Due to this the two terms are often used
            interchangeably.</p>

          <p>The Vaccination API provides a standards-based (FHIR) interface to the NDP's Clinical Data Repository in
            Scotland. Vaccination data is recorded through the Vaccination Management Tool (VMT) and through GP IT
            systems. This API is the most accurate source of vaccination data for a wide range of vaccinations and
            clinical settings.</p>

          <p>Developers can integrate with this service to provide patient vaccination functionality within their
            software applications. This may be to display a list of vaccinations for a patient in an application or, if
            an application captures structured vaccination information, to maintain a patient’s vaccination record in
            the CDR.</p>

          <h2>NCDS in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="stable"
            productionServiceStatusDescription="The Vaccinations API has been stable in production since 2020. We continue to maintain the service and add new features."
            sandboxServiceMaturity="mvp"
            sandboxServiceStatusDescription="A mock version of the Vaccination API is in development and we hope to make this available in 2023."
            sandboxLimitations={[
              "No Sandbox version of NCDS is available at present.",
              "The Sandbox implementation may function differently from production in subtle ways."
            ]}
          />

          <h3>In production</h3>
          <p>This is an oversimplified version of the data flow diagram, to give you an idea of where the service sits
            in a health application</p>
          <img src={ProdFlowDiagram} className="border p-3 bg-white"/>

          <h3>In the Sandbox</h3>
          <p>In the Sandbox environment we use your sandbox's FHIR server as the source for vaccination data. This
            allows you to control the data the Vaccination API will provide to your application, without worrying about
            the governance concerns that come from connecting to datas sources.</p>
          <img src={SandboxFlowDiagram} className="border p-3 bg-white"/>
        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        {environment.vaccinationsApi && <>

          <LandingBlock title="Interactive demo"
                        url="ncds/try">
            <p>Explore the API in a sandbox environment from within your browser</p>
          </LandingBlock>

          <LandingBlock title="Getting started guide"
                        url="/resources/getting-started/ncds">
            <p>Follow the step-by-step guide to call the API from Postman</p>
          </LandingBlock>

          <LandingBlock title="Manage data"
                        url="/sandbox/data">
            <p>View and generate test data for your sandbox environment</p>
          </LandingBlock>
        </>}

        <LandingBlock title="OpenAPI specification"
                      url="https://swagger.products.ndp.scot/">
          <p>The OpenAPI specification is a standardised method of documenting APIs</p>
        </LandingBlock>

        {!environment.vaccinationsApi &&
          <>
            <LandingBlock title="Interactive demo">
              <p><strong>Coming soon</strong><br/>
                An interactive demo is being developed, but not ready yet.</p>
            </LandingBlock>
          </>
        }

      </Row>

    </TurasSection>

  </BaseScreen>;
}
