import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {useLocalStorage} from "usehooks-ts";

import {useMsal} from "@azure/msal-react";

import AdminAPI from "../../../service/AdminAPI";
import {getFriendlyErrorMessage, sortResourceTypesByName} from "../../../helpers";
import {FhirDataSummaryResponse, GenerateFhirDataRequest, Team} from "../../../models";
import * as ScreenHelpers from "../../ScreenHelpers";
import {localStorageKeys} from "../../../constants";

import {ErrorMessageProps} from "../../BaseScreen";
import SandboxBaseScreen, {
  SandboxLoadingScreen,
  SandboxNotFoundScreen,
  SandboxNotSelectedScreen
} from "../SandboxBaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {ErrorAlert} from "../../../components/AlertBox";

export default function GenerateDatasetsScreen(): JSX.Element {

  const [sandboxId] = useLocalStorage<string | undefined>(localStorageKeys.sandboxId, undefined);

  const msal = useMsal();
  const api = AdminAPI.fromMsal(msal);

  const [team, setTeam] = useState<Team | undefined>();
  const [teamNotFound, setTeamNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [generatingFhirData, setGeneratingFhirData] = useState<boolean>(false);
  const [generatedDataSummary, setGeneratedDataSummary] = useState<FhirDataSummaryResponse | undefined>();
  const [generateErrorMessage, setGenerateErrorMessage] = useState<string | undefined>(undefined);

  async function getAndDisplaySandbox(): Promise<Team | undefined> {
    if (!sandboxId) return;
    return await ScreenHelpers.loadTeam(sandboxId, api,
      setTeam,
      setTeamNotFound,
      undefined,
      undefined,
      undefined,
      setErrorMessage,
      setIsLoaded);
  }

  type DataGenerationItem = {
    fhirType: string;
    count: number;
  }

  async function handleGenerateData(config: DataGenerationItem[]) {
    setGenerateErrorMessage(undefined);
    setGeneratedDataSummary(undefined);

    if (!team) return;
    console.debug(`Generating FHIR data`);
    setGeneratingFhirData(true);

    // after 500ms scroll to results
    setTimeout(() => {
      const resultsHeader = document.getElementById('results-header');
      resultsHeader?.scrollIntoView({behavior: 'smooth'})
    }, 500);

    const counts: GenerateFhirDataRequest = {
      resource_types: config.map(c => ({resource_name: c.fhirType, count: c.count}))
    };

    try {
      const fd = await api.populateRandomFhirData(team.id, counts);
      setGeneratedDataSummary(fd);

    } catch (e) {
      setGenerateErrorMessage(getFriendlyErrorMessage(e));
    }
    setGeneratingFhirData(false);
  }

  function GenerateDataBox(props: React.PropsWithChildren<{
    title: string,
    buttonLabel: string,
    generateConfig: {
      fhirItems?: DataGenerationItem[]
    }
  }>): JSX.Element {
    return <Card className="col-md-12">
      <Card.Body>
        <Row>
          <Col md={7} sm={12}>
            <h3 className="card-title mt-0">{props.title}</h3>
            {props.children}

            {props.generateConfig.fhirItems && <p>FHIR data items:{' '}
              {props.generateConfig.fhirItems.map((i, n) => <>
                {n > 0 && <span className="list-separator"> | </span>}
                {i.fhirType}
              </>)}
            </p>}

          </Col>
          {/* lager screen equivalent of the button */}
          <Col md={5} className="border-left text-md-center d-none d-md-block">
            <div className="vertical-center">
              <Button type="button"
                      variant="success"
                      className="text-wrap mr-2"
                      disabled={generatingFhirData}
                      onClick={() => handleGenerateData(props.generateConfig.fhirItems || [])}><i
                className="fa fa-plus mr-1"></i> {props.buttonLabel}</Button>
            </div>
          </Col>
          {/* "small" equivalent of the button */}
          <Col sm={12} className="border-left text-md-center d-xs-block d-sm-block d-md-none">
            <Button type="button"
                    variant="success"
                    className="btn-block"
                    disabled={generatingFhirData}
                    onClick={() => handleGenerateData(props.generateConfig.fhirItems || [])}>
              {props.buttonLabel}</Button>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  }

  useEffect(() => {
    getAndDisplaySandbox();
    // .then(() => loadFhirData());
  }, [sandboxId]);

  const pageTitle = "Generate datasets for your sandbox";
  const pageSubtitle = "Datasets are groups of data, for example a set of related patients, conditions, drugs, and practitioners which represent a clinical picture";

  if (!sandboxId)
    return <SandboxNotSelectedScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle}/>;
  if (!isLoaded)
    return <SandboxLoadingScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;
  if (teamNotFound || !team)
    return <SandboxNotFoundScreen pageTitle={pageTitle} pageSubtitle={pageSubtitle} errorMessage={errorMessage}/>;

  return <SandboxBaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    team={team}
    errorMessage={errorMessage}
    isLoaded={true}
  >
    <TurasSection>

      <h2>Generate data sets</h2>

      <p>For these datasets, if applicable, the individual FHIR resources will be linked together. e.g. If you create
        a Vaccination record, the Patient, Immunization, Drug, Location, etc will be linked.</p>

      <Row>
        <GenerateDataBox title="Vaccinations API (NCDS) background data"
                         buttonLabel="Add resources for NCDS"
                         generateConfig={{
                           fhirItems: [
                             {fhirType: 'Condition', count: 5},
                             {fhirType: 'MedicationKnowledge', count: 20},
                           ]
                         }}>
          <p>Generate five FHIR Conditions and five FHIR MedicationKnowledge. These resources are used by NCDS to
            represent supported immunization types.</p>
        </GenerateDataBox>

        <GenerateDataBox title="Vaccinations API (NCDS) patients"
                         buttonLabel="Add 5 patients for NCDS"
                         generateConfig={{
                           fhirItems: [
                             {fhirType: 'Patient', count: 5},
                             {fhirType: 'Immunization', count: 20},
                             {fhirType: 'Practitioner', count: 3},
                             {fhirType: 'Organization', count: 1},
                             {fhirType: 'Location', count: 3},
                           ]
                         }}>
          <p>Generate the FHIR resources required to represent five Patients with their associated immunization record.
            These records will include all associated data items such as Location and Practitioner.</p>
        </GenerateDataBox>

        <GenerateDataBox title="Patients with Allergy/Intolerance"
                         buttonLabel="Add 5 Patients with Allergy/Intolerances"
                         generateConfig={{
                           fhirItems: [
                             {fhirType: 'Patient', count: 5},
                             {fhirType: 'MedicationKnowledge', count: 2},
                             {fhirType: 'AllergyIntolerance', count: 10},
                           ]
                         }}>
          <p>Generate five FHIR Patients with Allergy/Intolerances.</p>
        </GenerateDataBox>

        <GenerateDataBox title="Patients with Conditions"
                         buttonLabel="Add 5 Patients with Conditions"
                         generateConfig={{
                           fhirItems: [
                             {fhirType: 'Patient', count: 5},
                             {fhirType: 'Condition', count: 20},
                           ]
                         }}>
          <p>Generate five FHIR Patients with various Conditions.</p>
        </GenerateDataBox>

        <GenerateDataBox title="Medication Knowledge"
                         buttonLabel="Add 5 Medication Knowledges"
                         generateConfig={{
                           fhirItems: [
                             {fhirType: 'MedicationKnowledge', count: 5},
                           ]
                         }}>
          <p>Generate five distinct FHIR Medication Knowledge resources.</p>
        </GenerateDataBox>
      </Row>

      {(generatingFhirData || generatedDataSummary) && <>
        <h2 id="results-header">Data generated</h2>

        {generatingFhirData && <Spinner animation="border" variant="default">
          <span className="invisible">Loading...</span>
        </Spinner>}

        {generateErrorMessage && <ErrorAlert title="Error"
                                             text={generateErrorMessage}/>}

        {generatedDataSummary && <>
          <p>You can query the data on the <a href="/data-stores/fhir/try">interactive FHIR demo</a> screen</p>
          <table className="table">
            <thead>
            <tr>
              <th>Resource</th>
              <th>Count</th>
            </tr>
            </thead>

            <tbody>
            {generatedDataSummary.resource_types
              .sort(sortResourceTypesByName)
              .map(i =>
                <tr key={`row-${i}`}>
                  <td>{i.resource_name}</td>
                  <td>{generatingFhirData ? '...' : i.count}</td>
                </tr>)}
            </tbody>
          </table>
        </>}

      </>}


    </TurasSection>
  </SandboxBaseScreen>;

}
