import React from "react";
import {Card, Col, Form, InputGroup, Row} from "react-bootstrap";

import {environment} from "../../../config";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {GenericAlert, InfoAlert} from "../../../components/AlertBox";
import ClipboardButton from "../../../components/forms/ClipboardButton";
import TurasSideNav from "../../../components/TurasSideNav";
import TurasCodePanel from "../../../components/TurasCodePanel";
import LandingBlock from "../../../components/LandingBlock";

import SandboxFlowDiagram from "../../../img/docs/empi/simplified-flow-sandbox.png";


export default function EMPIGuide(): JSX.Element {

  return <BaseScreen
    pageTitle="Getting started: Electronic Master Patient Index (EMPI)"
    pageSubtitle="Learn how to call the EMPI service so you can use patient demographics in your application">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Generate mock data for your sandbox
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Query for a patient by some known demographics
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Request a patient's details if you know their CHI
            </li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
          </ul>
          <p>Before starting, it might help to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="auth">authentication guide</a></li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>

      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {label: "Getting started", scrollToId: "step-getting-started"},
              {label: "Step 1: Generate some mock patients", scrollToId: "step-1"},
              {label: "Step 2: Authentication", scrollToId: "step-2"},
              {label: "Step 3: Use the EMPI API to search for patients by demographics", scrollToId: "step-3"},
              {label: "Step 4: Use the EMPI API to request a patient by CHI", scrollToId: "step-4"},
            ]}/>
        </Col>

        <Col md={9} sm={12}>

          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>
            <p>The Electronic Master Patient Index (EMPI) service provides a proxy to several sources of patient
              demographics, presenting them as a <a href="https://www.hl7.org/fhir/index.html"
                                                    target="_blank">FHIR</a> API.</p>

            <p>In the Sandbox environment we use your sandbox's FHIR server as the source for patient demographics. This
              allows you to control the data EMPI will provide to your application, without worrying about the
              governance concerns that come from connecting to real demographics providers.</p>
            <img src={SandboxFlowDiagram} className="border p-3 bg-white"/>

          </div>

          <div id="step-1" className="guide-step">
            <h2>Step 1: Generate some mock patients</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Manage sandbox data"
                            url="/sandbox/data">
                Use this link to manage or generate data in your sandbox
              </LandingBlock>
              <LandingBlock title="EMPI interactive demo"
                            url="/apis/empi/try">
                Try the EMPI service from within your browser
              </LandingBlock>
            </Row>

            <p>Open the <a href="/sandbox/data">Manage sandbox data</a> page. You'll see several options to generate
              test data for your application. Pick the <strong>Generate FHIR data</strong> option.</p>

            <p>On the Generate FHIR data page, scroll down to Patient. Select 5 from the dropdown list, and click Add.
              After a few seconds you should see the message "Generated 5 Patients", as well as some IDs. These are the
              IDs of the newly generated FHIR Patient resources.</p>

            <h3>See it in the browser</h3>
            <p>Open the <a href="/apis/empi/try">EMPI interactive demo</a>. You don't know any of the patients' CHIs
              yet, so try searching by demographics. Search
              for <strong>male</strong> or <strong>female</strong> patients. Scroll to the bottom of the screen and
              you'll see the HTTP request which was sent to the EMPI service and the response it has returned.</p>
            <p>Within the Results section you'll see the FHIR data which represents your patients. The random data
              generator only generates male or female patients, so querying both options should return all of your
              patients.</p>

            <GenericAlert alertBg="success" faIcon="fa-check-square-o">
              <p>Under one of the patients, find the <code>identifier</code> which has a
                system <code>https://phfapi.digitalhealthplatform.net/fhir/chinumber</code>.</p>
              <p>Take a note of this patient's CHI number, it'll be useful later.</p>
            </GenericAlert>

          </div>

          <div id="step-2" className="guide-step">
            <h2>Step 2: Authentication</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Getting started with authentication"
                            url="auth">
                Follow this guide to learn how to use the authentication mechanisms of the Sandbox.
              </LandingBlock>
              <LandingBlock title="Authentication interactive demo"
                            url="/sandbox/auth">
                Try the authentication mechanisms from within your browser
              </LandingBlock>
              <LandingBlock title="Quick access"
                            url="/sandbox/quick-access">
                Use this link to obtain an access token right away
              </LandingBlock>
            </Row>

            <p>The first step of connecting to EMPI is to obtain an authentication token from the authentication
              service. In the Sandbox environment this is AWS Cognito. </p>

            <InfoAlert>
              In the Sandbox environment and in production we use AWS Cognito to authenticate services. The
              configuration will probably differ between environments. This is true of all NDP services, although we
              are working to unify our auth mechanisms across the platform.
            </InfoAlert>

            <p>The boxes above provide three methods to obtain a token. If you're in a rush, you can use
              the <strong>Quick access</strong> screen to generate a token immediately. If you want to build a full
              end-to-end system you'll need to follow the <strong>Getting started with authentication</strong> guide.
            </p>

            <h3>Get a token</h3>

            <p>Using one of the methods above, obtain an <code>ID token</code> from AWS Cognito. This token is only
              valid for <strong>60 minutes</strong>, so if you take a break, or want to revisit this guide you'll need
              to obtain a fresh one.</p>

            <p>You'll also need the <code>API key</code> for your private sandbox, available from the Quick access
              screen.</p>

          </div>


          <div id="step-3" className="guide-step">
            <h2>Step 3: Use the EMPI API to search for patients by demographics</h2>

            <InfoAlert title="Postman">
              <p>Before completing this section you'll need to
                install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool.</p>
              <p>You can use any tool which allows you to prepare and send HTTP requests (e.g. cURL)</p>
            </InfoAlert>

            <Card>
              <div className="card-heading">
                <Card.Title>EMPI API details</Card.Title>
              </div>
              <Card.Body>
                <Form className="turasForm">

                  <Form.Group controlId="empiAccessDetails.rootUrl" className='form-group'>
                    <Form.Label>Root URL</Form.Label>
                    <InputGroup>
                      <Form.Control type="text"
                                    value={environment.empiApi?.url}
                                    readOnly={true}/>
                      <ClipboardButton value={environment.empiApi?.url || ''}/>
                    </InputGroup>
                  </Form.Group>

                  <p>If you've not done so already, obtain an <code>ID token</code> and your sandbox's <code>API
                    key</code> from the <a href="/sandbox/quick-access">Quick access</a> screen.</p>

                </Form>
              </Card.Body>
            </Card>

            <h3>Postman environment</h3>

            <ol>
              <li>Create an environment with a name that clearly identifies your sandbox.</li>
              <li>Add variables called
                <ul>
                  <li><code>EMPI_ROOT</code> - type=default</li>
                  <li><code>API_KEY</code> - type=<strong>secret</strong></li>
                  <li><code>COGNITO_ID_TOKEN</code> - type=<strong>secret</strong></li>
                </ul>
              </li>
              <li>Copy the values you gathered earlier into these variables
                <ul>
                  <li>EMPI root URL to <code>EMPI_ROOT</code> (under initial value and current value)</li>
                  <li>API Key to <code>API_KEY</code> (under initial value and current value)</li>
                  <li>ID Token to <code>COGNITO_ID_TOKEN</code> (under <strong>current value</strong> only)</li>
                </ul>
              </li>
            </ol>

            <p><strong>NB:</strong> your Token is only valid for <strong>1 hour</strong>, so you'll need to
              refresh it occasionally. All the other values are static.</p>

            <h3>Postman collection</h3>

            <ol>
              <li>Create a collection called <code>NDP EMPI service (Sandbox)</code></li>
              <li>Create a request called <code>EMPI - All female patients</code></li>
              <li>Choose request type <code>POST</code> and URL {' '}
                <code>{`{{EMPI_ROOT}}`}/Patient/$match</code></li>
              <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
                be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>In the request body, paste the code from below</li>
              <li>That's it! Hit <code>Send</code>. You should see a FHIR Bundle of Patient objects (or an empty
                bundle if there's no matching patients in your sandbox)
              </li>
            </ol>

            <h4>Request body</h4>
            <TurasCodePanel language="json5" copyButton>
              {`
{
  resourceType: "Parameters",
  parameter: [
    {
      name: "resource",
      resource: {
        resourceType: "Patient",
        gender: "female"
      }
    }
  ]
}
`.trim()}
            </TurasCodePanel>
          </div>

          <div id="step-4" className="guide-step">
            <h2>Step 4: Use the EMPI API to request a patient by CHI</h2>

            <InfoAlert title="Prerequisites">
              If you've not already completed Step 3, before starting Step 4 you'll need to
              <ol className="list-unstyled">
                <li><i className="fa fa-check text-primary"></i>{' '}
                  Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
                </li>
                <li><i className="fa fa-check text-primary"></i>{' '}
                  Configure your Postman environment (see Step 3)
                </li>
                <li><i className="fa fa-check text-primary"></i>{' '}
                  Create a Postman collection called "NDP EMPI service (Sandbox)"
                </li>
              </ol>
            </InfoAlert>

            <h3>Postman request</h3>

            <ol>
              <li>Add a variable to your Postman environment called
                <ul>
                  <li><code>SELECTED_CHI</code> - type=default</li>
                </ul>
              </li>
              <li>Paste the CHI you saved earlier into the current value field. If you didn't save a CHI from earlier,
                follow Step 1 to easily find a known CHI
              </li>
              <li>Create a new request called <code>EMPI - request patient by CHI</code></li>
              <li>Choose request type <code>GET</code> and URL {' '}
                <code>{`{{EMPI_ROOT}}`}/Patient/{`{{SELECTED_CHI}}`}</code></li>
              <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
                be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>That's it! Hit <code>Send</code>. You should see a FHIR Patient object (or an error response if
                there's
                no matching patient in your sandbox)
              </li>
            </ol>

          </div>
        </Col>

      </Row>


    </TurasSection>
  </BaseScreen>;
}
