import React from "react";
import {urlIsExternal} from "../helpers";

export type TurasSideNavItemProps = {
  label: string;
  url?: string;
  scrollToId?: string;
  items?: TurasSideNavItemProps[];
}
export type TurasSideNavProps = {
  title?: string;
  items: TurasSideNavItemProps[];
  additionalItemsOn?: string;
  additionalItems?: TurasSideNavItemProps[];
  scrollBehaviour?: ScrollBehavior;
  className?: string;
}
export default function TurasSideNav(props: TurasSideNavProps): JSX.Element {

  if (props.additionalItemsOn && props.additionalItems) {
    // find the item(s) with the matching label
    const filtered = props.items.filter(i => i.label === props.additionalItemsOn)
    // concat the additional items
    filtered.forEach(i => i.items = i.items?.concat(props.additionalItems!) || props.additionalItems)
  }


  function scrollTo(id: string) {
    const ref = document.getElementById(id);
    ref?.scrollIntoView({behavior: props.scrollBehaviour || 'smooth'});
  }

  function NavItem(props: TurasSideNavItemProps): JSX.Element {
    return <li>
      {props.url &&
        <a href={props.url} target={urlIsExternal(props.url) ? "_blank" : undefined}>{props.label}</a>}
      {props.scrollToId &&
        <a onClick={() => props.scrollToId && scrollTo(props.scrollToId)}>{props.label}</a>}
      {!props.url && !props.scrollToId &&
        <div>{props.label}</div>}

      {props.items && <ul>
        {props.items.map((item, n) => <NavItem {...item} key={`side-nav-item-${n}`}/>)}
      </ul>}
    </li>;
  }

  return <>
    <div id="leftnav" className={props.className}>
      {props.title && <div className="panel-heading">
        <a href="#">{props.title}</a>
      </div>}

      <div className="panel-body" style={{overflowY: 'auto', maxHeight: '80vh'}}>
        <ul>
          {props.items.map((item, n) => <NavItem {...item} key={`side-nav-item-${n}`}/>)}
        </ul>
      </div>
    </div>
  </>;

}
