import React from "react";
import {useLocation} from "react-router-dom";
import {Container} from "react-bootstrap";

import {Team} from "../../models";
import {getBreadcrumbs} from "../../components/BreadcrumbItems";

import BaseScreen, {BaseScreenProps} from "../BaseScreen";
import SandboxBanner from "../../components/SandboxBanner";

export type SandboxBaseScreenProps = {
  team?: Team,
  teamNotFound?: boolean,
  isLoaded: boolean,
} & BaseScreenProps;

export default function SandboxBaseScreen(props: SandboxBaseScreenProps): JSX.Element {

  const location = useLocation();

  const warningBannerMessage = <>This is a sandbox area: when using this area you are bound by the Sandbox <a
    href="/legal/terms">terms and conditions</a>.</>;

  // Team not found
  if (props.teamNotFound) {
    return <BaseScreen
      {...props}
      isLoaded={true}
      errorMessage={props.errorMessage || {
        title: "Sandbox not found",
        text: "The requested sandbox was not found. Go back to the home page and try again"
      }}
    />;
  }

  // Loading
  // NB: isLoaded may be undefined - implies loaded=true
  if (props.isLoaded === false) {
    return <BaseScreen
      {...props}
      isLoaded={false}
      warningBannerMessage={warningBannerMessage}
    />;
  }

  const breadcrumbs = props.breadcrumbs || getBreadcrumbs(location.pathname, props.team);

  return <BaseScreen
    {...props}
    breadcrumbs={breadcrumbs}
    warningBannerMessage={warningBannerMessage}
  >
    {props.team && <SandboxBanner sandbox={props.team}/>}
    {props.children}
  </BaseScreen>;

}

// NB: This is just a convenience wrapper for the SandboxBaseScreen, but it makes the code a lot simpler in concrete screen files
export function SandboxNotSelectedScreen(props: BaseScreenProps): JSX.Element {
  return <SandboxBaseScreen
    isLoaded={true}
    {...props}
  >
    <Container>
      <h2>Sandbox not selected</h2>
      <p>To use this page you must select one of your sandboxes</p>
      <p>
        <a href="/sandbox-list" className="btn btn-primary">Go to sandbox list</a>
      </p>
    </Container>
  </SandboxBaseScreen>;
}

// NB: This is just a convenience wrapper for the SandboxBaseScreen, but it makes the code a lot simpler in concrete screen files
export function SandboxNotFoundScreen(props: BaseScreenProps): JSX.Element {
  return <SandboxBaseScreen
    {...props}
    isLoaded={true}
    teamNotFound={true}
  />;
}

// NB: This is just a convenience wrapper for the SandboxBaseScreen, but it makes the code a lot simpler in concrete screen files
export function SandboxLoadingScreen(props: BaseScreenProps): JSX.Element {
  return <SandboxBaseScreen
    {...props}
    isLoaded={false}
    team={undefined}
  />
}

// NB: This is just a convenience wrapper for the SandboxBaseScreen, but it makes the code a lot simpler in concrete screen files
export function SandboxFhirDisabledScreen(props: BaseScreenProps & { team: Team }): JSX.Element {
  return <SandboxBaseScreen
    {...props}
    isLoaded={true}
    errorMessage={{
      title: "FHIR server access issue",
      text: "No FHIR server is associated with this team"
    }}
  />
}
