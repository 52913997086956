import React from "react";

import {Team} from "../models";

import SandboxIcon from '../img/icons/sandbox.svg'

export default function SandboxBanner(props: { sandbox: Team }): JSX.Element {
  return <>
    <div className="container">
      <div className="border alert alert-light mt-3 mb-0">
        <div className="alert-icon p-1"
             style={{
               backgroundColor: '#fff',
               // borderRight: '1px solid #dee2e6',
             }}>
          <img src={SandboxIcon}
               style={{
                 width: '5em',
                 borderRight: '1px solid #dee2e6',
                 padding: '3px',
                 paddingRight: '1em',
                 margin: '0.5em',
               }}/>
        </div>
        <div className="alert-content pl-1">
          <p className="mb-0"><strong>{props.sandbox.team_name}</strong> &nbsp;
            <span className="list-separator"> ( </span>
            <a href="/sandbox">manage</a>
            <span className="list-separator"> | </span>
            <a href="/sandbox-list">switch</a>
            <span className="list-separator"> ) </span>
          </p>
          <p>{props.sandbox.team_description}</p>
        </div>
      </div>
    </div>
  </>;
}
