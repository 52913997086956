import React from 'react';
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './turas.css';
import './turas-overrides.css';

import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap.min';

import {environment} from "./config";
import * as Auth from "./service/Auth";

import Landing from './screens/Landing';
import OAuthCallbackScreen from "./screens/OAuthCallbackScreen";
import LoginCallbackScreen from "./screens/LoginCallbackScreen";

import MySandboxesScreen from './screens/MySandboxesScreen';

import SandboxOverviewScreen from "./screens/sandbox/SandboxOverviewScreen";
import SandboxAuthOverviewScreen from "./screens/sandbox/auth/SandboxAuthOverviewScreen";
import CallbackUrlsScreen from "./screens/sandbox/auth/ConfigureCallbackUrlsScreen";

import ResourcesOverviewScreen from './screens/resources/ResourcesScreen';
import CorsInstructionsScreen from './screens/resources/CorsInstructions';
import GettingStartedAuthScreen from './screens/resources/gettingstarted/SandboxAuth';
import GettingStartedFHIR from './screens/resources/gettingstarted/FHIR';
import GettingStartedMediaStore from './screens/resources/gettingstarted/MediaStore';
import GettingStartedMediaStoreAndFHIR from './screens/resources/gettingstarted/MediaStoreAndFHIR';
import GettingStartedEMPI from './screens/resources/gettingstarted/EMPI';
import GettingStartedNCDS from './screens/resources/gettingstarted/NCDS';

import LegalLandingScreen from "./screens/legal/LegalLandingScreen";
import CookiesPolicyScreen from "./screens/legal/CookiePolicyScreen";
import TermsAndConditionsScreen from "./screens/legal/TermsAndConditionsScreen";

import DataOverviewScreen from "./screens/sandbox/data/DataOverviewScreen";
import GenerateDatasetsScreen from "./screens/sandbox/data/GenerateDatasetsScreen";
import GenerateFhirDataItemsScreen from "./screens/sandbox/data/GenerateFhirDataItemsScreen";
import ClearDataScreen from "./screens/sandbox/data/ClearDataScreen";

import QuickAccessScreen from './screens/sandbox/QuickAccessScreen';

import OpenEHROverviewScreen from './screens/sandbox/openehr/OverviewScreen';

import DataStoresOverviewScreen from "./screens/datastores/OverviewScreen";

import FhirOverviewScreen from './screens/sandbox/fhir/OverviewScreen';
import FhirTryItScreen from './screens/sandbox/fhir/TryItScreen';
import FhirPostmanInstructionsScreen from './screens/sandbox/fhir/PostmanInstructionsScreen';

import MSOverviewScreen from "./screens/sandbox/mediastore/OverviewScreen";
import MSTryItScreen from "./screens/sandbox/mediastore/TryItScreen";

import ApisOverviewScreen from "./screens/apis/OverviewScreen";

import EmpiOverviewScreen from "./screens/sandbox/empi/OverviewScreen";
import EmpiTryItScreen from "./screens/sandbox/empi/TryItScreen";

import NcdsOverviewScreen from "./screens/sandbox/ncds/OverviewScreen";
import NcdsTryItScreen from "./screens/sandbox/ncds/TryItScreen";

import CareSummaryOverviewScreen from "./screens/sandbox/caresummary/OverviewScreen";


dayjs.extend(customParseFormat);


const msalInstance = new PublicClientApplication(environment.adminApi.msalConfig);
msalInstance.addEventCallback(async (message: EventMessage) => {
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    try {
      await Auth.doPostLogin(msalInstance);
      // @ts-ignore
      if (window.gtag) window.gtag('event', 'login', {});
      window.location.replace('/');
    } catch (e) {
      // ignore
      console.warn('Error calling post-login endpoint', e);
    }
  }
});

function App(): JSX.Element {

  return <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <Routes>
        {/* NB: The routes in here should be kept in line with ScreenHelpers.getBreadcrumbs() */}

        {/* Auth callbacks */}
        <Route path="/callback" element={<OAuthCallbackScreen/>}/>
        <Route path="/login-callback" element={<LoginCallbackScreen/>}/>

        {/* Top level resources */}
        <Route path="/" element={<Landing/>}/>

        {/* Developer Resources */}
        <Route path="/resources" element={<ResourcesOverviewScreen/>}/>
        <Route path="/resources/cors" element={<CorsInstructionsScreen/>}/>
        {/*<Route path="/resources/getting-started" element={<...../>}/>*/}
        <Route path="/resources/getting-started/auth" element={<GettingStartedAuthScreen/>}/>
        <Route path="/resources/getting-started/fhir" element={<GettingStartedFHIR/>}/>
        <Route path="/resources/getting-started/media-store" element={<GettingStartedMediaStore/>}/>
        <Route path="/resources/getting-started/media-store-and-fhir" element={<GettingStartedMediaStoreAndFHIR/>}/>
        <Route path="/resources/getting-started/empi" element={<GettingStartedEMPI/>}/>
        <Route path="/resources/getting-started/ncds" element={<GettingStartedNCDS/>}/>

        {/* Legal, etc*/}
        <Route path="/legal" element={<LegalLandingScreen/>}/>
        <Route path="/legal/terms" element={<TermsAndConditionsScreen/>}/>
        <Route path="/legal/cookies" element={<CookiesPolicyScreen/>}/>

        {/* APIs */}
        <Route path="/apis" element={<ApisOverviewScreen/>}/>
        <Route path="/apis/empi" element={<EmpiOverviewScreen/>}/>
        <Route path="/apis/empi/try" element={<EmpiTryItScreen/>}/>
        <Route path="/apis/ncds" element={<NcdsOverviewScreen/>}/>
        <Route path="/apis/ncds/try" element={<NcdsTryItScreen/>}/>
        <Route path="/apis/care-summary" element={<CareSummaryOverviewScreen/>}/>

        {/* Data stores */}
        <Route path="/data-stores" element={<DataStoresOverviewScreen/>}/>
        <Route path="/data-stores/fhir" element={<FhirOverviewScreen/>}/>
        <Route path="/data-stores/fhir/try" element={<FhirTryItScreen/>}/>
        <Route path="/data-stores/fhir/postman" element={<FhirPostmanInstructionsScreen/>}/>
        <Route path="/data-stores/media-store" element={<MSOverviewScreen/>}/>
        <Route path="/data-stores/media-store/try" element={<MSTryItScreen/>}/>
        <Route path="/data-stores/openehr" element={<OpenEHROverviewScreen/>}/>

        {/* Sandbox management */}
        <Route path="/sandbox-list" element={<MySandboxesScreen/>}/>
        <Route path="/sandbox" element={<SandboxOverviewScreen/>}/>
        <Route path="/sandbox/auth" element={<SandboxAuthOverviewScreen/>}/>
        <Route path="/sandbox/auth/callback-urls" element={<CallbackUrlsScreen/>}/>
        <Route path="/sandbox/data" element={<DataOverviewScreen/>}/>
        <Route path="/sandbox/data/datasets" element={<GenerateDatasetsScreen/>}/>
        <Route path="/sandbox/data/fhir" element={<GenerateFhirDataItemsScreen/>}/>
        <Route path="/sandbox/data/clear" element={<ClearDataScreen/>}/>
        <Route path="/sandbox/quick-access" element={<QuickAccessScreen/>}/>

        <Route path="*" element={<Landing/>}/>
      </Routes>
    </BrowserRouter>
  </MsalProvider>;
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App/>);
