import Breadcrumbs, {BreadcrumbItem} from "./Breadcrumbs"
import React from "react";

type PageHeaderProps = React.PropsWithRef<{
  title: string,
  subtitle?: string,
  breadcrumbs?: BreadcrumbItem[],
  picture?: string
}>

export function PageHeaderWithoutPicture(props: PageHeaderProps) {
  return (

    <div className="col-md-10">
      {props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs}/>}
      <div className="home-banner-text">
        <h1 id="bannerPageTitle">{props.title}</h1>
        {props.subtitle && props.subtitle.length > 0 && <p id="homeBannerText">{props.subtitle}</p>}
      </div>
    </div>

  )
}

export function PageHeaderWithPicture(props: PageHeaderProps) {
  return (
    <div className="row">
      <div className="col-lg-5 col-xl-4">
        <div className="home-banner-text">
          <h1 id="bannerPageTitle">{props.title}</h1>
          {props.subtitle && props.subtitle.length > 0 && <p id="homeBannerText">{props.subtitle}</p>}
        </div>
      </div>
      <div className="d-none d-lg-block col-lg-6 offset-lg-1 col-xl-6 offset-xl-2">
        <img src={props.picture} className="img-fluid"/>
      </div>
    </div>
  )
}

export default function PageHeader(props: PageHeaderProps) {
  return (
    <div className="main-banner" id="mainbanner">
      <div className="container">
        <div className="row">
          {props.picture
            ? <PageHeaderWithPicture title={props.title}
                                     subtitle={props.subtitle}
                                     breadcrumbs={props.breadcrumbs}
                                     picture={props.picture}/>
            : <PageHeaderWithoutPicture title={props.title}
                                        subtitle={props.subtitle}
                                        breadcrumbs={props.breadcrumbs}/>
          }
        </div>
      </div>
    </div>
  )
}
