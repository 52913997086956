import {Form} from "react-bootstrap";
import React, {useState} from "react";


export type TurasRadioGroupItemProperties = {
  value: string;
  label: string;
}

export type TurasRadioGroupProperties = {
  title: string;
  items: TurasRadioGroupItemProperties[];
  initialValue?: string;
  onValueChange: (value: string | undefined) => void;
  required?: boolean;
}
export default function TurasRadioGroup(props: TurasRadioGroupProperties): JSX.Element {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(props.initialValue);

  function handleItemSelect(newValue?: string) {
    let value = newValue;

    // If we reselect the same value, we want to deselect any option
    if (selectedValue === value) {
      value = undefined;
    }

    setSelectedValue(value);
    props.onValueChange(value);
  }

  return <fieldset className="form-row">
    <Form.Group className="form-group col">
      <legend className={props.required ? "requiredFieldMarker" : ""}> {props.title}</legend>

      {props.items.map(item =>
        <Form.Check type="radio"
                    key={`radio-value-${item.value}`}
                    className="custom-radio"
                    checked={selectedValue === item.value}
        >
          <Form.Check.Input type="radio"
                            value={item.value}
                            id={`radio-value-${item.value}-chk`}
                            checked={selectedValue === item.value}
                            onChange={() => {
                            }}
          />

          <Form.Check.Label
            className="form-check-label"
            htmlFor={`radio-value-${item.value}-chk`}
            onClick={() => handleItemSelect(item.value)}
          >{item.label}</Form.Check.Label>

        </Form.Check>
      )}

      {/* TODO: Validation message */}

    </Form.Group>
  </fieldset>;
}
