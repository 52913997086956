import {PrePreparedOauthToken, Team, TeamApiKey, TeamCognitoSettings, TeamFhirSettings} from "../models";
import {environment} from "../config";
import {getFriendlyErrorMessage} from "../helpers";
import AdminAPI from "../service/AdminAPI";
import {ErrorMessageProps} from "./BaseScreen";


/**
 * A convenience method to handle loading a Cognito access token within a screen
 */
export async function loadAccessToken(sandboxId: string,
                                      api: AdminAPI,
                                      setAccessToken: (value: PrePreparedOauthToken | undefined) => void,
                                      setErrorMessage?: (value: ErrorMessageProps | undefined) => void): Promise<PrePreparedOauthToken | undefined> {
  if (setErrorMessage) setErrorMessage(undefined);

  console.debug(`Loading Cognito token for team ${sandboxId}`);
  if (!sandboxId) {
    setAccessToken(undefined);
    if (setErrorMessage) setErrorMessage({text: 'Team ID not supplied'});
    return;
  }

  function handleError(e: any) {
    if (setErrorMessage) setErrorMessage({text: getFriendlyErrorMessage(e)});
  }

  try {
    const key = await api.getCognitoAccessToken(sandboxId);

    if (key) {
      setAccessToken(key);
    } else {
      setAccessToken(undefined);
    }

    return key;
  } catch (e) {
    handleError(e);
    return undefined;
  }

}

/**
 * A convenience method to handle loading a Cognito access token within a screen
 */
export async function loadApiKey(sandboxId: string,
                                 api: AdminAPI,
                                 setApiKey: (value: TeamApiKey | undefined) => void,
                                 setErrorMessage?: (value: ErrorMessageProps | undefined) => void): Promise<TeamApiKey | undefined> {
  if (setErrorMessage) setErrorMessage(undefined);

  console.debug(`Loading API key for team ${sandboxId}`);
  if (!sandboxId) {
    setApiKey(undefined);
    if (setErrorMessage) setErrorMessage({text: 'Team ID not supplied'});
    return;
  }

  function handleError(e: any) {
    if (setErrorMessage) setErrorMessage({text: getFriendlyErrorMessage(e)});
  }

  try {
    const apiKeys = await api.getApiKeys(sandboxId);

    if (apiKeys && apiKeys.length) {
      setApiKey(apiKeys[0]);
      return apiKeys[0];
    }

    setApiKey(undefined);
  } catch (e) {
    handleError(e);
  }

  return undefined;
}

/**
 * A convenience method to handle loading a screen's Team
 */
export async function loadTeam(sandboxId: string,
                               api: AdminAPI,
                               setTeam: (value: Team | undefined) => void,
                               setTeamNotFound?: (value: boolean) => void,
                               setTeamFhirSettings?: (value: TeamFhirSettings | undefined) => void,
                               setTeamCognitoSettings?: (value: TeamCognitoSettings | undefined) => void,
                               setApiKey?: (value: TeamApiKey | undefined) => void,
                               setErrorMessage?: (value: ErrorMessageProps | undefined) => void,
                               setIsLoaded?: (value: boolean) => void): Promise<Team | undefined> {

  if (setErrorMessage) setErrorMessage(undefined);
  if (setTeamNotFound) setTeamNotFound(false);
  if (setIsLoaded) setIsLoaded(false);

  // console.debug(`Loading info for team ${sandboxId}`);

  if (!sandboxId) {
    setTeam(undefined);
    if (setTeamFhirSettings) setTeamFhirSettings(undefined);
    if (setTeamCognitoSettings) setTeamCognitoSettings(undefined);
    if (setApiKey) setApiKey(undefined);
    if (setErrorMessage) setErrorMessage({
      title: 'Team ID not supplied',
      text: 'Please try selecting a team again.'
    });
    if (setIsLoaded) setIsLoaded(true);
    return;
  }

  function handleError(e: any) {
    if (setErrorMessage) setErrorMessage({
      title: "An error occurred",
      text: getFriendlyErrorMessage(e)
    });
  }

  try {
    // First load the team to ensure it exists
    const team = await api.getTeam(sandboxId);

    // Handle if team doesn't exist
    if (!team) {
      if (setTeamNotFound) setTeamNotFound(true);
      if (setTeamFhirSettings) setTeamFhirSettings(undefined);
      if (setTeamCognitoSettings) setTeamCognitoSettings(undefined);
      if (setApiKey) setApiKey(undefined);
      if (setErrorMessage) setErrorMessage({
        title: "Team not found",
        text: "This may be due to the team no longer existing. Please go to the homepage and try again."
      });
      setTeam(undefined);
      if (setIsLoaded) setIsLoaded(true);
      return;
    }

    // Store the tam
    setTeam(team);

    // FHIR Settings
    if (setTeamFhirSettings)
      api.getFhirSettings(sandboxId)
        .then(setTeamFhirSettings)
        .catch(handleError);

    // Cognito settings
    if (setTeamCognitoSettings)
      api.getCognitoSettings(sandboxId)
        .then(setTeamCognitoSettings)
        .catch(handleError);

    // API Gateway settings
    if (setApiKey)
      api.getApiKeys(sandboxId)
        .then(keys => {
          if (keys && keys.length > 0) {
            setApiKey(keys[0]);
          }
        })
        .catch(handleError);

    if (setIsLoaded) setIsLoaded(true);
    return team;
  } catch (e) {
    if (setErrorMessage) setErrorMessage({
      title: "An unknown error occurred",
      text: "Please try again later or contact us for further information."
    });
    handleError(e);
    if (setIsLoaded) setIsLoaded(true);
    return undefined;
  }
}

export function initGoogleAnalytics() {
  if (!environment.googleAnalytics)
    return () => {
    };

  const gtagScript = document.createElement('script');
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics?.trackingId}`;
  gtagScript.async = true;

  const initScript = document.createElement('script');
  initScript.text = `
window.dataLayer = window.dataLayer || [];

function gtag() {
  dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', '${environment.googleAnalytics?.trackingId}', {
  send_page_view: false,
});

gtag('consent', 'default', {
  'ad_storage': 'denied',
  'analytics_storage': 'denied'
});
`;

  document.head.appendChild(gtagScript);
  document.head.appendChild(initScript);

  return () => {
    document.head.removeChild(gtagScript);
    document.head.removeChild(initScript);
  }
}
