import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {a11yLight} from "react-syntax-highlighter/dist/esm/styles/hljs";

import ClipboardButton from "./forms/ClipboardButton";


export type TurasCodePanelProps = {
  title?: string;
  children: string;
  language: string;
  maxHeight?: string;
  copyButton?: boolean;
  footer?: React.ReactNode;
};

export default function TurasCodePanel(props: TurasCodePanelProps): JSX.Element {

  return <div className="overflow-auto card" style={{
    marginTop: '2vh',
    maxHeight: props.maxHeight || '25em'
  }}>

    {props.title && <>
      <h5>{props.title}</h5>
      <hr className="mt-5 mb-5"/>
    </>}
    <SyntaxHighlighter language={props.language} style={a11yLight} className="m-0">
      {props.children}
    </SyntaxHighlighter>

    <div style={{
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      display: 'flex',
      alignItems: 'start',
    }}>
      {props.copyButton && <ClipboardButton value={props.children}/>}
    </div>

    {props.footer && <div className="card-footer border-top pt-2">
      {props.footer}
    </div>}

  </div>
}
