import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useLocation} from "react-router-dom";

import BaseScreen, {ErrorMessageProps} from "./BaseScreen";

function LoginCallbackScreen() {
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps | undefined>(undefined);


  useEffect(() => {
    // NB: Azure B2C sends the code as the "hash" (i.e the URL component after the # sign)
    const params = new URLSearchParams(location.hash.replace('#', ''));
    const code = params.get('code') || undefined;

    if (!code) {
      setErrorMessage({text: "No 'code' parameter in the callback URL"});
      return;
    }

    setTimeout(() => {
      window.location.replace('/')
    }, 3000);
  }, []); // NB: empty array important to prevent multiple firing of event


  return <BaseScreen
    pageTitle="Login"
    errorMessage={errorMessage}>

    {success && <Container>
      <h2>Login Successful</h2>

      <p>You have been logged in to the Portal.</p>
      <p>You'll be redirected to the home page in a few seconds, or click here to go <a href="/">back to home</a>.
      </p>
    </Container>}

    {loading && <Container>
      <h2>Logging you in...</h2>

      <p>Communicating with the server.</p>
      <p>This should only take a few seconds.</p>
    </Container>}
  </BaseScreen>
}

export default LoginCallbackScreen
