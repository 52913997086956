import React from "react";
import {Row} from "react-bootstrap";

import BaseScreen from "../BaseScreen";
import LandingBlock from "../../components/LandingBlock";
import {TurasSection} from "../../components/TurasSection";
import {environment} from "../../config";

export default function ResourcesScreen() {

  return <BaseScreen
    pageTitle="Developer resources"
    pageSubtitle="Links to getting started guides, third party documentation, and resources to help you develop on the NDP"
  >

    <TurasSection>
      <h2>Getting started guides</h2>

      <Row md={3} lg={4}>
        <LandingBlock title="Sandbox authentication"
                      url="/resources/getting-started/auth"
        >
          Authenticate with the Sandbox's services so you can use them in your own application
        </LandingBlock>
        <LandingBlock title="FHIR server"
                      url="/resources/getting-started/fhir"
        >
          Integrate the NDP FHIR server into your application so you can store and recall FHIR clinical data
        </LandingBlock>
        <LandingBlock title="Media Store"
                      url="/resources/getting-started/media-store"
        >
          Integrate the Media Store service into your application so you can store and recall unstructured clinical data
        </LandingBlock>
        <LandingBlock title="Media Store and FHIR"
                      url="/resources/getting-started/media-store-and-fhir"
        >
          Combine Media Store items with FHIR resources to create complex structured and unstructured clinical data, or
          to provide metadata for files in Media Store.
        </LandingBlock>
        {environment.empiApi && <LandingBlock title="Electronic Master Patient Index"
                                              url="/resources/getting-started/empi"
        >
          Call the EMPI service so you can use patient demographics in your application
        </LandingBlock>}
        {environment.vaccinationsApi && <LandingBlock title="Vaccinations API (NCDS)"
                                                      url="/resources/getting-started/ncds"
        >
          Call the NCDS service so you can use vaccination information in your application
        </LandingBlock>}
      </Row>

      <h2>Front end, UI and UX</h2>

      <Row md={3} lg={4}>
        <LandingBlock title="Turas Style Guide"
                      url="https://nes-turas-bs4styleguide.azurewebsites.net/"
        >
          Follow this style guide when developing Turas themed user applications
        </LandingBlock>
      </Row>

      <h2>Authentication and authorization</h2>

      <Row md={3} lg={4}>
        <LandingBlock title="AWS Cognito"
                      url="https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-identity-pools.html"
        >
          This is the auth provider for a variety of platform services (e.g. FHIR Works)
        </LandingBlock>

        <LandingBlock title="Azure B2C"
                      url="https://learn.microsoft.com/en-us/azure/active-directory-b2c/"
        >
          This is the primary identity provider for staff and developers within the platform
        </LandingBlock>

      </Row>

    </TurasSection>

  </BaseScreen>;
}
