import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import TurasCheckbox from "../../../components/TurasCheckbox";

export default function TermsAndConditionsVerifyModal(props: {
  onApprove: () => void,
  onHide: () => void,
}) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    props.onHide();
  };

  const [validated, setValidated] = useState<boolean>(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    setValidated(true);

    // Failure path
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    // Success path
    props.onApprove();
    props.onHide();
  };

  return (
    <>
      <Modal className="modal modal-danger" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="modal-title">Media Store terms of use</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="turasForm">
          <Modal.Body>

            <p>Required fields are marked with a red asterisk <span className='requiredFieldMarkerNotice'>*</span></p>

            <p>You have already agreed to the <a href="/terms" target="_blank">terms and conditions</a> (opens in new
              tab).</p>

            <p><strong>I agree that <span className='requiredFieldMarkerNotice'>*</span></strong></p>

            <p>
              <TurasCheckbox
                required
                label="this file does not contain personal data of real individuals"/>
            </p>

            <p>
              <TurasCheckbox
                required
                label="this file does not contain malware or other malicious or illegal content that may negatively
                impact NHS Education for Scotland systems"/>
            </p>

            <p>
              <TurasCheckbox
                required
                label="your use of the Media store is part of your work for the NHS"/>
            </p>

          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <Button className="btn btn-default" onClick={handleClose}>I do not accept</Button>
            <Button type="submit">I accept</Button>

          </Modal.Footer>

        </Form>

      </Modal>
    </>
  )
}
