import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import ServiceStatusPanel from "../../../components/ServiceStatusPanel";
import LandingBlock from "../../../components/LandingBlock";
import {environment} from "../../../config";

import ProdFlowDiagram from "../../../img/docs/empi/simplified-flow-prod.png";
import SandboxFlowDiagram from "../../../img/docs/empi/simplified-flow-sandbox.png";


export default function OverviewScreen(): JSX.Element {
  const pageTitle = "Electronic Master Patient Index (EMPI)";
  const pageSubtitle = "The EMPI is designed to be the authoritative source for patient demographics";

  return <BaseScreen
    pageTitle={pageTitle}
    pageSubtitle={pageSubtitle}
    isLoaded={true}
  >
    <TurasSection>
      <Row>
        <Col md={9}>
          <h2>A quick introduction</h2>

          <p>
            The <strong>Electronic Master Patient Index (EMPI)</strong> service provides a standards-based interface to
            the CHI repository in Scotland. Developers can use it to integrate their applications to provide patient
            demographics' functionality. This is usually to identify a patient before performing other actions like
            accessing records in other systems, such as a clinical data repository, or for assigning the unique
            identifier of a patient to a record before saving and linking across systems. </p>
          <p>
            The NDP EMPI service uses an international standard for representing patient demographics, and provides a
            layer of abstraction above the current CHI system, and its impending replacement. This means that developers
            can add EMPI functionality to their applications safe in the knowledge that the migration from the old
            system to the new system will be handled at the platform-level.</p>

          <h2>EMPI in production and in the Sandbox</h2>

          <ServiceStatusPanel
            productionServiceMaturity="pilot"
            productionServiceStatusDescription={`We've been piloting the EMPI service with internal services. Progress has been delayed by the upcoming migration between national CHI services ("old CHI" to "new CHI")`}
            sandboxServiceMaturity="mvp"
            sandboxServiceStatusDescription="A mock version of the EMPI service is in development and we hope to make this available in 2023."
            sandboxLimitations={[
              "No Sandbox version of EMPI is available at present.",
              "The production service is still in pilot. It may not be ready for your needs.",
              "The Sandbox implementation may function differently from production in subtle ways."
            ]}
          />

          <h3>In production</h3>
          <p>This is an oversimplified version of the data flow diagram, to give you an idea of where the service sits
            in a health application</p>
          <img src={ProdFlowDiagram} className="border p-3 bg-white"/>

          <h3>In the Sandbox</h3>
          <p>In the Sandbox environment we use your sandbox's FHIR server as the source for patient demographics. This
            allows you to control the data EMPI will provide to your application, without worrying about the governance
            concerns that come from connecting to real demographics providers.</p>
          <img src={SandboxFlowDiagram} className="border p-3 bg-white"/>
        </Col>
      </Row>

      <hr className="mb-30 mt-30"/>

      <h2>Get started</h2>
      <Row>
        {environment.empiApi && <>

          <LandingBlock title="Interactive demo"
                        url="empi/try">
            <p>Explore the API in a sandbox environment from within your browser</p>
          </LandingBlock>

          <LandingBlock title="Getting started guide"
                        url="/resources/getting-started/empi">
            <p>Follow the step-by-step guide to call the API from Postman</p>
          </LandingBlock>

          <LandingBlock title="Manage data"
                        url="/sandbox/data">
            <p>View and generate test data for your sandbox environment</p>
          </LandingBlock>
        </>}

        <LandingBlock title="OpenAPI specification"
                      url="https://empi.staging.platform.ndp.scot/api-docs">
          <p>The OpenAPI specification is a standardised method of documenting APIs</p>
        </LandingBlock>

        {!environment.empiApi &&
          <>
            <LandingBlock title="Interactive demo">
              <p><strong>Coming soon</strong><br/>
                An interactive demo is being developed, but not ready yet.</p>
            </LandingBlock>
          </>
        }

      </Row>

    </TurasSection>

  </BaseScreen>;
}
