import React from "react";
import {Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {InfoAlert, WarningAlert} from "../../../components/AlertBox";
import TurasSideNav from "../../../components/TurasSideNav";
import TurasCodePanel from "../../../components/TurasCodePanel";
import LandingBlock from "../../../components/LandingBlock";
import {preformatJsonAsHttpRequest, preformatUrlAsHttpRequest} from "../../../helpers";


export default function MediaStoreGuide(): JSX.Element {

  return <BaseScreen
    pageTitle="Getting started: Media Store"
    pageSubtitle="Learn how to integrate the Media Store service into your application so you can store and recall unstructured clinical data">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Store a file within Media Store</li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Store metadata
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Make use of update history files to support batch
              processes
            </li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
          </ul>
          <p>Before starting, it might help to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="auth">authentication guide</a></li>
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="fhir">FHIR getting started guide</a></li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>

      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {label: "Getting started", scrollToId: "step-getting-started"},
              {
                label: "Uploading", items: [
                  {label: "Step 1: Create a FHIR Binary resource", scrollToId: "step-1"},
                  {label: "Step 2: Upload the binary content to AWS S3", scrollToId: "step-2"},
                  {label: "Step 3: Complete the multipart upload", scrollToId: "step-3"},
                  {label: "Step 4: Attach the FHIR Binary to another FHIR resource", scrollToId: "step-4"},
                ]
              },
              {
                label: "Downloading", items: [
                  {label: "Step 5: Get the FHIR Binary resource", scrollToId: "step-5"},
                  {label: "Step 6: Get the file contents from S3", scrollToId: "step-6"},
                ]
              }
            ]}/>
        </Col>

        <Col md={9} sm={12}>

          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>

            <p>The Sandbox environment provides you with a private Media Store as one of its <a href="/data-stores">data
              storage options</a>. Learning how to read and write to the Media Store will allow you to use it to store
              unstructured clinical data for your applications.</p>

            <WarningAlert title="Custom values in this guide">
              <p>Many of the code snippets in this guide contain placeholders for custom values, which should be filled
                with values from your sandbox. </p>

              <p>These placeholders include <code>FHIR_ROOT_URL</code>, <code>FHIR_TENANT_ID</code>,
                {' '}<code>COGNITO_ID_TOKEN</code>, <code>API_KEY</code>, as well as several others specific to the
                request.</p>

              <p>All of these values can be found on your Sandbox's <a href="/sandbox/quick-access">Quick
                access</a> page. </p>
            </WarningAlert>

          </div>

          <div id="step-1" className="guide-step">
            <h2>Uploading</h2>

            <p>This section describes the steps required to upload a file to Media Store. To do this, you simply need
              the file and the access details for your sandbox.</p>

            <h3>Step 1: Create a FHIR Binary resource</h3>

            <p>The first step is to create a Binary resource in the FHIR server (<em>without</em> the binary contents).
              This resource helps you keep track of, and reference the binary file you are about to upload.</p>

            <Row>
              <Col md={6} sm={12}>
                <TurasCodePanel language="http"
                                title="If using single part upload"
                                footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                                  contains placeholders</div>}
                                copyButton>
                  {preformatJsonAsHttpRequest('https://FHIR_SERVER_ROOT/tenant/FHIR_TENANT_ID/Binary',
                    'POST',
                    {
                      'Authorization': 'Bearer COGNITO_ID_TOKEN',
                      'X-API-Key': 'API_KEY',
                    },
                    {
                      resourceType: "Binary",
                      contentType: "(file MIME type)",
                      filename: "(original file name)"
                    }
                  )}
                </TurasCodePanel>
              </Col>

              <Col md={6} sm={12}>
                <TurasCodePanel language="http"
                                title="If using multipart upload"
                                footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                                  contains placeholders</div>}
                                copyButton>
                  {preformatJsonAsHttpRequest('https://FHIR_SERVER_ROOT/tenant/FHIR_TENANT_ID/Binary/multipart',
                    'POST',
                    {
                      'Authorization': 'Bearer COGNITO_ID_TOKEN',
                      'X-API-Key': 'API_KEY',
                    },
                    {
                      resourceType: "Binary",
                      contentType: "(file MIME type)",
                      filename: "(original file name)",
                      numberOfParts: "(how many individual parts)"
                    })
                  }
                </TurasCodePanel>
              </Col>
            </Row>

            <p>The Binary resource which is returned from the FHIR server contains a <strong>pre-signed PUT URL</strong>,
              which is used to upload the binary file to AWS S3.</p>

            <Row>
              <Col md={6} sm={12}>
                <TurasCodePanel language="json"
                                title="Response: POST /Binary"
                                copyButton>
                  {`
{
  "resourceType": "Binary",
  "id": "79a121ab-71ba-472a-b5d8-1a7f92d88e16",
  "filename": "(original file name)",
  "contentType": "(file content type)",
  "meta": {
    "versionId": "1",
    "lastUpdated": "2022-08-08T08:15:31.920Z"
  },
  "presignedPutUrl": "{{ A REALLY LONG URL }}"
}
`.trim()}
                </TurasCodePanel>
              </Col>

              <Col md={6} sm={12}>
                <TurasCodePanel language="json"
                                title="Response: POST /Binary/multipart"
                                copyButton>
                  {`
{
  "resourceType": "Binary",
  "id": "ca90e17a-898c-467d-b862-c6cd1c13ff6c",
  "filename": "(original file name)",
  "contentType": "(file content type)",
  "meta": {
    "lastUpdated": "2023-03-26T16:39:27.606Z",
    "versionId": "1"
  },
  "uploadId": "(S3 file ID)",
  "presignedPutUrls": [
    "{{ A REALLY LONG URL }}",
    "{{ A REALLY LONG URL }}"
  ]
}
`.trim()}
                </TurasCodePanel>
              </Col>
            </Row>

            <InfoAlert title="Important">
              <ul>
                <li>This is a <code>PUT</code> URL, not a <code>POST</code> URL.</li>
                <li>This pre-signed URL is only valid for <strong>5 minutes</strong>. You should only request it when
                  you are ready to initiate the file upload.
                </li>
                <li>The returned <code>ID</code> field should be used to reference this <code>Binary</code> resource in
                  other FHIR resources.
                </li>
                <li>If using <strong>multipart upload</strong> you'll need to record the value of
                  the <code>uploadId</code> field in order to mark the upload as completed.
                </li>
              </ul>
            </InfoAlert>
          </div>

          <div id="step-2" className="guide-step">
            <h3>Step 2: Upload the binary content to AWS S3</h3>

            <p>Use the <strong>PUT URL</strong> from the previous step (or multiple PUT URLs, if using multipart
              upload), to send a HTTP PUT request containing the data of your file.</p>

            <TurasCodePanel language="http"
                            title="S3 PUT request"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {`
PUT /{{REALLY_LONG_URL}} HTTP/1.1
Host: {{ S3 HOSTNAME }}
Content-Type: multipart/form-data

{{BINARY_CONTENT}}
`.trim()}
            </TurasCodePanel>

            <InfoAlert title="Authentication">
              <p>You don't need the <code>Authorization</code> or <code>X-API-Key</code> header in
                this request - all the authentication information is included in the URL parameters</p>
            </InfoAlert>

            <InfoAlert title="Multipart uploads">
              <p>For each part you upload, the server response will contain an <code>etag header</code>. If using
                multipart uploads you must record the value of this header. You'll need it later to mark the upload as
                completed.</p>
            </InfoAlert>

          </div>

          <div id="step-3" className="guide-step">
            <h3>Step 3: Complete the multipart upload</h3>
            <p>This step is only required if you are using <strong>multipart upload</strong>. Single part uploads are
              completed automatically once the file is uploaded</p>


            <TurasCodePanel language="http"
                            title="Complete multipart upload request"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {preformatJsonAsHttpRequest(
                "https://FHIR_ROOT_URL/tenant/FHIR_TENANT_ID/Binary/multipart/complete/BINARY_FILE_ID",
                "POST",
                {
                  'Authorization': 'Bearer COGNITO_ID_TOKEN',
                  'X-API-Key': 'API_KEY',
                },
                {
                  resourceType: "Binary",
                  contentType: '(original file mimetype)',
                  uploadId: "(S3 file ID from step 1)",
                  uploadedParts: [
                    {PartNumber: 1, ETag: "(etag file part 1)"},
                    {PartNumber: 2, ETag: "(etag file part 2)"},
                    {PartNumber: 3, ETag: "(etag file part 3)"},
                  ]
                }
              )}

            </TurasCodePanel>

            <p>The final response will be the updated FHIR Binary resource.</p>
          </div>


          <div id="step-4" className="guide-step">
            <h3>Step 4: Attach the FHIR Binary to another FHIR resource</h3>

            <p><em>This step is optional - see the guide below for full details</em></p>

            <p>Attaching the <code>Binary</code> resource to another FHIR Resource allows you to build up complex
              clinical data, or simply metadata for your file (e.g. a FHIR <code>DiagnosticReport</code> may have
              several attached files.</p>

            <Row className="justify-content-center">
              <LandingBlock title="Media Store and FHIR"
                            url="/resources/getting-started/media-store-and-fhir"
              >
                Combine Media Store items with FHIR resources to create complex structured and unstructured clinical
                data, or to provide metadata for files in Media Store.
              </LandingBlock>
            </Row>


            <h4>Example: Attaching to a FHIR <code>Media</code> resource</h4>

            <TurasCodePanel language="http"
                            title="Example request to create a linked Media resource"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {preformatJsonAsHttpRequest('https://FHIR_SERVER_ROOT/tenant/FHIR_TENANT_ID/Media',
                'POST',
                {
                  'Authorization': 'Bearer COGNITO_ID_TOKEN',
                  'X-API-Key': 'API_KEY',
                },
                {
                  resourceType: "Media",
                  status: "completed",
                  subject: {"reference": "Patient/{PATIENT_ID}"},
                  operator: {"reference": "Practitioner/{PRACTITIONER_ID}"},
                  content: {
                    contentType: "image/jpeg",
                    url: "Binary/{BINARY_ID}"
                  }
                }
              )}
            </TurasCodePanel>

          </div>

          <div id="step-5" className="guide-step">
            <h2>Downloading</h2>

            <p>This section describes the steps required to download a file from Media Store</p>

            <WarningAlert title="Binary ID">
              <p>In order to download a file from Media Store you must know the corresponding <code>Binary.id</code>. If
                you do not have the ID you cannot search for files within Media Store.</p>

              <p>How you locate the corresponding Binary ID will depend upon the application. e.g. you may have
                a <code>Patient</code> with a <code>DiagnosticReport</code>, and the <code>Binary</code> is linked to
                this.</p>
            </WarningAlert>

            <h3>Step 5: Get the FHIR Binary resource</h3>

            <p>Send a FHIR request to GET the Binary resource</p>

            <TurasCodePanel language="http"
                            title="Request the Binary resource"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {preformatUrlAsHttpRequest("https://FHIR_SERVER_ROOT/tenant/FHIR_TENANT_ID/Binary/BINARY_ID",
                "GET",
                {
                  'Authorization': 'Bearer COGNITO_ID_TOKEN',
                  'X-API-Key': 'API_KEY',
                }
              )}
            </TurasCodePanel>


            <p>The server responds with a pre-signed <code>GET</code> URL</p>
            <TurasCodePanel language="json"
                            title="Response: GET Binary"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {`
{
    "resourceType": "Binary",
    "contentType": "image/jpeg",
    "id": "79a121ab-71ba-472a-b5d8-1a7f92d88e16",
    "meta": {
        "versionId": "1",
        "lastUpdated": "2022-08-08T08:15:31.920Z"
    },
    "presignedGetUrl": "{{ A REALLY LONG URL }}"
}
`.trim()}
            </TurasCodePanel>


            <InfoAlert title="Important">
              <p>This pre-signed URL is only valid for <strong>5 minutes</strong>. You should only
                request it when you are ready to initiate the file download.</p>
            </InfoAlert>

          </div>


          <div id="step-6" className="guide-step">
            <h3>Step 6: Get the file contents from S3</h3>

            <p>Use the pre-signed GET URL from the previous step to make the request.</p>

            <TurasCodePanel language="http"
                            title="S3 GET request"
                            footer={<div className="text-secondary"><i className="fa fa-lightbulb"></i> This snippet
                              contains placeholders</div>}
                            copyButton>
              {`
GET /{{REALLY_LONG_URL}} HTTP/1.1
Host: {{ S3 HOSTNAME }}
`.trim()}
            </TurasCodePanel><br/>

            <InfoAlert title="Authentication">
              <p>You don't need the <code>Authorization</code> or <code>X-API-Key</code> header in
                this request - all the authentication information is included in the URL parameters</p>
            </InfoAlert>
          </div>

        </Col>
      </Row>

    </TurasSection>
  </BaseScreen>;
}
