import {Team} from "../models";
import {BreadcrumbItem} from "./Breadcrumbs";

export function getBreadcrumbs(pathName: string,
                               sandbox?: Team | undefined): BreadcrumbItem[] {

  const breadcrumbs: BreadcrumbItem[] = [
    {title: 'Home', url: '/'},
  ];

  // const pathName = new URL(url).pathname;
  const urlTokens = pathName.split('/')
    .filter(t => t && t.length);

  if (urlTokens.length === 0)
    return breadcrumbs;

  const pathTopLevel = urlTokens.shift();
  switch (pathTopLevel) {
    case 'callback':
      breadcrumbs.push({title: 'Authentication', url: '/sandbox/auth'});
      breadcrumbs.push({title: 'OAuth 2.0 callback'});
      break;

    case 'login-callback':
      breadcrumbs.push({title: 'Login'});
      break;

    case 'snippets':
      breadcrumbs.push({title: 'Code snippets', url: `/snippets`});
      break;

    case 'documentation':
      breadcrumbs.push({title: 'Documentation', url: `/documentation`});
      break;

    case 'resources':
      breadcrumbs.push({title: 'Developer resources', url: `/resources`});

      // Resources overview page
      if (urlTokens.length === 0) break;

      const resourcesPath = urlTokens.shift();
      switch (resourcesPath) {
        case 'cors':
          breadcrumbs.push({title: 'CORS instructions', url: `/resources/cors`});
          break;

        case 'getting-started':
          breadcrumbs.push({title: 'Getting started'});

          // Getting started page
          if (urlTokens.length === 0) break;

          const gettingStartedPath = urlTokens.shift();
          switch (gettingStartedPath) {
            case 'auth':
              breadcrumbs.push({title: 'Authentication', url: `/resources/getting-started/auth`});
              break;

            case 'empi':
              breadcrumbs.push({title: 'EMPI', url: `/resources/getting-started/empi`});
              break;

            case 'ncds':
              breadcrumbs.push({title: 'Vaccinations API', url: `/resources/getting-started/ncds`});
              break;

            case 'fhir':
              breadcrumbs.push({title: 'FHIR server', url: `/resources/getting-started/fhir`});
              break;

            case 'media-store':
              breadcrumbs.push({title: 'Media Store', url: `/resources/getting-started/media-store`});
              break;

            case 'openehr':
              breadcrumbs.push({title: 'openEHR', url: `/resources/getting-started/openehr`});
              break;

          }
          break;
      }

      break;

    case 'legal':
      breadcrumbs.push({title: 'Legal', url: `/legal`});

      // Legal overview page
      if (urlTokens.length === 0) break;

      const legalPath = urlTokens.shift();
      switch (legalPath) {
        case 'terms':
          breadcrumbs.push({title: 'Terms and conditions', url: `/legal/terms`});
          break;

        case 'cookies':
          breadcrumbs.push({title: 'Cookies', url: `/legal/cookies`});
          break;
      }

      break;

    case 'apis':
      breadcrumbs.push({title: 'APIs', url: '/apis'});

      // This is the APIs overview page
      if (urlTokens.length === 0) break;

      const apiPath = urlTokens.shift();

      switch (apiPath) {

        case 'empi':
          breadcrumbs.push({title: 'EMPI', url: `/apis/empi`});

          // EMPI landing page
          if (urlTokens.length === 0) break;

          const empiPath = urlTokens.shift();
          switch (empiPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/apis/empi/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/apis/empi/postman`});
              break;

          }
          break;

        case 'ncds':
          breadcrumbs.push({title: 'Vaccinations API', url: `/apis/ncds`});

          // NCDS landing page
          if (urlTokens.length === 0) break;

          const ncdsPath = urlTokens.shift();
          switch (ncdsPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/apis/ncds/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/apis/ncds/postman`});
              break;

          }
          break;

        case 'care-summary':
          breadcrumbs.push({title: 'Care Summary Proxy', url: `/apis/care-summary`});

          // NCDS landing page
          if (urlTokens.length === 0) break;

          const careSummaryPath = urlTokens.shift();
          switch (careSummaryPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/apis/care-summary/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/apis/care-summary/postman`});
              break;

          }
          break;

      }

      break;

    case 'data-stores':
      breadcrumbs.push({title: 'Data stores', url: `/data-stores`});

      // Data store overview page
      if (urlTokens.length === 0) break;

      const dataStorePath = urlTokens.shift();

      switch (dataStorePath) {

        case 'fhir':
          breadcrumbs.push({title: 'FHIR CDR', url: `/data-stores/fhir`});

          // FHIR landing page
          if (urlTokens.length === 0) break;

          const fhirPath = urlTokens.shift();
          switch (fhirPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/data-stores/fhir/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/data-stores/fhir/postman`});
              break;

          }
          break;

        case 'media-store':
          breadcrumbs.push({title: 'Media Store', url: `/data-stores/media-store`});

          // Media Store landing page
          if (urlTokens.length === 0) break;

          const msPath = urlTokens.shift();
          switch (msPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/data-stores/media-store/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/data-stores/media-store/postman`});
              break;

          }
          break;

        case 'openehr':
          breadcrumbs.push({title: 'openEHR CDR', url: `/data-stores/openehr`});

          // openEHR landing page
          if (urlTokens.length === 0) break;

          const openehrPath = urlTokens.shift();
          switch (openehrPath) {
            case 'try':
              breadcrumbs.push({title: 'Interactive demo', url: `/data-stores/openehr/try`});
              break;

            case 'postman':
              breadcrumbs.push({title: 'Postman guide', url: `/data-stores/openehr/postman`});
              break;

          }
          break;

      }

      break;

    case 'sandbox-list':
      breadcrumbs.push({title: 'Select sandbox', url: `/sandbox-list`});
      break;

    case 'sandbox':
      breadcrumbs.push({title: 'Manage sandbox', url: `/sandbox`});

      // Sandbox overview page
      if (urlTokens.length === 0) {
        breadcrumbs.push({title: 'Sandbox overview', url: `/sandbox`});
        break;
      }

      const sandboxPath = urlTokens.shift();
      switch (sandboxPath) {
        case 'quick-access':
          breadcrumbs.push({title: 'Quick access details', url: `/sandbox/quick-access`});
          break;

        case 'auth':
          breadcrumbs.push({title: 'Authentication', url: `/sandbox/auth`});

          // auth overview page
          if (urlTokens.length === 0) break;

          const authPath = urlTokens.shift();
          switch (authPath) {
            case 'callback-urls':
              breadcrumbs.push({title: 'Callback URLs', url: `/sandbox/auth/callback-urls`});
              break;

          }
          break;

        case 'data':
          breadcrumbs.push({title: 'Manage data', url: `/sandbox/data`});

          // data overview page
          if (urlTokens.length === 0) break;

          const dataPath = urlTokens.shift();
          switch (dataPath) {
            case 'datasets':
              breadcrumbs.push({title: 'Generate datasets', url: `/sandbox/data/datasets`});
              break;

            case 'clear':
              breadcrumbs.push({title: 'Clear data', url: `/sandbox/data/clear`});
              break;

            case 'fhir':
              breadcrumbs.push({title: 'Generate FHIR data', url: `/sandbox/data/fhir`});
              break;

            case 'openehr':
              breadcrumbs.push({title: 'Generate openEHR data', url: `/sandbox/data/openEHR`});
              break;

          }
          break;

      }

      break;

  }

  return breadcrumbs;
}
