import React from "react";
import {Card, Col, Row} from "react-bootstrap";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {InfoAlert, WarningAlert} from "../../../components/AlertBox";
import TurasSideNav from "../../../components/TurasSideNav";
import LandingBlock from "../../../components/LandingBlock";

export default function SandboxAuth(): JSX.Element {
  return <BaseScreen
    pageTitle="Getting started: Sandbox authentication"
    pageSubtitle="Learn how to authenticate with the Sandbox's services so you can use them in your own application">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Locate your sandbox's mock user credentials</li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Request a token for a user</li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Send an authenticated request to the FHIR server
            </li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
            <li><i className="fa fa-check text-warning"></i>{' '}
              Understand the basics of OAuth 2.0 and authorization code flow. See <a
                href="https://auth0.com/docs/get-started/authentication-and-authorization-flow"
                target="_blank">the Auth0 guide</a> for more information on OAuth 2.0.
            </li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>

      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {label: "Getting started", scrollToId: "step-getting-started"},
              {label: "Step 1: Locate your sandbox's mock user credentials", scrollToId: "step-1"},
              {label: "Step 2: Configure Postman to use Sandbox auth", scrollToId: "step-2"},
              {label: "Step 3: Send an authenticated request", scrollToId: "step-3"},
            ]}/>
        </Col>

        <Col md={9} sm={12}>

          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>
            <p>The majority of Sandbox services (e.g FHIR server, Media Store) make use of a common authentication
              service, provided by <a href="https://docs.aws.amazon.com/cognito/index.html" target="_blank">AWS
                Cognito</a>.</p>

            <p>When your sandbox was created, a set of mock users were created to represent potential users of your
              applications. These differ from your Turas login: your Turas login lets you log in as a developer and
              Sandbox administrator, while these mock users let you log in as users of an application you might
              develop.</p>

            <WarningAlert title="Client credentials">
              <p>Many applications call for the client credentials OAuth flow. Unfortunately the FHIR server which
                powers the Sandbox environment does not support client credentials. This is something we hope to address
                in the near future, but for now you must use the authorization code OAuth flow.</p>
            </WarningAlert>

          </div>

          <div id="step-1" className="guide-step">
            <h2>Step 1: Locate your sandbox's mock user credentials</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Sandbox authentication overview"
                            url="/sandbox/auth">
                Configure your sandbox auth, and try the mechanisms from within your browser
              </LandingBlock>
              <LandingBlock title="Quick access"
                            url="/sandbox/quick-access">
                Use this link to obtain an access token right away
              </LandingBlock>
            </Row>

            <p>Open the <a href="/sandbox/auth">Sandbox authentication</a> page. You'll be presented with two
              authentication mechanisms: Authorization Code and Client Credentials. These are two common OAuth 2.0
              authentication mechanisms. This guide demonstrates the use of <strong>authorization code</strong>, which
              is the flow you should use if an end user is making requests for data.</p>

            <p>Under the authorization code tab you'll see a collection of usernames and passwords. These are mock
              logins, representing potential users of your system.
            </p>

            <h3>Callback URL</h3>
            <p>The authorization code flow requires a <code>callback URL</code>. The auth server redirects the user back
              to this URL after login, along with a temporary <code>authorization code</code>. For security, the
              callback URL must be pre-configured with the auth server.</p>
            <p>Your private sandbox comes pre-configured with to callback URLs: <code>http://localhost:3000</code> (so
              you can develop web applications locally) and <code>https://developer.platform.ndp.scot</code> (the URL of
              this Developer Portal). You can modify these on the <a href="/sandbox/auth">Sandbox authentication</a> if
              required, however for the purposes of this guide you <strong>must</strong> at least
              have <code>http://localhost:3000</code> configured.
            </p>
            <p><i className="fa fa-info-circle"></i> On the Sandbox authentication page you have the option to toggle
              between callback URLs. This only affects the interactive demos in this web application. Other applications
              may use any of the configured callback URLs, regardless of which one is toggled in the Dev Portal.</p>

            <h3>See it in the browser</h3>
            <p>Open the <a href="/sandbox/auth">Sandbox authentication</a> page and ensure
              that <code>{window.location.protocol + '//' + window.location.host}/callback</code> is selected for the
              callback URL.</p>
            <p>At the bottom of the authorization code tab you'll see a section labelled "Try it!" Expand this section,
              and click the "Try it!" button at the bottom. This will open a new tab with a login form. Copy one of the
              username/password pairs into the form and log in.</p>
            <p>You'll be redirected back to a page called "OAuth 2.0 callback received." This page explains how to swap
              an <code>authorization code</code> for a <code>token</code>. The ID token is used to make authenticate
              requests to the various data servers in the Sandbox.</p>

          </div>
          <div id="step-2" className="guide-step">
            <h2>Step 2: Configure Postman to use Sandbox auth</h2>
            <Row className="justify-content-md-center">
              <InfoAlert title="Postman" className="col-md-10">
                <p>Before completing this section you'll need to
                  install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool.</p>
                <p>You can use any tool which allows you to prepare and send HTTP requests (e.g. cURL)</p>
              </InfoAlert>
            </Row>

            <p>We'll now configure Postman's built-in authentication helper to request an ID token on your behalf, and
              embed this token into HTTP requests so you can communicate with Sandbox services (e.g the FHIR
              server).</p>

            <Card>
              <div className="card-heading">
                <Card.Title>Authentication server details</Card.Title>
              </div>

              <Card.Body>
                <p>These details are specific to your sandbox. You can find them on the <a href="/sandbox/auth">Sandbox
                  authentication</a> page. You'll need </p>

                <ul className="list-unstyled">
                  <li><i
                    className="fa fa-check-square-o text-success"></i> The <code>username</code>/<code>password</code> for
                    one of your users
                  </li>
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>login URL</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>token URL</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> The <code>OAuth client ID (web)</code></li>
                </ul>

              </Card.Body>
            </Card>

            <h3>Postman environment</h3>

            <ol>
              <li>Create an environment with a name that clearly identifies your sandbox.</li>
              <li>Add variables called
                <ul>
                  <li><code>COGNITO_LOGIN_URL</code> - type=default</li>
                  <li><code>COGNITO_TOKEN_URL</code> - type=default</li>
                  <li><code>COGNITO_CLIENT_ID</code> - type=default</li>
                </ul>
              </li>
              <li>Copy the values you gathered earlier into these variables (into both the 'initial' and 'current' value
                fields)
              </li>
            </ol>

            <h3>Postman collection</h3>

            <ol>
              <li>Create a collection called <code>NDP FHIR Server (Sandbox)</code></li>
              <li>Under the Authentication tab, select type <code>OAuth 2.0</code></li>
              <li>Add auth data to <code>request headers</code></li>
              <li>Under 'Current token'
                <ul>
                  <li>Use Token Type should be <code>ID token</code></li>
                </ul>
              </li>
              <li>Under 'Configure new token'
                <ul>
                  <li>Grant type should be <code>authorization code</code></li>
                  <li>Callback URL should be <code>http://localhost:3000/callback</code></li>
                  <li>Auth URL should be <code>{`{{COGNITO_LOGIN_URL}}`}</code></li>
                  <li>Access Token URL should be <code>{`{{COGNITO_TOKEN_URL}}`}</code></li>
                  <li>Client ID should be <code>{`{{COGNITO_CLIENT_ID}}`}</code></li>
                  <li>Client Authentication should be <code>Send client credentials in body</code></li>
                </ul>
              </li>
              <li>Click <code>Get New Access Token</code></li>
              <li>Log in using one of your sandbox users' username/password</li>
              <li>You should see "<i className="fa fa-check-circle text-success"></i> Authentication complete"</li>
              <li>That's it! After a few second you should see a popup which displays several long, encoded tokens which
                will be used to request data from your sandbox. Postman stores these in memory for use later, so you can
                safely close the dialog.
              </li>
            </ol>
          </div>

          <div id="step-3" className="guide-step">
            <h2>Step 3: Send an authenticated request</h2>

            <p>We'll now configure Postman to send an authenticated request to a Sandbox service. In this guide we'll
              use the FHIR server, although the proces is the same for Media Store, EMPI, Vaccinations API, etc.</p>

            <Card>
              <div className="card-heading">
                <Card.Title>Access details</Card.Title>
              </div>

              <Card.Body>
                <p>You'll need the following from the <a href="/sandbox/quick-access">Quick access</a> page</p>

                <ul className="list-unstyled">
                  <li><i className="fa fa-check-square-o text-success"></i> The FHIR server <code>full URL</code></li>
                  <li><i className="fa fa-check-square-o text-success"></i> Your sandbox's <code>API key</code></li>
                </ul>
              </Card.Body>
            </Card>

            <h3>Postman environment</h3>

            <ol>
              <li>Open the Postman environment you created in Step 2</li>
              <li>Add variables called
                <ul>
                  <li><code>FHIR_SERVER_ROOT</code> - type=default</li>
                  <li><code>API_KEY</code> - type=<strong>secret</strong></li>
                </ul>
              </li>
              <li>Copy the values you gathered earlier into these variables
                <ul>
                  <li>FHIR root URL to <code>FHIR_SERVER_ROOT</code> (under initial value and current value)</li>
                  <li>API Key to <code>API_KEY</code> (under initial value and current value)</li>
                </ul>
              </li>
            </ol>

            <h3>Postman collection</h3>

            <ol>
              <li>Open the Postman collection you created in Step 2 (e.g. NDP FHIR Server (Sandbox))</li>
              <li>Create a new request called <code>GET Patients</code></li>
              <li>Choose request type <code>GET</code> and URL {' '}
                <code>{`{{FHIR_SERVER_ROOT}}`}/Patient</code></li>
              <li>On the Authorization tab, choose <code>Inherit auth from parent</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>That's it! Hit <code>Send</code>. You should see a FHIR Bundle of Patient objects (or an empty
                bundle if there's no data in your sandbox)
              </li>
            </ol>
          </div>

        </Col>
      </Row>

    </TurasSection>
  </BaseScreen>;
}
