import React from "react";
import {Card, Col, Form, InputGroup, Row} from "react-bootstrap";

import {environment} from "../../../config";

import BaseScreen from "../../BaseScreen";
import {TurasSection} from "../../../components/TurasSection";
import {ErrorAlert, InfoAlert} from "../../../components/AlertBox";
import ClipboardButton from "../../../components/forms/ClipboardButton";
import TurasSideNav from "../../../components/TurasSideNav";
import LandingBlock from "../../../components/LandingBlock";

import SandboxFlowDiagram from "../../../img/docs/ncds/simplified-flow-sandbox.png";


export default function NCDSGuide(): JSX.Element {

  return <BaseScreen
    pageTitle="Getting started: Vaccinations API (NCDS)"
    pageSubtitle="Learn how to call the NCDS service so you can use vaccination information in your application">

    <TurasSection>
      <Row>
        <Col md={6} sm={12} id="step-objectives">
          <h2>Objectives</h2>
          <p>By the end of this guide you will be able to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-bullseye-arrow text-success"></i> Generate mock data for your sandbox
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Request a patient's vaccination history if you
              know their CHI
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Request information about the Vaccinations API
              capabilities (known drugs, diseases, etc)
            </li>
            <li><i className="fa fa-bullseye-arrow text-success"></i> Make use of update history files to support batch
              processes
            </li>
          </ul>
        </Col>
        <Col md={6} sm={12} id="step-prerequisites">
          <h2>Prerequisites</h2>
          <p>This guide assumes you've already completed these steps</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-check text-warning"></i>{' '}
              Install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool
            </li>
          </ul>
          <p>Before starting, it might help to</p>
          <ul className="list-unstyled">
            <li><i className="fa fa-lightbulb text-info"></i>{' '}
              Complete the <a href="auth">authentication guide</a></li>
          </ul>
        </Col>
      </Row>

      <hr className="mt-30 mb-30"/>

      <Row>
        <Col md={3} sm={12}>
          <TurasSideNav
            title="Jump to section"
            items={[
              {label: "Objectives", scrollToId: "step-objectives"},
              {label: "Prerequisites", scrollToId: "step-prerequisites"},
              {label: "Getting started", scrollToId: "step-getting-started"},
              {label: "Step 1: Generate some mock patients", scrollToId: "step-1"},
              {label: "Step 2: Authentication", scrollToId: "step-2"},
              {label: "Step 3: Use the NCDS API to request a patient by CHI", scrollToId: "step-3"},
              {label: "Step 4: Query the NCDS configuration - Drugs, diseases, etc", scrollToId: "step-4"},
              {label: "Step 5: Query the NCDS API for updated patients", scrollToId: "step-5"},
            ]}/>
        </Col>

        <Col md={9} sm={12}>
          <div id="step-getting-started" className="guide-step">
            <h2>Getting started</h2>
            <p>The National Clinical Data Store (NCDS) Vaccination API provides a proxy to several sources of patient
              demographics, presenting them as a <a href="https://www.hl7.org/fhir/index.html"
                                                    target="_blank">FHIR</a> API.</p>

            <p>In the Sandbox environment we use your sandbox's FHIR server as the source for vaccinations information.
              This allows you to control the data NCDS will provide to your application, without worrying about the
              governance concerns that come from connecting to real data sources.</p>
            <img src={SandboxFlowDiagram} className="border p-3 bg-white"/>

          </div>
          <div id="step-1" className="guide-step">
            <h2>Step 1: Generate some mock patients</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Manage sandbox data"
                            url="/sandbox/data">
                Use this link to manage or generate data in your sandbox
              </LandingBlock>
              <LandingBlock title="NCDS interactive demo"
                            url="/apis/ncds/try">
                Try the NCDS service from within your browser
              </LandingBlock>
            </Row>

            <p>Open the <a href="/sandbox/data">Manage sandbox data</a> page. You'll see several options to generate
              test data for your application. Pick the <strong>Generate datasets</strong> option.</p>

            <p>On the Generate datasets page, scroll down to "Vaccinations API (NCDS) patients". Click the button to add
              NCDS patients. After a few seconds, at the bottom of the screen, you should see a summary which
              includes <code>Patient</code>, <code>Immunization</code>, <code>Practitioner</code>, etc. These are the
              FHIR resources which comprise a patient's immunization history within NCDS.</p>

            <h3>See it in the browser</h3>
            <p>Open the <a href="/apis/ncds/try">NCDS interactive demo</a>.</p>
            <p><i className="fa fa-warning"></i> You don't know any of the patients' CHIs yet, and you may have patients
              in your sandbox which do not have immunizations. The easiest way to find a patient who has immunizations
              is to switch to <strong>Batch Files</strong> and generate the <strong>Updated Patients</strong> report.
            </p>
            <p><i className="fa fa-check-square-o"></i> At the bottom of the screen you will see a list of CHIs which
              are guaranteed to have immunization history. Take note of one or more of these CHIs, you'll need them
              later.
            </p>


          </div>
          <div id="step-2" className="guide-step">

            <h2>Step 2: Authentication</h2>

            <Row className="justify-content-md-center">
              <LandingBlock title="Getting started with authentication"
                            url="auth">
                Follow this guide to learn how to use the authentication mechanisms of the Sandbox.
              </LandingBlock>
              <LandingBlock title="Authentication interactive demo"
                            url="/sandbox/auth">
                Try the authentication mechanisms from within your browser
              </LandingBlock>
              <LandingBlock title="Quick access"
                            url="/sandbox/quick-access">
                Use this link to obtain an access token right away
              </LandingBlock>
            </Row>

            <p>The first step of connecting to NCDS is to obtain an authentication token from the authentication
              service. In the Sandbox environment this is AWS Cognito. </p>

            <InfoAlert>
              In the Sandbox environment and in production we use AWS Cognito to authenticate services. The
              configuration will probably differ between environments. This is true of all NDP services, although we
              are working to unify our auth mechanisms across the platform.
            </InfoAlert>

            <p>The boxes above provide three methods to obtain a token. If you're in a rush, you can use
              the <strong>Quick access</strong> screen to generate a token immediately. If you want to build a full
              end-to-end system you'll need to follow the <strong>Getting started with authentication</strong> guide.
            </p>

            <h3>Get a token</h3>

            <p>Using one of the methods above, obtain an <code>ID
              token</code> from AWS Cognito. This token is only valid for <strong>60 minutes</strong>, so if you take a
              break, or want to revisit this guide you'll need to obtain a fresh one.</p>
            <p>You'll also need the <code>API key</code> for your private sandbox, available
              from the Quick access screen.</p>


          </div>
          <div id="step-3" className="guide-step">
            <h2>Step 3: Use the NCDS API to request a patient by CHI</h2>

            <InfoAlert title="Postman">
              <p>Before completing this section you'll need to
                install <a href="https://www.postman.com/" target="_blank">Postman</a> or another API test tool.</p>
              <p>You can use any tool which allows you to prepare and send HTTP requests (e.g. cURL)</p>
            </InfoAlert>

            <Card>
              <div className="card-heading">
                <Card.Title>NCDS API details</Card.Title>
              </div>
              <Card.Body>
                <Form className="turasForm">

                  <Form.Group controlId="ncdsAccessDetails.rootUrl" className='form-group'>
                    <Form.Label>Root URL</Form.Label>
                    <InputGroup>
                      <Form.Control type="text"
                                    value={environment.vaccinationsApi?.url || ''}
                                    readOnly={true}/>
                      <ClipboardButton value={environment.vaccinationsApi?.url || ''}/>
                    </InputGroup>
                  </Form.Group>

                  <p>If you've not done so already, obtain an <code>ID token</code> and your sandbox's <code>API
                    key</code> from the <a href="/sandbox/quick-access">Quick access</a> screen.</p>

                </Form>
              </Card.Body>
            </Card>

            <h3>Postman environment</h3>

            <ol>
              <li>Create an environment with a name that clearly identifies your sandbox.</li>
              <li>Add variables called
                <ul>
                  <li><code>NCDS_ROOT</code> - type=default</li>
                  <li><code>API_KEY</code> - type=<strong>secret</strong></li>
                  <li><code>COGNITO_ID_TOKEN</code> - type=<strong>secret</strong></li>
                  <li><code>SELECTED_CHI</code> - type=default</li>
                </ul>
              </li>
              <li>Copy the values you gathered earlier into these variables
                <ul>
                  <li>NCDS root URL to <code>NCDS_ROOT</code> (under initial value and current value)</li>
                  <li>API Key to <code>API_KEY</code> (under initial value and current value)</li>
                  <li>ID Token to <code>COGNITO_ID_TOKEN</code> (under <strong>current value</strong> only)</li>
                  <li>CHI you saved earlier to <code>SELECTED_CHI</code> (under <strong>current value</strong> only). If
                    you didn't save a CHI from earlier, follow Step 1 to easily find a known CHI
                  </li>
                </ul>
              </li>
            </ol>

            <p><strong>NB:</strong> your Token is only valid for <strong>1 hour</strong>, so you'll need to refresh it
              occasionally. All the other values are static.</p>

            <h3>Postman collection</h3>

            <ol>
              <li>Create a collection called <code>NDP Vaccination API (Sandbox)</code></li>
              <li>Create a new request called <code>NCDS - request patient by CHI</code></li>
              <li>Choose request type <code>GET</code> and URL {' '}
                <code>{`{{NCDS_ROOT}}`}/Immunization?identifier={`{{SELECTED_CHI}}`}</code></li>
              <li>On the Authorization tab, choose type <code>Bearer Token</code>, and the Token should
                be <code>{`{{COGNITO_ID_TOKEN}}`}</code></li>
              <li>On the Headers tab, add a header called <code>X-API-Key</code> with value {' '}
                <code>{`{{API_KEY}}`}</code></li>
              <li>That's it! Hit <code>Send</code>. You should see a FHIR Bundle of Immunization objects (or an error
                response if there's no matching patient in your sandbox)
              </li>
            </ol>
          </div>


          <div id="step-4" className="guide-step">
            <h2>Step 4: Query the NCDS configuration - Drugs, diseases, etc</h2>
            <ErrorAlert title="TODO">
              <ul>
                <li>Write section on using the ValueSets endpoint</li>
                <li>Write section on using the Batch Update Files</li>
              </ul>
            </ErrorAlert>

          </div>

          <div id="step-5" className="guide-step">
            <h2>Step 5: Query the NCDS API for updated patients</h2>
            <ErrorAlert title="TODO">
              <ul>
                <li>Write section on using the ValueSets endpoint</li>
                <li>Write section on using the Batch Update Files</li>
              </ul>
            </ErrorAlert>

          </div>

        </Col>

      </Row>


    </TurasSection>
  </BaseScreen>;
}
