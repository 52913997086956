import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {Button} from "react-bootstrap";
import {handleOptionalCookiesOptIn, handleOptionalCookiesOptOut} from "../helpers";

export function GoogleAnalyticsOptOut(): JSX.Element {
  const location = useLocation();

  const [locationLoaded, setLocationLoaded] = useState<boolean>(false);
  const [gaCookiePresent, setGaCookiePresent] = useState<boolean>();

  useEffect(() => {
    // HACK: GA is loaded by a useEffect in BaseScreen. This ensures we load our screen after GA is loaded.
    setLocationLoaded(true);

    setGaCookiePresent(document.cookie !== undefined && document.cookie.includes('_ga='))
  }, [location]);


  function AvailableOptionButtons(): JSX.Element {
    // HACK: GA is loaded by a useEffect in BaseScreen. This ensures we load our screen after GA is loaded.
    if (!locationLoaded) return <></>;

    // @ts-ignore
    if (!window.gtag) {
      return <p>
        <i className="fa fa-check text-success"></i> Google Analytics is not loaded. There's nothing to do.
      </p>
    }

    if (gaCookiePresent) {
      return <>
        <Button variant="default"
                className="mt-0 mb-2"
                onClick={() => handleOptionalCookiesOptOut()}>
          <i className="fa fa-sign-out"></i> Opt out of Google Analytics cookies
        </Button>
        <p>Opting out will only apply to this browser on this computer. If you access the site from elsewhere you'll
          need to opt out there as well.</p>
      </>
    }


    return <>
      <Button variant="default"
              className="mt-0 mb-2"
              onClick={() => handleOptionalCookiesOptIn()}>
        <i className="fa fa-sign-in"></i> Out in to Google Analytics cookies
      </Button>
      <p>Opting in will only apply to this browser on this computer. If you access the site from elsewhere you'll
        need to opt in there as well.</p>
    </>
  }

  return <>
    {/*<InfoAlert title="Google Analytics">*/}
    <p>We use <a href="https://marketingplatform.google.com/intl/en_uk/about/analytics/" target="_blank">Google
      Analytics</a> to help us monitor usage of the Developer Portal and Sandbox features. Combined with user
      feedback and usability studies, this allows us to make better decisions about the development of our
      services.</p>
    <p>We will never sell this information, or give it to third parties for their commercial benefit.</p>

    <p><strong>What are your options?</strong></p>
    <AvailableOptionButtons/>
    {/*</InfoAlert>*/}
  </>;
}
