import axios, {AxiosRequestConfig} from "axios";
import {Organization, Patient, Practitioner} from "fhir/r4";

import {environment} from "../config";
import {ApiResponseError} from "../errors";

export class RandomDataAPI {
  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = environment.randomDataApi.url;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(0, urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // build path
    return `${urlRoot}/${path}`
  }

  private getRequestConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json+fhir'
    }

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async pingApi(): Promise<boolean> {
    return axios.get(this.getApiUrl('/any-old-url'))
      .then(() => true)
      .catch(e => {
        if (e.response) {
          // all is good, we don't care about the response, just that it exists
          return true;
        }
        console.error(e.message);
        // We're likely seeing a failure to connect
        return false;
      });
  }

  apiGet<T>(url: string): Promise<T> {
    const config = this.getRequestConfig();

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw ApiResponseError.fromResponse(response);

        const obj = response.data as T;
        return obj;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  getPatient(age?: number | 'child' | 'adult' | 'oap', gender?: 'male' | 'female'): Promise<Patient> {
    const baseUrl = this.getApiUrl(`/Patient`);
    const url = new URL(baseUrl);
    if (age)
      url.searchParams.append('age', age.toString());
    if (gender)
      url.searchParams.append('gender', gender);
    return this.apiGet<Patient>(url.href);
  }

  getPractitioner(type?: 'gp' | 'nurse'): Promise<Practitioner> {
    const baseUrl = this.getApiUrl(`/Practitioner`);
    const url = new URL(baseUrl);
    if (type)
      url.searchParams.append('type', type);
    return this.apiGet<Practitioner>(url.href);
  }

  getOrganization(orgType?: 'healthboard' | 'gp',
                  subType?: 'generate' | 'regional' | 'special' | 'public_health'): Promise<Organization> {
    const baseUrl = this.getApiUrl(`/Organization`);
    const url = new URL(baseUrl);
    if (orgType)
      url.searchParams.append('type', orgType);
    if (subType)
      url.searchParams.append('subtype', subType);

    return this.apiGet<Organization>(url.href);
  }
}
